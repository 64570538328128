
/* animation fro all */

* {
    font-family: Play;
}

/* dashboard-container page */

.dashboard-container {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 10%;
    margin-bottom: 5%;
}

/* dashboard-sub-container */

.dashboard-container .dashboard-sub-container {
    display: flex;
    flex-direction: column;
    animation: 3s slide-right;
}


/***** Animations *****/
@keyframes slide-right {
    from {
      margin-left: -100%;
    }
    to {
      margin-left: 0%;
    }
  }
  @keyframes slide-left {
    from {
      margin-left: 100%;
    }
    to {
      margin-left: 0%;
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .fade-in {
    animation: fadeIn ease 3s;
  }
  .slide-right {
    animation: 3s slide-right;
  }
  .slide-left {
    animation: 3s slide-left;
  }

/* sub-container headings */

.dashboard-container .dashboard-sub-container h4 {
    font-size: 30px;
    margin-bottom: 10px;
    text-align: center;
    color: #2348e2;
}

/* sub-container1 */

.dashboard-container .dashboard-sub-container .sub-container1 {
    margin-bottom: 10px;
}

/* sub-container1 para */

.dashboard-container .dashboard-sub-container .sub-container1 p {
    font-size: 18px;
    margin-bottom: 10px;
    width: 40vw;
    text-align: justify;
}

/* sub-container1 heading */

.dashboard-container .dashboard-sub-container .sub-container1 h3 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #2348e2;
}

/* sub-container1 image */

.dashboard-container .dashboard-sub-container .sub-container1 img {
    width: 35vw;
    margin-top: -22%;
    margin-left: 55%;
}

/* sub-container2 */

.dashboard-container .dashboard-sub-container .sub-container2 {
    margin-bottom: 10px;
}

/* sub-container2 para */

.dashboard-container .dashboard-sub-container .sub-container2 p {
    font-size: 18px;
    margin-bottom: 10px;
    /* margin-top: 20px; */
    margin-left: 50%;
    width: 40vw;
    text-align: justify;
}

/* sub-container2 heading */

.dashboard-container .dashboard-sub-container .sub-container2 h3 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #2348e2;
}

/* sub-container2 image */

.dashboard-container .dashboard-sub-container .sub-container2 img {
    width: 35vw;
    margin-top: -30%;
    margin-left: -12%;
    margin-bottom: 10%;
}

/* sub-container3 */

.dashboard-container .dashboard-sub-container .sub-container3 {
    margin-bottom: 10px;
}

/* sub-container3 para */

.dashboard-container .dashboard-sub-container .sub-container3 p {
    font-size: 18px;
    margin-bottom: 10px;
    width: 40vw;
    text-align: justify;
}

/* sub-container3 heading */

.dashboard-container .dashboard-sub-container .sub-container3 h3 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #2348e2;
}

/* sub-container3 image */

.dashboard-container .dashboard-sub-container .sub-container3 img {
    width: 40vw;
    margin-top: -30%;
    margin-left: 55%;
}

/* sub-container2 */

.sub-container2{
    width: 80%;
    /* display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; */
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 1%;
    margin-bottom: 5%;
}

/* sub-container2 heading */

.dashboard-container .dashboard-sub-container2 h2{
    font-size: 25px;
    color: #2348e2;
    margin-bottom: 20px;
    text-align: center;
}

/* sub-container2 para */

.dashboard-container .dashboard-sub-container2 p{
    font-size: 18px;
    margin-bottom: 20px;
    text-align: justify;
    /* width: 80vw; */
}

/* sub-container2 historical data */

.dashboard-container .dashboard-sub-container2 .historical_data{
    margin-top: 20px;
    width: 20vw;
    margin-left: 15%;
    text-align: justify;
    /* color: #2348e2; */
    line-height: 1.8rem;
}

/* sub-container2 heading */

.dashboard-container .dashboard-sub-container2 h3{
    color: #2348e2;
}

/* sub-container2 image */

.dashboard-container .dashboard-sub-container2 .historical_data_img{
    width: 20vw;
    margin-top: -15%;
    margin-left: 58%;
}

/* sub-container2 zoom functionality */

.dashboard-container .dashboard-sub-container2 .zoom_functionality{
    margin-top: 20px;
    width: 20vw;
    margin-left: 15%;
    text-align: justify;
    line-height: 1.8rem;
    /* margin-bottom: 60px; */
}

/* sub-container2 zoom functionality image */

.dashboard-container .dashboard-sub-container2 .zoom_functionality_img{
    width: 20vw;
    margin-top: -30%;
    margin-left: 58%;
}

/* sub-container2 date selection */

.dashboard-container .dashboard-sub-container2 .dateselection{
    margin-top: 40px;
    width: 20vw;
    margin-left: 15%;
    text-align: justify;
    line-height: 1.8rem;
}

/* sub-container2 date selection image */

.dashboard-container .dashboard-sub-container2 .dateselection_img{
    width: 20vw;
    margin-top: -15%;
    margin-left: 58%;
}

/* sub-container3  */

.dashboard-container .dashboard-sub-container3{
    /* display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; */
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 1%;
    margin-bottom: 5%;
}

/* sub-container3 image */

.dashboard-container .dashboard-sub-container3 img{
    width: 60%;
    height: 60%;
    margin-bottom: 20px;
    margin-left: 23%;
    /* object-fit: cover; */
}

/* sub-container3 para */

.dashboard-container .dashboard-sub-container3 p{
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 60vw;
    text-align: justify;
    line-height: 1.8rem;
}

/* sub-container3 heading */

.dashboard-container .dashboard-sub-container3 p h3{
    font-size: 20px;
    color: #2348e2;
}

@media screen and (max-width: 1920px){
    
    /* animation fro all */

    * {
        font-family: Play;
    }

    /* dashboard-container page */

    .dashboard-container {
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
        margin-top: 10%;
        margin-bottom: 5%;
    }

    /* dashboard-sub-container */

    .dashboard-container .dashboard-sub-container {
        display: flex;
        flex-direction: column;
        animation: 3s slide-right;
    }


    /***** Animations *****/
    @keyframes slide-right {
        from {
        margin-left: -100%;
        }
        to {
        margin-left: 0%;
        }
    }
    @keyframes slide-left {
        from {
        margin-left: 100%;
        }
        to {
        margin-left: 0%;
        }
    }
    @keyframes fadeIn {
        0% {
        opacity: 0;
        }
        100% {
        opacity: 1;
        }
    }
    .fade-in {
        animation: fadeIn ease 3s;
    }
    .slide-right {
        animation: 3s slide-right;
    }
    .slide-left {
        animation: 3s slide-left;
    }

    /* sub-container headings */

    .dashboard-container .dashboard-sub-container h4 {
        font-size: 30px;
        margin-bottom: 10px;
        text-align: center;
        color: #2348e2;
    }

    /* sub-container1 */

    .dashboard-container .dashboard-sub-container .sub-container1 {
        margin-bottom: 10px;
    }

    /* sub-container1 para */

    .dashboard-container .dashboard-sub-container .sub-container1 p {
        font-size: 18px;
        margin-bottom: 10px;
        width: 40vw;
        text-align: justify;
    }

    /* sub-container1 heading */

    .dashboard-container .dashboard-sub-container .sub-container1 h3 {
        font-size: 20px;
        margin-bottom: 10px;
        color: #2348e2;
    }

    /* sub-container1 image */

    .dashboard-container .dashboard-sub-container .sub-container1 img {
        width: 35vw;
        margin-top: -20%;
        margin-left: 55%;
    }

    /* sub-container2 */

    .dashboard-container .dashboard-sub-container .sub-container2 {
        margin-bottom: 10px;
    }

    /* sub-container2 para */

    .dashboard-container .dashboard-sub-container .sub-container2 p {
        font-size: 18px;
        margin-bottom: 10px;
        /* margin-top: 20px; */
        margin-left: 50%;
        width: 40vw;
        text-align: justify;
    }

    /* sub-container2 heading */

    .dashboard-container .dashboard-sub-container .sub-container2 h3 {
        font-size: 20px;
        margin-bottom: 10px;
        color: #2348e2;
    }

    /* sub-container2 image */

    .dashboard-container .dashboard-sub-container .sub-container2 img {
        width: 35vw;
        margin-top: -30%;
        margin-left: -12%;
        margin-bottom: 10%;
    }

    /* sub-container3 */

    .dashboard-container .dashboard-sub-container .sub-container3 {
        margin-bottom: 10px;
    }

    /* sub-container3 para */

    .dashboard-container .dashboard-sub-container .sub-container3 p {
        font-size: 18px;
        margin-bottom: 10px;
        width: 40vw;
        text-align: justify;
    }

    /* sub-container3 heading */

    .dashboard-container .dashboard-sub-container .sub-container3 h3 {
        font-size: 20px;
        margin-bottom: 10px;
        color: #2348e2;
    }

    /* sub-container3 image */

    .dashboard-container .dashboard-sub-container .sub-container3 img {
        width: 40vw;
        margin-top: -28%;
        margin-left: 55%;
    }

    /* sub-container2 */

    .sub-container2{
        width: 80%;
        /* display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column; */
        margin-left: 10%;
        margin-right: 10%;
        margin-top: 1%;
        margin-bottom: 5%;
    }

    /* sub-container2 heading */

    .dashboard-container .dashboard-sub-container2 h2{
        font-size: 25px;
        color: #2348e2;
        margin-bottom: 20px;
        text-align: center;
    }

    /* sub-container2 para */

    .dashboard-container .dashboard-sub-container2 p{
        font-size: 18px;
        margin-bottom: 20px;
        text-align: justify;
        /* width: 80vw; */
    }

    /* sub-container2 historical data */

    .dashboard-container .dashboard-sub-container2 .historical_data{
        margin-top: 20px;
        width: 20vw;
        margin-left: 15%;
        text-align: justify;
        /* color: #2348e2; */
        line-height: 1.8rem;
    }

    /* sub-container2 heading */

    .dashboard-container .dashboard-sub-container2 h3{
        color: #2348e2;
    }

    /* sub-container2 image */

    .dashboard-container .dashboard-sub-container2 .historical_data_img{
        width: 20vw;
        margin-top: -15%;
        margin-left: 58%;
    }

    /* sub-container2 zoom functionality */

    .dashboard-container .dashboard-sub-container2 .zoom_functionality{
        margin-top: 20px;
        width: 20vw;
        margin-left: 15%;
        text-align: justify;
        line-height: 1.8rem;
        /* margin-bottom: 60px; */
    }

    /* sub-container2 zoom functionality image */

    .dashboard-container .dashboard-sub-container2 .zoom_functionality_img{
        width: 20vw;
        margin-top: -30%;
        margin-left: 58%;
    }

    /* sub-container2 date selection */

    .dashboard-container .dashboard-sub-container2 .dateselection{
        margin-top: 40px;
        width: 20vw;
        margin-left: 15%;
        text-align: justify;
        line-height: 1.8rem;
    }

    /* sub-container2 date selection image */

    .dashboard-container .dashboard-sub-container2 .dateselection_img{
        width: 20vw;
        margin-top: -15%;
        margin-left: 58%;
    }

    /* sub-container3  */

    .dashboard-container .dashboard-sub-container3{
        /* display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column; */
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
        margin-top: 1%;
        margin-bottom: 5%;
    }

    /* sub-container3 image */

    .dashboard-container .dashboard-sub-container3 img{
        width: 60%;
        height: 60%;
        margin-bottom: 20px;
        margin-left: 23%;
        /* object-fit: cover; */
    }

    /* sub-container3 para */

    .dashboard-container .dashboard-sub-container3 p{
        font-size: 18px;
        margin-top: 20px;
        margin-bottom: 20px;
        width: 60vw;
        text-align: justify;
        line-height: 1.8rem;
    }

    /* sub-container3 heading */

    .dashboard-container .dashboard-sub-container3 p h3{
        font-size: 20px;
        color: #2348e2;
    }
        
}

/* Responsive CSS for max-width: 1024px (Laptop view) */
@media screen and (max-width: 1536px){
    
    /* animation fro all */

    * {
        font-family: Play;
    }

    /* dashboard-container page */

    .dashboard-container {
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
        margin-top: 10%;
        margin-bottom: 5%;
    }

    /* dashboard-sub-container */

    .dashboard-container .dashboard-sub-container {
        display: flex;
        flex-direction: column;
        animation: 3s slide-right;
    }


    /***** Animations *****/
    @keyframes slide-right {
        from {
        margin-left: -100%;
        }
        to {
        margin-left: 0%;
        }
    }
    @keyframes slide-left {
        from {
        margin-left: 100%;
        }
        to {
        margin-left: 0%;
        }
    }
    @keyframes fadeIn {
        0% {
        opacity: 0;
        }
        100% {
        opacity: 1;
        }
    }
    .fade-in {
        animation: fadeIn ease 3s;
    }
    .slide-right {
        animation: 3s slide-right;
    }
    .slide-left {
        animation: 3s slide-left;
    }

    /* sub-container headings */

    .dashboard-container .dashboard-sub-container h4 {
        font-size: 30px;
        margin-bottom: 10px;
        text-align: center;
        color: #2348e2;
    }

    /* sub-container1 */

    .dashboard-container .dashboard-sub-container .sub-container1 {
        margin-bottom: 10px;
    }

    /* sub-container1 para */

    .dashboard-container .dashboard-sub-container .sub-container1 p {
        font-size: 18px;
        margin-bottom: 10px;
        width: 40vw;
        text-align: justify;
    }

    /* sub-container1 heading */

    .dashboard-container .dashboard-sub-container .sub-container1 h3 {
        font-size: 20px;
        margin-bottom: 10px;
        color: #2348e2;
    }

    /* sub-container1 image */

    .dashboard-container .dashboard-sub-container .sub-container1 img {
        width: 35vw;
        margin-top: -32%;
        margin-left: 55%;
        margin-bottom: 10%;
    }

    /* sub-container2 */

    .dashboard-container .dashboard-sub-container .sub-container2 {
        margin-bottom: 10px;
        margin-top: -5%;
    }

    /* sub-container2 para */

    .dashboard-container .dashboard-sub-container .sub-container2 p {
        font-size: 18px;
        margin-bottom: 10px;
        /* margin-top: 20px; */
        margin-left: 50%;
        width: 40vw;
        text-align: justify;
    }

    /* sub-container2 heading */

    .dashboard-container .dashboard-sub-container .sub-container2 h3 {
        font-size: 20px;
        margin-bottom: 10px;
        color: #2348e2;
    }

    /* sub-container2 image */

    .dashboard-container .dashboard-sub-container .sub-container2 img {
        width: 35vw;
        margin-top: -38%;
        margin-left: -12%;
        margin-bottom: 18%;
    }

    /* sub-container3 */

    .dashboard-container .dashboard-sub-container .sub-container3 {
        margin-bottom: 10px;
        margin-top: -8%;
    }

    /* sub-container3 para */

    .dashboard-container .dashboard-sub-container .sub-container3 p {
        font-size: 18px;
        margin-bottom: 10px;
        width: 40vw;
        text-align: justify;
    }

    /* sub-container3 heading */

    .dashboard-container .dashboard-sub-container .sub-container3 h3 {
        font-size: 20px;
        margin-bottom: 10px;
        color: #2348e2;
    }

    /* sub-container3 image */

    .dashboard-container .dashboard-sub-container .sub-container3 img {
        width: 40vw;
        margin-top: -30%;
        margin-left: 55%;
    }

    /* sub-container2 */

    .sub-container2{
        width: 80%;
        /* display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column; */
        margin-left: 10%;
        margin-right: 10%;
        margin-top: 1%;
        margin-bottom: 5%;
    }

    /* sub-container2 heading */

    .dashboard-container .dashboard-sub-container2 h2{
        font-size: 25px;
        color: #2348e2;
        margin-bottom: 20px;
        text-align: center;
    }

    /* sub-container2 para */

    .dashboard-container .dashboard-sub-container2 p{
        font-size: 18px;
        margin-bottom: 20px;
        text-align: justify;
        /* width: 80vw; */
    }

    /* sub-container2 historical data */

    .dashboard-container .dashboard-sub-container2 .historical_data{
        margin-top: 20px;
        width: 20vw;
        margin-left: 15%;
        text-align: justify;
        /* color: #2348e2; */
        line-height: 1.8rem;
    }

    /* sub-container2 heading */

    .dashboard-container .dashboard-sub-container2 h3{
        color: #2348e2;
    }

    /* sub-container2 image */

    .dashboard-container .dashboard-sub-container2 .historical_data_img{
        width: 20vw;
        margin-top: -25%;
        margin-left: 58%;
    }

    /* sub-container2 zoom functionality */

    .dashboard-container .dashboard-sub-container2 .zoom_functionality{
        margin-top: 20px;
        width: 20vw;
        margin-left: 15%;
        text-align: justify;
        line-height: 1.8rem;
        /* margin-bottom: 60px; */
    }

    /* sub-container2 zoom functionality image */

    .dashboard-container .dashboard-sub-container2 .zoom_functionality_img{
        width: 20vw;
        margin-top: -23%;
        margin-left: 58%;
    }

    /* sub-container2 date selection */

    .dashboard-container .dashboard-sub-container2 .dateselection{
        margin-top: 40px;
        width: 20vw;
        margin-left: 15%;
        text-align: justify;
        line-height: 1.8rem;
    }

    /* sub-container2 date selection image */

    .dashboard-container .dashboard-sub-container2 .dateselection_img{
        width: 20vw;
        margin-top: -23%;
        margin-left: 58%;
    }

    /* sub-container3  */

    .dashboard-container .dashboard-sub-container3{
        /* display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column; */
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
        margin-top: 1%;
        margin-bottom: 5%;
    }

    /* sub-container3 image */

    .dashboard-container .dashboard-sub-container3 img{
        width: 60%;
        height: 60%;
        margin-bottom: 20px;
        margin-left: 23%;
        /* object-fit: cover; */
    }

    /* sub-container3 para */

    .dashboard-container .dashboard-sub-container3 p{
        font-size: 18px;
        margin-top: 20px;
        margin-bottom: 20px;
        width: 60vw;
        text-align: justify;
        line-height: 1.8rem;
    }

    /* sub-container3 heading */

    .dashboard-container .dashboard-sub-container3 p h3{
        font-size: 20px;
        color: #2348e2;
    }
    
}

@media screen and (max-width: 1263px) {
    
    /* animation fro all */

    * {
        font-family: Play;
    }

    /* dashboard-container page */

    .dashboard-container {
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
        margin-top: 10%;
        margin-bottom: 5%;
    }

    /* dashboard-sub-container */

    .dashboard-container .dashboard-sub-container {
        display: flex;
        flex-direction: column;
        animation: 3s slide-right;
    }


    /***** Animations *****/
    @keyframes slide-right {
        from {
        margin-left: -100%;
        }
        to {
        margin-left: 0%;
        }
    }
    @keyframes slide-left {
        from {
        margin-left: 100%;
        }
        to {
        margin-left: 0%;
        }
    }
    @keyframes fadeIn {
        0% {
        opacity: 0;
        }
        100% {
        opacity: 1;
        }
    }
    .fade-in {
        animation: fadeIn ease 3s;
    }
    .slide-right {
        animation: 3s slide-right;
    }
    .slide-left {
        animation: 3s slide-left;
    }

    /* sub-container headings */

    .dashboard-container .dashboard-sub-container h4 {
        font-size: 30px;
        margin-bottom: 10px;
        text-align: center;
        color: #2348e2;
    }

    /* sub-container1 */

    .dashboard-container .dashboard-sub-container .sub-container1 {
        margin-bottom: 10px;
    }

    /* sub-container1 para */

    .dashboard-container .dashboard-sub-container .sub-container1 p {
        font-size: 18px;
        margin-bottom: 10px;
        width: 40vw;
        text-align: justify;
    }

    /* sub-container1 heading */

    .dashboard-container .dashboard-sub-container .sub-container1 h3 {
        font-size: 20px;
        margin-bottom: 10px;
        color: #2348e2;
    }

    /* sub-container1 image */

    .dashboard-container .dashboard-sub-container .sub-container1 img {
        width: 35vw;
        margin-top: -38%;
        margin-left: 55%;
    }

    /* sub-container2 */

    .dashboard-container .dashboard-sub-container .sub-container2 {
        margin-bottom: 10px;
    }

    /* sub-container2 para */

    .dashboard-container .dashboard-sub-container .sub-container2 p {
        font-size: 18px;
        margin-bottom: 10px;
        margin-top: 20px;
        margin-left: 50%;
        width: 40vw;
        text-align: justify;
    }

    /* sub-container2 heading */

    .dashboard-container .dashboard-sub-container .sub-container2 h3 {
        font-size: 20px;
        margin-bottom: 10px;
        color: #2348e2;
    }

    /* sub-container2 image */

    .dashboard-container .dashboard-sub-container .sub-container2 img {
        width: 35vw;
        margin-top: -58%;
        margin-left: -12%;
        margin-bottom: 20%;
    }

    /* sub-container3 */

    .dashboard-container .dashboard-sub-container .sub-container3 {
        margin-bottom: 10px;
        margin-top: 10px;
    }

    /* sub-container3 para */

    .dashboard-container .dashboard-sub-container .sub-container3 p {
        font-size: 18px;
        margin-bottom: 10px;
        width: 40vw;
        text-align: justify;
    }

    /* sub-container3 heading */

    .dashboard-container .dashboard-sub-container .sub-container3 h3 {
        font-size: 20px;
        margin-bottom: 10px;
        color: #2348e2;
    }

    /* sub-container3 image */

    .dashboard-container .dashboard-sub-container .sub-container3 img {
        width: 40vw;
        margin-top: -40%;
        margin-left: 55%;
        margin-bottom: 10%;
    }

    /* sub-container2 */

    .sub-container2{
        width: 80%;
        /* display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column; */
        margin-left: 10%;
        margin-right: 10%;
        margin-top: 1%;
        margin-bottom: 5%;
    }

    /* sub-container2 heading */

    .dashboard-container .dashboard-sub-container2 h2{
        font-size: 25px;
        color: #2348e2;
        margin-bottom: 20px;
        text-align: center;
    }

    /* sub-container2 para */

    .dashboard-container .dashboard-sub-container2 p{
        font-size: 18px;
        margin-bottom: 20px;
        text-align: justify;
        /* width: 80vw; */
    }

    /* sub-container2 historical data */

    .dashboard-container .dashboard-sub-container2 .historical_data{
        margin-top: 20px;
        width: 20vw;
        margin-left: 15%;
        text-align: justify;
        /* color: #2348e2; */
        line-height: 1.8rem;
    }

    /* sub-container2 heading */

    .dashboard-container .dashboard-sub-container2 h3{
        color: #2348e2;
    }

    /* sub-container2 image */

    .dashboard-container .dashboard-sub-container2 .historical_data_img{
        width: 20vw;
        margin-top: -35%;
        margin-left: 58%;
        margin-bottom: 5%;
    }

    /* sub-container2 zoom functionality */

    .dashboard-container .dashboard-sub-container2 .zoom_functionality{
        margin-top: 20px;
        width: 20vw;
        margin-left: 15%;
        text-align: justify;
        line-height: 1.8rem;
        /* margin-bottom: 60px; */
    }

    /* sub-container2 zoom functionality image */

    .dashboard-container .dashboard-sub-container2 .zoom_functionality_img{
        width: 20vw;
        margin-top: -30%;
        margin-left: 58%;
        margin-bottom: 10%;
    }

    /* sub-container2 date selection */

    .dashboard-container .dashboard-sub-container2 .dateselection{
        margin-top: 40px;
        width: 20vw;
        margin-left: 15%;
        text-align: justify;
        line-height: 1.8rem;
    }

    /* sub-container2 date selection image */

    .dashboard-container .dashboard-sub-container2 .dateselection_img{
        width: 20vw;
        margin-top: -30%;
        margin-left: 58%;
        margin-bottom: 35%;
    }

    /* sub-container3  */

    .dashboard-container .dashboard-sub-container3{
        /* display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column; */
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
        margin-top: -30%;
        margin-bottom: 5%;
    }

    /* sub-container3 image */

    .dashboard-container .dashboard-sub-container3 img{
        width: 60%;
        height: 60%;
        margin-bottom: 20px;
        margin-left: 23%;
        /* object-fit: cover; */
    }

    /* sub-container3 para */

    .dashboard-container .dashboard-sub-container3 p{
        font-size: 18px;
        margin-top: 20px;
        margin-bottom: 20px;
        width: 60vw;
        text-align: justify;
        line-height: 1.8rem;
    }

    /* sub-container3 heading */

    .dashboard-container .dashboard-sub-container3 p h3{
        font-size: 20px;
        color: #2348e2;
    }
    
}

/* General Reset for Tablet */
@media (max-width: 768px) {
    /* animation for page */
    * {
        font-family: Play;
    }

    /* dashboard-container page */

    .dashboard-container {
        width: 90%;
        /* margin-left: 5%; */
        /* margin-right: 5%; */
        margin-top: 35%;
        margin-bottom: 5%;
    }

    /* dashboard-sub-container */

    .dashboard-container .dashboard-sub-container {
        display: flex;
        flex-direction: column;
        animation: 3s slide-right;
    }

    /* dashboard-sub-container heading */

    .dashboard-container .dashboard-sub-container h4 {
        font-size: 24px;
        margin-bottom: 8px;
        text-align: center;
        color: #2348e2;
    }

    /* dashboard-sub-container .sub-container1 */

    .dashboard-container .dashboard-sub-container .sub-container1 {
        margin-bottom: 8px;
    }

    /* dashboard-sub-container .sub-container1 para */

    .dashboard-container .dashboard-sub-container .sub-container1 p {
        font-size: 16px;
        margin-bottom: 8px;
        margin-left: 8%;
        width: 80%;
        text-align: justify;
    }

    /* dashboard-sub-container .sub-container1 heading */

    .dashboard-container .dashboard-sub-container .sub-container1 h3 {
        font-size: 18px;
        margin-bottom: 8px;
        color: #2348e2;
    }

    /* dashboard-sub-container .sub-container1 image */

    .dashboard-container .dashboard-sub-container .sub-container1 img {
        width: 80%;
        margin-top: 10%;
        margin-left: 10%;
    }

    /* dashboard-sub-container .sub-container2 */

    .dashboard-container .dashboard-sub-container .sub-container2 {
        margin-bottom: 8px;
    }

    /* dashboard-sub-container .sub-container2 para */

    .dashboard-container .dashboard-sub-container .sub-container2 p {
        font-size: 16px;
        margin-bottom: 8px;
        margin-left: 10%;
        width: 80%;
        text-align: justify;
    }

    /* dashboard-sub-container .sub-container2 heading */

    .dashboard-container .dashboard-sub-container .sub-container2 h3 {
        font-size: 18px;
        margin-bottom: 8px;
        color: #2348e2;
    }

    /* dashboard-sub-container .sub-container2 image */

    .dashboard-container .dashboard-sub-container .sub-container2 img {
        width: 80%;
        margin-top: 10%;
        margin-left: 10%;
        margin-bottom: 10%;
    }

    /* dashboard-sub-container .sub-container3 */

    .dashboard-container .dashboard-sub-container .sub-container3 {
        margin-bottom: 8px;
    }

    /* dashboard-sub-container .sub-container3 para */

    .dashboard-container .dashboard-sub-container .sub-container3 p {
        font-size: 16px;
        margin-bottom: 8px;
        margin-left: 8%;
        width: 80%;
        text-align: justify;
    }

    /* dashboard-sub-container .sub-container3 heading */

    .dashboard-container .dashboard-sub-container .sub-container3 h3 {
        font-size: 18px;
        margin-bottom: 8px;
        color: #2348e2;
    }

    /* dashboard-sub-container .sub-container3 image */

    .dashboard-container .dashboard-sub-container .sub-container3 img {
        width: 80%;
        margin-top: 10%;
        margin-left: 10%;
    }

    /* dashboard-sub-container1 .sub-container2 */

    .sub-container2 {
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
        margin-top: 1%;
        margin-bottom: 5%;
    }

    /* dashboard-sub-container2 heading */

    .dashboard-container .dashboard-sub-container2 h2 {
        font-size: 22px;
        color: #2348e2;
        margin-bottom: 15px;
        text-align: center;
    }

    /* dashboard-sub-container2 para */

    .dashboard-container .dashboard-sub-container2 p {
        font-size: 16px;
        margin-bottom: 15px;
        margin-left: 8%;
        text-align: justify;
    }

    /* dashboard-sub-container2 historical_data */

    .dashboard-container .dashboard-sub-container2 .historical_data {
        margin-top: 15px;
        width: 90%;
        margin-left: 5%;
        text-align: justify;
        line-height: 1.6rem;
    }

    /* dashboard-sub-container2 historical_data image */

    .dashboard-container .dashboard-sub-container2 .historical_data_img {
        width: 90%;
        margin-top: 10%;
        margin-left: 5%;
    }

    /* dashboard-sub-container2 zoom_functionality */

    .dashboard-container .dashboard-sub-container2 .zoom_functionality {
        margin-top: 15px;
        width: 90%;
        margin-left: 5%;
        text-align: justify;
        line-height: 1.6rem;
    }

    /* dashboard-sub-container2 zoom_functionality image */

    .dashboard-container .dashboard-sub-container2 .zoom_functionality_img {
        width: 90%;
        margin-top: 10%;
        margin-left: 5%;
    }

    /* dashboard-sub-container2 date selection */

    .dashboard-container .dashboard-sub-container2 .dateselection {
        margin-top: 25px;
        width: 90%;
        margin-left: 5%;
        text-align: justify;
        line-height: 1.6rem;
    }

    /* dashboard-sub-container2 date selection image */

    .dashboard-container .dashboard-sub-container2 .dateselection_img {
        width: 90%;
        margin-top: 10%;
        margin-left: 5%;
    }

    /* dashboard-sub-container3 */

    .dashboard-container .dashboard-sub-container3 {
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
        margin-top: 1%;
        margin-bottom: 5%;
    }

    /* dashboard-sub-container3 image */

    .dashboard-container .dashboard-sub-container3 img {
        width: 80%;
        height: auto;
        margin-bottom: 15px;
        margin-left: 10%;
    }

    /* dashboard-sub-container3 para */

    .dashboard-container .dashboard-sub-container3 p {
        font-size: 16px;
        margin-top: 15px;
        margin-bottom: 15px;
        margin-left: 8%;
        width: 80%;
        text-align: justify;
        line-height: 1.6rem;
    }

    /* dashboard-sub-container3 heading */

    .dashboard-container .dashboard-sub-container3 p h3 {
        font-size: 18px;
        color: #2348e2;
    }
}



