/* AboutUs.css */

/* AboutUs page styles */

.about-us {
    font-family: Arial, sans-serif;
    padding: 20px;
    background-color: #f4f4f4;
    margin-top: 100px;
}

/* AboutUs box styles */

.about-us-container {
    max-width: 1200px;
    margin: 0 auto;
    margin-top: -10%;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: center;
}

/* AboutUs image */

.about-us img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    margin: 20px 0;
}

/* AboutUs title and description */

.about-us-text h3 {
    color: #333;
    margin-bottom: 10px;
}

.about-us-text p {
    color: #666;
    line-height: 1.6;
    margin-bottom: 20px;
    text-align: justify;
}

/* AboutUs content wrapper */

.content-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
    margin-left: -5%;
    margin-right: 5%;
}

/* AboutUs boxes */

.box {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    flex: 1;
    min-width: 300px;
    padding: 20px;
}

/* AboutUs box title and description */

.box h2 {
    color: #333;
    margin-bottom: 10px;
}

.box p {
    color: #666;
    line-height: 1.6;
    text-align: justify;
}


/* Responsive Styles for Tablet View */
/* Tablet view (max-width: 768px) */
@media (max-width: 768px) {
    .about-us {
        padding: 10px;
        /* margin-top: 40px; */
    }

    .about-us-container {
        padding: 15px;
        margin-top: -20%;
        margin-bottom: 8%;
        box-shadow: none; /* Remove shadow to reduce complexity on smaller screens */
        border-radius: 0;  /* Make edges softer by removing the radius */
    }

    .about-us img {
        margin: 10px 0;
        border-radius: 5px;
    }

    .about-us-text h3 {
        font-size: 20px;
        margin-bottom: 8px;
    }

    .about-us-text p {
        font-size: 14px;
        margin-bottom: 15px;
    }

    /* Stack the content boxes vertically on smaller screens */
    .content-wrapper {
        flex-direction: column;
        margin-left: 0;
        margin-right: 0;
        margin-top: -15%;
        gap: -10px; /* Reduce the gap between boxes */
    }

    .box {
        padding: 15px;
        min-width: unset; /* Allow the boxes to take full width */
        flex: 100%; /* Make each box take full width */
        box-shadow: none; /* Optionally remove shadow for mobile for simpler look */
        border-radius: 0; /* Remove the border-radius on small devices */
        color: #666
    }

    .box h2 {
        font-size: 18px;
    }

    .box p {
        color: #666;
        font-size: 14px;
    }
}

/* Media query for mobile view between max-width 429px and min-width 425px */
@media (max-width: 429px) and (min-width: 425px) {

    .about-us {
        padding: 15px;
        margin-top: -90px; /* Adjust margin for smaller screens */
    }

    .about-us-container {
        margin-top: 0;
        padding: 15px;
        box-shadow: none; /* Remove shadow for a cleaner mobile look */
        border-radius: 0;
    }

    .about-us img {
        margin: 10px 0;
        border-radius: 4px;
    }

    .about-us-text h3 {
        font-size: 18px; /* Slightly reduce font size for mobile */
        margin-bottom: 5px;
    }

    .about-us-text p {
        font-size: 14px; /* Reduce font size for readability on smaller screens */
        margin-bottom: 15px;
    }

    .content-wrapper {
        flex-direction: column; /* Stack boxes vertically on small screens */
        margin-left: 0;
        margin-right: 0;
        gap: 15px; /* Reduce gap between boxes */
    }

    .box {
        flex: 1 100%; /* Ensure each box takes full width on mobile */
        min-width: unset; /* Remove the min-width constraint for better flexibility */
        padding: 15px;
        border-radius: 4px; /* Smaller border-radius on mobile */
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.05); /* Softer shadow */
    }

    .box h2 {
        font-size: 16px; /* Reduce font size for titles */
        margin-bottom: 8px;
    }

    .box p {
        font-size: 13px; /* Reduce text size for better readability */
    }
}

/* Responsive CSS for AboutUs page for mobile devices with max-width 425px */
@media only screen and (max-width: 425px) {
    /* Adjust padding and margin for smaller screens */
    .about-us {
        padding: 10px;
        margin-top: -20px;
    }

    .about-us-container {
        padding: 15px;
        margin-top: 0;
        margin-left: 5px;
        margin-right: 5px;
        box-shadow: none; /* Reduce shadow intensity for smaller screens */
    }

    /* Make image take full width on mobile */
    .about-us img {
        max-width: 100%;
        height: auto;
        margin: 15px 0; /* Reduce image margin for mobile */
    }

    /* Adjust text size and margins for readability */
    .about-us-text h3 {
        font-size: 20px;
        margin-bottom: 8px;
    }

    .about-us-text p {
        font-size: 14px;
        line-height: 1.5;
        margin-bottom: 15px;
    }

    /* Stack the boxes vertically */
    .content-wrapper {
        flex-direction: column;
        margin-left: 0;
        margin-right: 0;
        gap: 10px; /* Reduce gap between boxes */
    }

    /* Box styles for smaller screens */
    .box {
        min-width: 100%; /* Make boxes full width on mobile */
        padding: 15px; /* Adjust padding for smaller screens */
        box-shadow: none; /* Remove shadow or make it lighter */
    }

    /* Adjust text size in boxes */
    .box h2 {
        font-size: 18px;
        margin-bottom: 8px;
    }

    .box p {
        font-size: 14px;
        line-height: 1.5;
    }
}




