
/* Front Officer Receptionist page */

.receptionist-description-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin-left: 5%;
  margin-top: 6%;
}

/* Receptionist Details */

.receptionist-details {
  width: 60%;
  margin-right: 40px;
  text-align: justify;
}

/* Receptionist Description Details heading */

.receptionist-details h2 {
  font-size: 30px;
  margin-bottom: 20px;
  color: #23458e;
  text-align: left;
}

/* Receptionist Description Details  heading for responsibilities and education */

.database-details h3 {
  font-size: 24px;
  margin-bottom: 20px;
}

/* Receptionist Description content */

.receptionist-details .receptionist-description p{
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 10px;
}

/* Receptionist Description content before bullet points */

.receptionist-details .receptionist-description p:before{
  content: '• ';
  margin-right: 15px;
  font-size: 18px;
}

/* Receptionist Description content additional */

.receptionist-description-info p {
  display: block;
  /* padding: 10px; */
  justify-content: space-between;
  margin-top: 20px;
}

/* Receptionist Apply Form */

.receptionist-apply-form {
  width: 35%;
  height: 45%;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-right: 5%;
}

/* Receptionist Apply Form heading */

.receptionist-apply-form h3 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  color: #23458e;
}

/* Receptionist Apply Form content */

.receptionist-apply-form form {
  display: flex;
  flex-direction: column;
}

/* Receptionist Apply Form label */

.receptionist-apply-form label {
  margin-bottom: 5px;
  font-weight: bold;
}

/* Receptionist Apply Form input and textarea */

.receptionist-apply-form input,
.receptionist-apply-form textarea {
  margin-bottom: 15px;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

/* Receptionist Apply Form button */

.receptionist-apply-form button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

/* Receptionist Apply Form button hover */

.receptionist-apply-form button:hover {
  background-color: #0056b3;
}

/* Responsive styles for laptop view (max-width: 1268px) */
@media (max-width: 1263px) {
  .receptionist-description-container {
    flex-direction: row; /* Stack elements vertically */
    align-items: center; /* Center-align items */
    margin-left: 2%;
    margin-top: -1%;
    /* margin-bottom: 16%; */
  }

  .receptionist-details {
    width: 65%; /* Full width on smaller screens */
    /* margin-right: 50%; Remove right margin */

  }

  .receptionist-apply-form {
    width: 40%; /* Increase width for smaller screens */
    margin-right: 0; /* Remove right margin */
    /* margin-top: -63%; */
    /* margin-left: 50%; */
  }

  /* Adjust font sizes for readability */
  .receptionist-details h2 {
    font-size: 26px; /* Smaller heading size */
  }

  .database-details h3 {
    font-size: 22px; /* Smaller subheading size */
  }

  .receptionist-details .receptionist-description p {
    font-size: 14px; /* Smaller paragraph size */
  }

  .receptionist-description-info p {
    margin-top: 15px; /* Adjust margin for spacing */
  }

  .receptionist-apply-form h3 {
    font-size: 22px; /* Smaller heading size for the form */
  }

  .receptionist-apply-form input,
  .receptionist-apply-form textarea {
    font-size: 12px; /* Smaller input/textarea size */
  }

  .receptionist-apply-form button {
    font-size: 14px; /* Smaller button size */
  }
}

  
/* Tablet view adjustments */
@media (max-width: 768px) {

  /* receptionist page */

  .receptionist-description-container {
    flex-direction: column;
    margin-left: 0;
    margin-top: 18%;
    padding: 10px;
  }

  /* receptionist details  */

  .receptionist-details {
    width: 100%;
    margin-right: 0;
    text-align: left;
  }

  /* receptionist description heading */

  .receptionist-details h2 {
    font-size: 24px;
  }

  /* receptionist description heading for responsibilities and education */

  .database-details h3 {
    font-size: 20px;
  }

  /* receptionist description content */

  .receptionist-details .receptionist-description p {
    font-size: 14px;
    line-height: 1.4;
  }

  /* receptionist description content before bullet points */

  .receptionist-details .receptionist-description p:before {
    font-size: 16px;
  }

  /* receptionist description content additional */

  .receptionist-description-info p {
    margin-top: 10px;
  }

  /* receptionist apply form */

  .receptionist-apply-form {
    width: 100%;
    height: auto;
    margin-right: 0;
    margin-top: 20px;
  }

  /* receptionist apply form heading */

  .receptionist-apply-form h3 {
    font-size: 20px;
  }

  /* receptionist apply form input,textarea */

  .receptionist-apply-form input,
  .receptionist-apply-form textarea {
    font-size: 12px;
  }

  /* receptionist apply form button */

  .receptionist-apply-form button {
    font-size: 14px;
  }
}
