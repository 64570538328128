/* logistics service page content */

.logistics_content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin-top: 8%;
}

/* main heading */

.logistics_content h5 {
    font-size: 3.5rem;
    text-align: center;
    align-items: center;
    padding: 10px;
    color: #2348E2;
}

/* para content */

.logistics_para_content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.logistics_para_content p{
    font-size: 22px;
    text-align: justify;
    align-items: center;
    padding: 10px;
    /* color: #2348E2; */
    width: 70vw;
    margin-left: 75px;
    padding: 20px;
}

/* main image content below the heading */

.logistics_para_content .img1{
    width: 70vw;
    height: 55vh;
    margin-left: 75px;
}

/* benefits content */

.logistics_benefits{
    position: relative;
    width: 80%;
    margin: 0 auto;
    padding-top: 10px;
    margin-top: 10px;
}

/* benefits heading */

.logistics_benefits h5{
    font-size: 35px;
    text-align: justify;
    align-items: center;
    padding: 10px;
    color: #2348E2;
    width: 50vw;
    margin-left: 8%;
    line-height: 1;
    /* padding: 20px; */
}

/* benefits image */

.logistics_benefits .img2{
    float: right;
    width: 35vw;
    margin-left: 25px;
}

.logistics_benefits .benefits{
    font-size: 20px;
    /* text-align: justify; */
    align-items: center;
    /* padding: 10px; */
    /* color: #2348E2; */
    width: 30vw;
    margin-left: 12%;
    /* margin-left: 25px;
    padding: 20px; */
}

.logistics_benefits .benefits p{
    position: relative; /* For positioning the pseudo-element */
    padding-left: 5px; /* Space for the bullet */
    margin: 5px 0; /* Add margin between paragraphs */
    /* color: #2348E2; */
    text-align: justify;
    font-size: 20px;
    width: 30vw;
    padding: 10px;
}

.logistics_benefits .benefits p::before {
    content: '•'; /* Bullet character */
    position: absolute;
    left: -20px;
    /* color: #2348E2;  */
    font-size: 24px; /* Bullet size */
    line-height: 1; /* Adjust bullet alignment */
}

/* applications content */

.logistics_applications{
    position: relative;
    width: 80%;
    margin: 0 auto;
    padding-top: 10px;
    margin-top: 10px;
}

/* applications heading */

.logistics_applications h5{
    font-size: 35px;
    text-align: justify;
    align-items: center;
    padding: 10px;
    color: #2348E2;
    float: right;
    margin-right: 120px;
    /* padding: 20px; */
}

/* applications image */

.logistics_applications .img3{
    float: left;
    width: 35vw;
    /* margin-left: 20px; */
    margin-bottom: 30px;
    margin-top: -25%;
    margin-left: 120px;
}

.logistics_applications .applications{
    font-size: 20px;
    /* text-align: justify; */
    align-items: center;
    /* padding: 10px; */
    /* color: #2348E2; */
    width: 30vw;
    margin-left: 60%;
    margin-top: 60px;
    /* margin-left: 25px;
    padding: 20px; */
}

.logistics_applications .applications p{ 
    position: relative; /* For positioning the pseudo-element */
    padding-left: 5px; /* Space for the bullet */
    margin: 5px 0; /* Add margin between paragraphs */
    /* color: #2348E2; */
    text-align: justify;
    font-size: 20px;
    margin-top: 10px;
    width: 30vw;
    line-height: 1;
    padding: 5px;
}

.logistics_applications .applications p::before {
    content: '•'; /* Bullet character */
    position: absolute; /* For positioning the pseudo-element */
    left: -20px;
    /* color: #2348E2;  */
    font-size: 24px; /* Bullet size */
    line-height: 1; /* Adjust bullet alignment */
}

/* logistics down image */

.logistics_down_image .img4{
    width: 80vw;
    margin-top: 40px;
}

/* Responsive Design for desktop view */

@media screen and (max-width: 1920px) {
    /* logistics service page content */

    .logistics_content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
        margin-top: 8%;
    }

    /* main heading */

    .logistics_content h5 {
        font-size: 3.5rem;
        text-align: center;
        align-items: center;
        padding: 10px;
        color: #2348E2;
    }

    /* para content */

    .logistics_para_content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px;
    }

    .logistics_para_content p{
        font-size: 22px;
        text-align: justify;
        align-items: center;
        padding: 10px;
        /* color: #2348E2; */
        width: 70vw;
        margin-left: 75px;
        padding: 20px;
    }

    /* main image content below the heading */

    .logistics_para_content .img1{
        width: 70vw;
        height: 55vh;
        margin-left: 75px;
    }

    /* benefits content */

    .logistics_benefits{
        position: relative;
        width: 60%;
        margin: 0 auto;
        padding-top: 10px;
        margin-top: 10px;
    }

    /* benefits heading */

    .logistics_benefits h5{
        font-size: 35px;
        text-align: justify;
        align-items: center;
        padding: 10px;
        color: #2348E2;
        width: 50vw;
        margin-left: 8%;
        line-height: 1;
        /* padding: 20px; */
    }

    /* benefits image */

    .logistics_benefits .img2{
        float: right;
        width: 15vw;
        margin-right: 20%;
        margin-top: 10%;
    }

    .logistics_benefits .benefits{
        font-size: 20px;
        /* text-align: justify; */
        align-items: center;
        /* padding: 10px; */
        /* color: #2348E2; */
        width: 20vw;
        /* margin-left: 12%; */
        /* margin-left: 25px;
        padding: 20px; */
    }

    .logistics_benefits .benefits p{
        position: relative; /* For positioning the pseudo-element */
        padding-left: 5px; /* Space for the bullet */
        margin: 5px 0; /* Add margin between paragraphs */
        /* color: #2348E2; */
        text-align: justify;
        font-size: 20px;
        width: 20vw;
        padding: 10px;
    }

    .logistics_benefits .benefits p::before {
        content: '•'; /* Bullet character */
        position: absolute;
        left: -20px;
        /* color: #2348E2;  */
        font-size: 24px; /* Bullet size */
        line-height: 1; /* Adjust bullet alignment */
    }

    /* applications content */

    .logistics_applications{
        position: relative;
        width: 80%;
        margin: 0 auto;
        padding-top: 10px;
        margin-top: 10px;
    }

    /* applications heading */

    .logistics_applications h5{
        font-size: 35px;
        text-align: justify;
        align-items: center;
        padding: 10px;
        color: #2348E2;
        float: right;
        margin-right: 300px;
        /* padding: 20px; */
    }

    /* applications image */

    .logistics_applications .img3{
        float: left;
        width: 20vw;
        /* margin-left: 20px; */
        margin-bottom: 30px;
        margin-top: -25%;
        margin-left: 22%;
    }

    .logistics_applications .applications{
        font-size: 20px;
        /* text-align: justify; */
        align-items: center;
        /* padding: 10px; */
        /* color: #2348E2; */
        width: 20vw;
        margin-left: 55%;
        margin-top: 60px;
        /* margin-left: 25px;
        padding: 20px; */
    }

    .logistics_applications .applications p{ 
        position: relative; /* For positioning the pseudo-element */
        padding-left: 5px; /* Space for the bullet */
        margin: 5px 0; /* Add margin between paragraphs */
        /* color: #2348E2; */
        text-align: justify;
        font-size: 20px;
        margin-top: 10px;
        width: 20vw;
        line-height: 1;
        padding: 5px;
    }

    .logistics_applications .applications p::before {
        content: '•'; /* Bullet character */
        position: absolute; /* For positioning the pseudo-element */
        left: -20px;
        /* color: #2348E2;  */
        font-size: 24px; /* Bullet size */
        line-height: 1; /* Adjust bullet alignment */
    }

    /* logistics down image */

    .logistics_down_image .img4{
        width: 80vw;
        margin-top: 40px;
    }
    
}

@media (max-width: 1536px){
    /* logistics service page content */

    .logistics_content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
        margin-top: 8%;
    }

    /* main heading */

    .logistics_content h5 {
        font-size: 3.5rem;
        text-align: center;
        align-items: center;
        padding: 10px;
        color: #2348E2;
    }

    /* para content */

    .logistics_para_content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px;
    }

    .logistics_para_content p{
        font-size: 22px;
        text-align: justify;
        align-items: center;
        padding: 10px;
        /* color: #2348E2; */
        width: 70vw;
        margin-left: 75px;
        padding: 20px;
    }

    /* main image content below the heading */

    .logistics_para_content .img1{
        width: 70vw;
        height: 55vh;
        margin-left: 75px;
    }

    /* benefits content */

    .logistics_benefits{
        position: relative;
        width: 80%;
        margin: 0 auto;
        padding-top: 10px;
        margin-top: 10px;
    }

    /* benefits heading */

    .logistics_benefits h5{
        font-size: 35px;
        text-align: justify;
        align-items: center;
        padding: 10px;
        color: #2348E2;
        width: 50vw;
        margin-left: 23%;
        line-height: 1;
        /* padding: 20px; */
    }

    /* benefits image */

    .logistics_benefits .img2{
        float: right;
        width: 25vw;
        margin-right: 55px;
        margin-top:10%;
    }

    .logistics_benefits .benefits{
        font-size: 20px;
        /* text-align: justify; */
        align-items: center;
        /* padding: 10px; */
        /* color: #2348E2; */
        width: 25vw;
        margin-left: 22%;
        /* margin-left: 25px;
        padding: 20px; */
    }

    .logistics_benefits .benefits p{
        position: relative; /* For positioning the pseudo-element */
        padding-left: 5px; /* Space for the bullet */
        margin: 5px 0; /* Add margin between paragraphs */
        /* color: #2348E2; */
        text-align: justify;
        font-size: 20px;
        width: 25vw;
        padding: 10px;
    }

    .logistics_benefits .benefits p::before {
        content: '•'; /* Bullet character */
        position: absolute;
        left: -20px;
        /* color: #2348E2;  */
        font-size: 24px; /* Bullet size */
        line-height: 1; /* Adjust bullet alignment */
    }

    /* applications content */

    .logistics_applications{
        position: relative;
        width: 80%;
        margin: 0 auto;
        padding-top: 10px;
        margin-top: 10px;
    }

    /* applications heading */

    .logistics_applications h5{
        font-size: 35px;
        text-align: justify;
        align-items: center;
        padding: 10px;
        color: #2348E2;
        float: right;
        margin-right: 120px;
        /* padding: 20px; */
    }

    /* applications image */

    .logistics_applications .img3{
        float: left;
        width: 25vw;
        /* margin-left: 20px; */
        margin-bottom: 30px;
        margin-top: -25%;
        margin-left: 22%;
    }

    .logistics_applications .applications{
        font-size: 20px;
        /* text-align: justify; */
        align-items: center;
        /* padding: 10px; */
        /* color: #2348E2; */
        width: 25vw;
        margin-left: 60%;
        margin-top: 60px;
        /* margin-left: 25px;
        padding: 20px; */
    }

    .logistics_applications .applications p{ 
        position: relative; /* For positioning the pseudo-element */
        padding-left: 5px; /* Space for the bullet */
        margin: 5px 0; /* Add margin between paragraphs */
        /* color: #2348E2; */
        text-align: justify;
        font-size: 20px;
        margin-top: 10px;
        width: 25vw;
        line-height: 1;
        padding: 5px;
    }

    .logistics_applications .applications p::before {
        content: '•'; /* Bullet character */
        position: absolute; /* For positioning the pseudo-element */
        left: -20px;
        /* color: #2348E2;  */
        font-size: 24px; /* Bullet size */
        line-height: 1; /* Adjust bullet alignment */
    }

    /* logistics down image */

    .logistics_down_image .img4{
        width: 80vw;
        margin-top: 40px;
    }
}

@media screen and (max-width: 1263px) {
    /* logistics service page content */

.logistics_content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin-top: 8%;
}

/* main heading */

.logistics_content h5 {
    font-size: 3.5rem;
    text-align: center;
    align-items: center;
    padding: 10px;
    color: #2348E2;
}

/* para content */

.logistics_para_content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.logistics_para_content p{
    font-size: 22px;
    text-align: justify;
    align-items: center;
    padding: 10px;
    /* color: #2348E2; */
    width: 70vw;
    margin-left: 75px;
    padding: 20px;
}

/* main image content below the heading */

.logistics_para_content .img1{
    width: 70vw;
    height: 55vh;
    margin-left: 75px;
}

/* benefits content */

.logistics_benefits{
    position: relative;
    width: 80%;
    margin: 0 auto;
    padding-top: 10px;
    margin-top: 10px;
}

/* benefits heading */

.logistics_benefits h5{
    font-size: 35px;
    text-align: justify;
    align-items: center;
    padding: 10px;
    color: #2348E2;
    width: 50vw;
    margin-left: 8%;
    line-height: 1;
    /* padding: 20px; */
}

/* benefits image */

.logistics_benefits .img2{
    float: right;
    width: 35vw;
    margin-left: 25px;
}

.logistics_benefits .benefits{
    font-size: 20px;
    /* text-align: justify; */
    align-items: center;
    /* padding: 10px; */
    /* color: #2348E2; */
    width: 30vw;
    margin-left: 12%;
    /* margin-left: 25px;
    padding: 20px; */
}

.logistics_benefits .benefits p{
    position: relative; /* For positioning the pseudo-element */
    padding-left: 5px; /* Space for the bullet */
    margin: 5px 0; /* Add margin between paragraphs */
    /* color: #2348E2; */
    text-align: justify;
    font-size: 20px;
    width: 30vw;
    padding: 10px;
}

.logistics_benefits .benefits p::before {
    content: '•'; /* Bullet character */
    position: absolute;
    left: -20px;
    /* color: #2348E2;  */
    font-size: 24px; /* Bullet size */
    line-height: 1; /* Adjust bullet alignment */
}

/* applications content */

.logistics_applications{
    position: relative;
    width: 80%;
    margin: 0 auto;
    padding-top: 10px;
    margin-top: 10px;
}

/* applications heading */

.logistics_applications h5{
    font-size: 35px;
    text-align: justify;
    align-items: right;
    padding: 10px;
    color: #2348E2;
    float: right;
    margin-left: 35%;
    /* padding: 20px; */
}

/* applications image */

.logistics_applications .img3{
    float: left;
    width: 35vw;
    /* margin-left: 20px; */
    margin-bottom: 30px;
    margin-top: -35%;
    margin-left: 120px;
}

.logistics_applications .applications{
    font-size: 20px;
    /* text-align: justify; */
    align-items: center;
    /* padding: 10px; */
    /* color: #2348E2; */
    width: 25vw;
    margin-left: 60%;
    margin-top: 10px;
    /* margin-left: 25px;
    padding: 20px; */
}

.logistics_applications .applications p{ 
    position: relative; /* For positioning the pseudo-element */
    padding-left: 5px; /* Space for the bullet */
    margin: 5px 0; /* Add margin between paragraphs */
    /* color: #2348E2; */
    text-align: justify;
    font-size: 20px;
    margin-top: 10px;
    width: 25vw;
    line-height: 1;
    padding: 5px;
}

.logistics_applications .applications p::before {
    content: '•'; /* Bullet character */
    position: absolute; /* For positioning the pseudo-element */
    left: -20px;
    /* color: #2348E2;  */
    font-size: 24px; /* Bullet size */
    line-height: 1; /* Adjust bullet alignment */
}

/* logistics down image */

.logistics_down_image .img4{
    width: 80vw;
    margin-top: 40px;
}
    
}


/* Logistics service page content for tablet view */
@media (max-width: 768px) {
    .logistics_content {
        padding: 10px;
        margin-top: 25%;
    }

    /* Main heading */
    .logistics_content h5 {
        font-size: 2.5rem;
        padding: 5px;
    }

    /* Para content */
    .logistics_para_content {
        padding: 5px;
    }

    .logistics_para_content p {
        font-size: 18px;
        width: 90vw; /* Increase width for smaller screens */
        margin-left: 0;
        padding: 10px;
    }

    .logistics_para_content .img1 {
        width: 90vw; /* Increase width for smaller screens */
        height: auto; /* Adjust height for better fit */
        margin-left: 0;
    }

    /* Benefits content */
    .logistics_benefits {
        width: 90%;
    }

    /* Benefits heading */
    .logistics_benefits h5 {
        font-size: 28px;
        width: 90vw; /* Increase width for smaller screens */
        margin-left: 0;
        text-align: center; /* Center align for smaller screens */
    }

    /* Benefits image */
    .logistics_benefits .img2 {
        width: 100%;
        float: none; /* Remove float for better responsiveness */
        margin: 10px 0; /* Add some margin for spacing */
    }

    .logistics_benefits .benefits {
        width: 90vw; /* Increase width for smaller screens */
        margin-left: 0;
    }

    .logistics_benefits .benefits p {
        font-size: 18px;
        padding-left: 0; /* Remove padding for bullets */
        width: 100%; /* Full width for better fit */
    }

    .logistics_benefits .benefits p::before {
        left: -15px; /* Adjust bullet position */
    }

    /* Applications content */
    .logistics_applications {
        width: 90%;
    }

    /* Applications heading */
    .logistics_applications h5 {
        font-size: 28px;
        margin-right: 85px;
        text-align: center; /* Center align for smaller screens */
    }

    /* Applications image */
    .logistics_applications .img3 {
        width: 80vw; /* Increase width for smaller screens */
        margin-left: 0;
        margin-top: 10px;
        float: none; /* Remove float for better responsiveness */
    }

    .logistics_applications .applications {
        width: 90vw; /* Increase width for smaller screens */
        margin-left: 0;
        margin-top: 10px;
    }

    .logistics_applications .applications p {
        font-size: 18px;
        padding-left: 0; /* Remove padding for bullets */
        width: 100%; /* Full width for better fit */
    }

    .logistics_applications .applications p::before {
        left: -15px; /* Adjust bullet position */
    }

    /* Logistics down image */
    .logistics_down_image .img4 {
        width: 90vw; /* Increase width for smaller screens */
    }
}

/* Responsive design for mobile screens */

@media (max-width: 429px) {
    /* Responsive adjustments for mobile view (max-width: 430px) */

    /* logistics service page content */
    .logistics_content {
        padding: 10px;
        margin-top: 20%;
    }

    /* main heading */
    .logistics_content h5 {
        font-size: 2.5rem; /* Smaller font size for mobile */
        padding: 5px;
    }

    /* para content */
    .logistics_para_content {
        padding: 5px;
    }

    .logistics_para_content p {
        font-size: 16px; /* Smaller font size for mobile */
        width: 90vw; /* Adjust width to fit mobile screens */
        margin-left: 0;
        padding: 10px;
    }

    /* main image content below the heading */
    .logistics_para_content .img1 {
        width: 90vw; /* Adjust width to fit mobile screens */
        height: auto; /* Maintain aspect ratio */
        margin-left: 0;
    }

    /* benefits content */
    .logistics_benefits {
        width: 90%; /* Adjust width to fit mobile screens */
        margin: 0 auto;
    }

    /* benefits heading */
    .logistics_benefits h5 {
        font-size: 24px; /* Smaller font size for mobile */
        margin-left: 0;
    }

    /* benefits image */
    .logistics_benefits .img2 {
        width: 80vw; /* Adjust width to fit mobile screens */
        float: none; /* Remove float for mobile */
        margin-left: 0;
        margin-bottom: 20px;
    }

    /* benefits text */
    .logistics_benefits .benefits {
        font-size: 16px; /* Smaller font size for mobile */
        margin-left: 0;
    }

    /* benefits bullet points */
    .logistics_benefits .benefits p::before {
        left: -15px; /* Adjust bullet position */
        font-size: 20px; /* Smaller bullet size */
    }

    /* applications content */
    .logistics_applications {
        width: 90%; /* Adjust width to fit mobile screens */
        margin: 0 auto;
    }

    /* applications heading */
    .logistics_applications h5 {
        font-size: 24px; /* Smaller font size for mobile */
        margin-right: 0;
    }

    /* applications image */
    .logistics_applications .img3 {
        width: 80vw; /* Adjust width to fit mobile screens */
        float: none; /* Remove float for mobile */
        margin-left: 0;
        margin-bottom: 20px;
        margin-top: 0;
    }

    /* applications text */
    .logistics_applications .applications {
        font-size: 16px; /* Smaller font size for mobile */
        margin-left: 0;
    }

    /* applications bullet points */
    .logistics_applications .applications p::before {
        left: -15px; /* Adjust bullet position */
        font-size: 20px; /* Smaller bullet size */
    }

    /* logistics down image */
    .logistics_down_image .img4 {
        width: 90vw; /* Adjust width to fit mobile screens */
        margin-top: 20px; /* Adjust margin */
    }
}

/* Mobile view adjustments */
@media (max-width: 425px) {
    /* logistics service page content */
    .logistics_content {
        padding: 10px;
        margin-top: 20%;
    }

    /* main heading */
    .logistics_content h5 {
        font-size: 2rem;
        padding: 5px;
    }

    /* para content */
    .logistics_para_content {
        padding: 5px;
    }

    .logistics_para_content p {
        font-size: 16px;
        width: 90vw;
        margin: 0;
        padding: 10px;
    }

    /* main image content below the heading */
    .logistics_para_content .img1 {
        width: 90vw;
        height: auto;
    }

    /* benefits content */
    .logistics_benefits {
        width: 100%;
        padding-top: 5px;
        margin-top: 5px;
    }

    /* benefits heading */
    .logistics_benefits h5 {
        font-size: 24px;
        width: 90vw;
        margin-left: 0;
    }

    /* benefits image */
    .logistics_benefits .img2 {
        float: none;
        width: 90vw;
        margin-left: 0;
        margin-bottom: 10px;
    }

    .logistics_benefits .benefits {
        width: 90vw;
        margin-left: 0;
    }

    .logistics_benefits .benefits p {
        width: 100%;
        padding-left: 10px;
        margin: 5px 0;
    }

    .logistics_benefits .benefits p::before {
        left: 0px;
    }

    /* applications content */
    .logistics_applications {
        width: 80vw;
        padding-top: 5px;
        margin-top: 5px;
    }

    /* applications heading */
    .logistics_applications h5 {
        font-size: 24px;
        margin-left: -150%;
        text-align: left;
        margin-bottom: 10px;
        /* width: 40vw; */
    }

    /* applications image */
    .logistics_applications .img3 {
        float: none;
        width: 80vw;
        margin-bottom: 20px;
        margin-top: 10px;
        margin-left: 0;
    }

    .logistics_applications .applications {
        width: 80vw;
        margin-left: 0;
        margin-top: 30px;
    }

    .logistics_applications .applications p {
        width: 100%;
        padding-left: 10px;
        margin-top: 10px;
    }

    .logistics_applications .applications p::before {
        left: -15px;
    }

    /* logistics down image */
    .logistics_down_image .img4 {
        width: 90vw;
        margin-top: 20px;
    }
}
