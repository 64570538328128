/* website development services page content */

.web_content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

/* main heading website development */

.web_content h5{
    font-size: 3.5rem;
    text-align: center;
    align-items: center;
    padding: 10px;
    color: #2348E2;
}

/* para content */

.para_content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.para_content p{
    font-size: 22px;
    text-align: justify;
    align-items: center;
    padding: 10px;
    /* color: #2348E2; */
    width: 80vw;
    margin-left: 75px;
    padding: 20px;
}

/* main image content below the heading */

.para_content .img1{
    width: 80vw;
    height: 85vh;
    margin-left: 75px;
}

/* benefits content */

.benefits_content{
    position: relative;
    width: 80%;
    margin: 0 auto;
    /* padding-top: 10px;
    margin-top: 10px; */
}

/* benefits heading */

.benefits_content h5{
    font-size: 35px;
    text-align: justify;
    align-items: center;
    /* padding: 10px; */
    color: #2348E2;
    width: 50vw;
    margin-left: 25px;
    /* padding: 20px; */
}

/* benefits image */

.benefits_content .img2{
    float: right;
    width: 50%;
    margin-top: -5%;
    margin-left: 20px;
}

.benefits_content .benefits{
    font-size: 20px;
    text-align: justify;
    align-items: center;
    padding: 10px;
    /* color: #2348E2; */
    width: 50vw;
    margin-left: 25px;
    padding: 20px;
}

.benefits_content .benefits p{
    position: relative; /* For positioning the pseudo-element */
    padding-left: 55px; /* Space for the bullet */
    margin: 5px 0; /* Add margin between paragraphs */
    /* color: #2348E2; */
    font-size: 20px;
}

.benefits_content .benefits p::before {
    content: '•'; /* Bullet character */
    position: absolute;
    left: 0;
    /* color: #2348E2;  */
    font-size: 24px; /* Bullet size */
    line-height: 1; /* Adjust bullet alignment */
}

/* usage content */

.usage_content{
    position: relative;
    width: 80%;
    margin: 0 auto;
    /* padding-top: 10px;
    margin-top: 10px; */
}

/* usage heading */

.usage_content h5{
    font-size: 35px;
    text-align: justify;
    align-items: center;
    padding: 10px;
    color: #2348E2;
    float: right;
    margin-right: 90px;
}

/* usage image */

.usage_content .img3{
    float: left;
    width: 35vw;
    height: 40vh;
    margin-top: -35%;
    margin-left: 20px;

}

.usage_content .usage p{
    position: relative; /* For positioning the pseudo-element */ /* Space for the bullet */
    /* color: #2348E2; */
    width: 25vw;
    float: right;
    margin-left: 60%;
    padding-right: 20px;
    font-size: 20px;
    line-height: 1.5;
    text-align: justify;
}

.usage_content .usage p::before {
    content: '•'; /* Bullet character */
    position: absolute;
    left: -35px;
    /* color: #2348E2;  */
    font-size: 24px; /* Bullet size */
    line-height: 1.5; /* Adjust bullet alignment */
    padding-left: 1px;
}

/* applications content */

.applications_content{
    position: relative;
    width: 80%;
    /* margin: 0 auto;
    padding-top: 10px; */
    margin-top: 20px; 
}

/* applications heading */

.applications_content h5{
    font-size: 35px;
    text-align: justify;
    align-items: center;
    padding: 10px;
    color: #2348E2;
    /* width: 50vw; */
    float: left;
    margin-left: 40px;
}

/* applications image */

.applications_content .img4{
    float: right;
    width: 35vw;
    height: 50vh;
    margin-top: 10%;
    padding-right: 60px;
    /* margin-left: 20px; */
}


.applications_content .applications{
    font-size: 20px;
    text-align: justify;
    align-items: center;
    padding: 10px;
    /* color: #2348E2;  */
    width: 40vw;
    float: left;
    margin-left: 25px;
    /* padding: 20px; */
}

.applications_content .applications p{
    position: relative; /* For positioning the pseudo-element */ /* Space for the bullet */
    /* color: #2348E2; */
    width: 40vw;
    float: left;
    font-size: 20px;
    line-height: 1.5;
    padding-right: 30px;
    /* margin-left: 155px; */
    /* padding: 10px; */
    padding-left: 75px;
}

.applications_content .applications p::before {
    content: '•'; /* Bullet character */
    position: absolute;
    left: 0;
    /* color: #2348E2;  */
    font-size: 24px; /* Bullet size */
    line-height: 1.5; /* Adjust bullet alignment */
    padding-left: 30px;
}

/* website down image */

.down_image_content .img5 {
    /* float: left; */
    width: 60vw;
    /* margin-top: -25%; */
    margin-left: 1px;
    /* margin-right: 20px; */
}

/* Responsive Styles for Desktop View (Max-width: 1440px) */
@media only screen and (max-width: 1440px) {

    /* General content adjustments */
    .web_content, .para_content, .benefits_content, .usage_content, .applications_content {
        padding: 15px;
        width: 90%;
    }

    /* Main heading */
    .web_content h5 {
        font-size: 3rem; /* Reduce font size */
        padding: 8px;
    }

    /* Paragraph content */
    .para_content p {
        font-size: 20px;
        width: 75vw;
        margin-left: 50px;
        padding: 15px;
    }

    /* Main image */
    .para_content .img1 {
        width: 75vw;
        height: 70vh;
        margin-left: 50px;
    }

    /* Benefits content */
    .benefits_content {
        width: 85%;
    }

    .benefits_content h5 {
        font-size: 32px;
        width: 60vw;
        margin-left: 20px;
    }

    .benefits_content .img2 {
        width: 45%;
        margin-left: 10px;
        margin-top: -3%;
    }

    .benefits_content .benefits p {
        padding-left: 50px;
        font-size: 18px;
    }

    /* Usage content */
    .usage_content {
        width: 85%;
    }

    .usage_content h5 {
        font-size: 32px;
        margin-right: 70px;
    }

    .usage_content .img3 {
        width: 30vw;
        height: 35vh;
        margin-top: -25%;
    }

    .usage_content .usage p {
        width: 30vw;
        margin-left: 100px;
        font-size: 18px;
    }

    /* Applications content */
    .applications_content {
        width: 85%;
    }

    .applications_content h5 {
        font-size: 32px;
        margin-left: 30px;
    }

    .applications_content .img4 {
        width: 30vw;
        height: 45vh;
        padding-right: 50px;
    }

    .applications_content .applications p {
        width: 35vw;
        padding-right: 20px;
        font-size: 18px;
        padding-left: 50px;
    }

    /* Website down image */
    .down_image_content .img5 {
        width: 55vw;
        margin-left: 0;
    }
}

/* Responsive CSS for desktop view with max-width 1024px */
@media only screen and (max-width: 1024px) {

    .web_content {
        padding: 15px; /* Reduce padding for smaller screens */
    }

    .web_content h5 {
        font-size: 2.5rem; /* Adjust font size for smaller screens */
        padding: 8px;
    }

    .para_content p {
        font-size: 18px; /* Reduce font size for smaller screens */
        width: 90vw; /* Expand to utilize more space */
        margin-left: 0; /* Remove left margin for better alignment */
        padding: 15px;
    }

    .para_content .img1 {
        width: 90vw; /* Adjust image width for responsiveness */
        height: auto; /* Maintain aspect ratio */
        margin-left: 0; /* Remove left margin for better alignment */
    }

    .benefits_content {
        width: 90%; /* Adjust width for smaller screens */
    }

    .benefits_content h5 {
        font-size: 28px; /* Reduce font size for smaller screens */
        width: 70vw; /* Adjust width */
        margin-left: 0; /* Remove left margin */
    }

    .benefits_content .img2 {
        width: 45%; /* Adjust image width */
        margin-top: -3%; /* Slightly reduce margin */
    }

    .benefits_content .benefits {
        font-size: 18px; /* Reduce font size */
        width: 70vw; /* Adjust width */
        margin-left: 0; /* Remove left margin */
    }

    .usage_content {
        width: 90%; /* Adjust width for smaller screens */
    }

    .usage_content h5 {
        font-size: 28px; /* Reduce font size */
        float: none; /* Remove float for better alignment */
        text-align: center; /* Center the heading */
        margin-right: 0; /* Remove right margin */
    }

    .usage_content .img3 {
        width: 50vw; /* Adjust image width */
        height: auto; /* Maintain aspect ratio */
        margin-top: -15%; /* Adjust margin for image positioning */
    }

    .usage_content .usage p {
        width: 80vw; /* Adjust width for better alignment */
        float: none; /* Remove float */
        margin: 0 auto; /* Center content */
        padding-right: 10px;
    }

    .applications_content {
        width: 90%; /* Adjust width for smaller screens */
    }

    .applications_content h5 {
        font-size: 28px; /* Reduce font size */
        float: none; /* Remove float */
        text-align: center; /* Center the heading */
        margin-left: 0; /* Remove left margin */
    }

    .applications_content .img4 {
        width: 50vw; /* Adjust image width */
        height: auto; /* Maintain aspect ratio */
        margin-top: 5%; /* Adjust margin for better positioning */
    }

    .applications_content .applications {
        width: 75vw; /* Adjust width for smaller screens */
        float: none; /* Remove float */
        margin: 0 auto; /* Center content */
    }

    .applications_content .applications p {
        width: 75vw; /* Adjust width for better alignment */
        float: none; /* Remove float */
        padding-left: 45px; /* Adjust padding for bullet points */
    }

    .down_image_content .img5 {
        width: 80vw; /* Adjust image width */
        margin-left: 0; /* Remove left margin */
    }
}

/* Responsive CSS for tablet view (max-width: 768px) */
@media (max-width: 768px) {
    .web_content {
        padding: 10px;
    }

    .web_content h5 {
        font-size: 2.5rem;
        padding: 5px;
    }

    .para_content {
        padding: 5px;
    }

    .para_content p {
        font-size: 18px;
        padding: 10px;
        width: 90vw;
        margin-left: 10px;
    }

    .para_content .img1 {
        width: 90vw;
        height: auto;
        margin-left: 10px;
    }

    .benefits_content {
        width: 100%;
    }

    .benefits_content h5 {
        font-size: 30px;
        width: 70vw;
        margin-left: 10px;
    }

    .benefits_content .img2 {
        width: 40vw;
        margin-right: 20%;
    }

    .benefits_content .benefits {
        font-size: 18px;
        width: 90vw;
        margin-left: 10px;
        margin-top: 40%;
    }

    .benefits_content .benefits p {
        width: 80vw;
        margin-left: 10px;
    }

    .usage_content {
        width: 100%;
    }

    .usage_content h5 {
        font-size: 30px;
        margin-right: 30%;
    }

    .usage_content .img3 {
        width: 40vw;
        height: auto;
        margin-top: 5%;
        margin-left: 35%;
    }

    .usage_content .usage p {
        width: 80vw;
        margin-left: 60px;
        font-size: 18px;
    }

    .applications_content {
        width: 100%;
    }

    .applications_content h5 {
        font-size: 30px;
    }

    .applications_content .img4 {
        width: 50vw;
        height: auto;
        margin-right: 8%;
    }

    .applications_content .applications {
        font-size: 18px;
        width: 75vw;
        margin-top: 50%;
    }

    .down_image_content .img5 {
        width: 80vw;
        margin-left: 10px;
    }
}

/* Responsive CSS for mobile view (max-width: 430px) */
@media screen and (max-width: 430px) {
    .web_content {
        padding: 10px;
    }

    .web_content h5 {
        font-size: 2rem; /* Adjust font size */
        padding: 5px;
    }

    .para_content {
        padding: 5px;
    }

    .para_content p {
        font-size: 16px; /* Reduce font size */
        width: 90vw; /* Full width for better readability */
        margin-left: 0; /* Center align */
        padding: 10px;
    }

    .para_content .img1 {
        width: 90vw; /* Adjust image width */
        height: auto; /* Maintain aspect ratio */
        margin-left: 0;
    }

    .benefits_content {
        width: 90%; /* Full width for better layout */
        margin: 0 auto;
    }

    .benefits_content h5 {
        font-size: 24px; /* Smaller font size */
        width: 100%; /* Full width for heading */
        margin-left: 0;
    }

    .benefits_content .img2 {
        width: 100%; /* Full width for images */
        margin-top: 10px; /* Adjust margin */
        margin-left: 0;
    }

    .benefits_content .benefits {
        font-size: 16px; /* Smaller font size */
        width: 100%; /* Full width */
        margin-left: 0;
        padding: 10px;
    }

    .usage_content {
        width: 90%; /* Full width for better layout */
        margin: 0 auto;
    }

    .usage_content h5 {
        font-size: 24px; /* Adjust font size */
        float: none; /* Reset float */
        text-align: center; /* Center align heading */
        margin-right: 0;
    }

    .usage_content .img3 {
        width: 100%; /* Full width for images */
        height: auto; /* Maintain aspect ratio */
        margin-top: 10px;
        margin-left: 0;
        float: none; /* Reset float */
    }

    .usage_content .usage p {
        width: 100%; /* Full width */
        margin-left: 0;
        padding-right: 10px;
        float: none; /* Reset float */
    }

    .applications_content {
        width: 90%; /* Full width */
        margin: 0 auto;
    }

    .applications_content h5 {
        font-size: 24px; /* Smaller font size */
        float: none; /* Reset float */
        text-align: center; /* Center align heading */
        margin-left: 0;
    }

    .applications_content .img4 {
        width: 100%; /* Full width */
        height: auto; /* Maintain aspect ratio */
        margin-top: 10px;
        padding-right: 0;
        float: none; /* Reset float */
    }

    .applications_content .applications {
        font-size: 16px; /* Smaller font size */
        width: 100%; /* Full width */
        margin-left: 0;
    }

    .applications_content .applications p {
        width: 100%; /* Full width */
        float: none; /* Reset float */
        padding-left: 20px;
    }

    .down_image_content .img5 {
        width: 100%; /* Full width for images */
        margin-left: 0;
    }
}

/* Base styles for mobile view (max-width: 425px) and (min-width:320px)  */
@media (max-width: 425px) and (min-width: 320px) {
    /* Adjustments for web content section */
    .web_content {
        padding: 10px;
    }

    .web_content h5 {
        font-size: 2.5rem;
        padding: 5px;
    }

    /* Adjustments for paragraph content */
    .para_content p {
        font-size: 18px;
        margin-left: 0;
        padding: 10px;
        width: 80vw; /* Full width for mobile */
    }

    .para_content .img1 {
        width: 100vw;
        height: auto; /* Maintain aspect ratio */
        margin-left: 0;
    }

    /* Adjustments for benefits content */
    .benefits_content {
        width: 100%;
        margin: 0;
        padding-top: 10px;
    }

    .benefits_content h5 {
        font-size: 28px;
        width: 100%;
        margin-left: 0;
    }

    .benefits_content .img2 {
        width: 100%;
        margin-top: 10px;
        float: none; /* Remove float for mobile */
    }

    .benefits_content .benefits {
        font-size: 18px;
        width: 100vw;
        margin-left: 0;
        padding: 10px;
        margin-top: -6%;
    }

    .benefits_content .benefits p {
        width: 65vw;
        float: none; /* Remove float for mobile */
        margin-left: 8%;
        padding-right: 0;
    }

    /* Adjustments for usage content */
    .usage_content {
        width: 100%;
        margin: 0;
    }

    .usage_content h5 {
        font-size: 28px;
        float: none;
        margin-right: 100px;
        text-align: center;
    }

    .usage_content .img3 {
        width: 100%;
        height: auto; /* Maintain aspect ratio */
        margin-left: 25px;
        margin-top: 10px;
        float: none; /* Remove float for mobile */
    }

    .usage_content .usage p {
        width: 65vw;
        float: none; /* Remove float for mobile */
        margin-left: 18%;
        padding-right: 0;
    }

    /* Adjustments for applications content */
    .applications_content {
        width: 100%;
        margin-top: 10px;
    }

    .applications_content h5 {
        font-size: 28px;
        float: none;
        margin-left: 0;
        text-align: center;
    }

    .applications_content .img4 {
        width: 100%;
        height: auto; /* Maintain aspect ratio */
        margin-top: 10px;
        margin-left: 25px;
        float: none; /* Remove float for mobile */
    }

    .applications_content .applications {
        font-size: 18px;
        width: 90vw;
        margin-left: -5%;
        margin-top: -2%;
        padding: 10px;
    }

    .applications_content .applications p {
        padding-left: 75px; /* Adjust bullet position */
    }

    /* Adjustments for down image content */
    .down_image_content .img5 {
        width: 100%;
        margin-left: 0;
    }
}
