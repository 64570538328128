/* Hardware Development service content */

.hardware_content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin-top: 8%;
}

/* main heading hardware development */

.hardware_content h5{
    font-size: 3.0rem;
    text-align: center;
    align-items: center;
    padding: 10px;
    color: #2348E2;
}

/* image hardware development  */

.hardware_content .hardware_img img{
    width: 55vw;
    height: auto;
    margin-left: 0%;
    margin-bottom: 0%;
}

/* text hardware development co2*/

.hardware_content .hardware_text1 p{
    font-size: 18px;
    padding: 10px;
    text-align: justify;
    /* color: #2348E2; */
    width: 80vw;
    margin-left: 150px;
    padding: 100px;
    margin-top: -18%;
}

/*  hardware development text heading co2*/

.hardware_content .hardware_text1 h4{
    font-size: 25px;
    padding: 10px;
    color: #2348E2;
    margin-left: -30px;
}

/* applications list co2*/

.hardware_applications1{
    position: relative;
    width: 60vw;
    text-align: justify;
    margin: 0 auto;
}

/* applications heading co2*/

.hardware_applications1 h4{
    font-size: 25px;
    padding: 10px;
    color: #2348E2;
    margin-left: -15px;
    margin-top: -80px;
}

/* applications content co2*/

.hardware_applications1 .hardware_data{
    font-size: 18px;
    padding: 10px;
}

/* applications para co2*/

.hardware_applications1 .hardware_list1 p{
    font-size: 18px;
    padding: 10px;
    line-height: 1.5rem;
    margin-left: 20px;
}

/* applications bullet co2*/

.hardware_applications1 .hardware_list1 p::before {
    content: '•'; /* Bullet character */
    position: absolute;
    left: -10px;
    font-size: 22px;
    color: #2348E2;
}

/* text hardware development o2*/

.hardware_content .hardware_text2 p{
    font-size: 18px;
    padding: 10px;
    text-align: justify;
    /* color: #2348E2; */
    width: 73vw;
    margin-left: 10px;
    padding: 100px;
    /* margin-top: 18%; */
}

/*  hardware development text heading o2*/

.hardware_content .hardware_text2 h4{
    font-size: 25px;
    padding: 10px;
    color: #2348E2;
    margin-top: -5%;
    margin-left: -30px;
}

/* applications list o2*/

.hardware_applications2{
    position: relative;
    width: 60vw;
    text-align: justify;
    margin: 0 auto;
}

/* applications heading o2*/

.hardware_applications2 h4{
    font-size: 25px;
    padding: 10px;
    color: #2348E2;
    margin-left: -15px;
    margin-top: -80px;
}

/* applications content o2*/

.hardware_applications2 .hardware_list2 p{
    font-size: 18px;
    padding: 10px;
    line-height: 1.5rem;
    margin-left: 20px;
}

/* applications bullet o2*/

.hardware_applications2 .hardware_list2 p::before {
    content: '•'; /* Bullet character */
    position: absolute;
    left: -10px;
    font-size: 22px;
    color: #2348E2;
}

/* text hardware development IAQ*/

.hardware_content .hardware_text3 p{
    font-size: 18px;
    padding: 10px;
    text-align: justify;
    /* color: #2348E2; */
    width: 73vw;
    margin-left: 10px;
    padding: 100px;
    /* margin-top: 18%; */
}

/*  hardware development text heading IAQ*/

.hardware_content .hardware_text3 h4{
    font-size: 25px;
    padding: 10px;
    color: #2348E2;
    margin-top: -5%;
    margin-left: -30px;
}

/* applications list IAQ*/

.hardware_applications3{
    position: relative;
    width: 60vw;
    text-align: justify;
    margin: 0 auto;
}

/* applications heading IAQ*/

.hardware_applications3 h4{
    font-size: 25px;
    padding: 10px;
    color: #2348E2;
    margin-left: -15px;
    margin-top: -80px;
}

/* applications content IAQ*/

.hardware_applications3 .hardware_list3 p{
    font-size: 18px;
    padding: 10px;
    line-height: 1.5rem;
    margin-left: 20px;
}

/* applications bullet IAQ*/

.hardware_applications3 .hardware_list3 p::before {
    content: '•'; /* Bullet character */
    position: absolute;
    left: -10px;
    font-size: 22px;
    color: #2348E2;
}

/* text hardware development co*/

.hardware_content .hardware_text4 p{
    font-size: 18px;
    padding: 10px;
    text-align: justify;
    /* color: #2348E2; */
    width: 73vw;
    margin-left: 10px;
    padding: 100px;
    /* margin-top: 18%; */
}

/*  hardware development text heading co*/

.hardware_content .hardware_text4 h4{
    font-size: 25px;
    padding: 10px;
    color: #2348E2;
    margin-top: -5%;
    margin-left: -30px;
}

/* applications list co*/

.hardware_applications4{
    position: relative;
    width: 60vw;
    text-align: justify;
    margin: 0 auto;
}

/* applications heading co*/

.hardware_applications4 h4{
    font-size: 25px;
    padding: 10px;
    color: #2348E2;
    margin-left: -15px;
    margin-top: -80px;
}

/* applications content co*/

.hardware_applications4 .hardware_list4 p{
    font-size: 18px;
    padding: 10px;
    line-height: 1.5rem;
    margin-left: 20px;
}

/* applications bullet co*/

.hardware_applications4 .hardware_list4 p::before {
    content: '•'; /* Bullet character */
    position: absolute;
    left: -10px;
    font-size: 22px;
    color: #2348E2;
}

/* text hardware development PM*/

.hardware_content .hardware_text5 p{
    font-size: 18px;
    padding: 10px;
    text-align: justify;
    /* color: #2348E2; */
    width: 73vw;
    margin-left: 10px;
    padding: 100px;
    /* margin-top: 18%; */
}

/*  hardware development text heading PM*/

.hardware_content .hardware_text5 h4{
    font-size: 25px;
    padding: 10px;
    color: #2348E2;
    margin-top: -5%;
    margin-left: -30px;
}

/* applications above content PM*/

 .hardware_data_content{
    font-size: 18px;
    padding: 10px;
    line-height: 1.5rem;
    margin-left: 20px;
    margin-top: -6%;
    margin-bottom: 5%;
}

/* applications above para PM */

.hardware_data_content p{
    font-size: 18px;
    padding: 10px;
    line-height: 1.5rem;
    margin-left: 20px;
}

/* applications above bullet PM */

.hardware_data_content p::before {
    content: '•'; /* Bullet character */
    position: absolute;
    left: 34%;
    font-size: 28px;
    /* color: #2348E2; */
}

/* applications list PM*/

.hardware_applications5{
    position: relative;
    width: 60vw;
    text-align: justify;
    margin: 0 auto;
}

/* applications heading PM*/ 

.hardware_applications5 h4{
    font-size: 25px;
    padding: 10px;
    color: #2348E2;
    margin-left: -15px;
    margin-top: -80px;
}

/* applications content PM*/

.hardware_applications5 .hardware_list5 p{
    font-size: 18px;
    padding: 10px;
    line-height: 1.5rem;
    margin-left: 20px;
}

/* applications bullet PM*/

.hardware_applications5 .hardware_list5 p::before {
    content: '•'; /* Bullet character */
    position: absolute;
    left: -10px;
    font-size: 22px;
    color: #2348E2;
}

/* text hardware development temp&hum*/

.hardware_content .hardware_text6 p{
    font-size: 18px;
    padding: 10px;
    text-align: justify;
    /* color: #2348E2; */
    width: 73vw;
    margin-left: 10px;
    padding: 100px;
    /* margin-top: 18%; */
}

/*  hardware development text heading temp&hum*/

.hardware_content .hardware_text6 h4{
    font-size: 25px;
    padding: 10px;
    color: #2348E2;
    margin-top: -5%;
    margin-left: -30px;
}

/* applications list temp&hum */

.hardware_applications6{
    position: relative;
    width: 60vw;
    text-align: justify;
    margin: 0 auto;
}

/* applications heading temp&hum */

.hardware_applications6 h4{
    font-size: 25px;
    padding: 10px;
    color: #2348E2;
    margin-left: -15px;
    margin-top: -80px;
}

/* applications content temp&hum */

.hardware_applications6 .hardware_list6 p{
    font-size: 18px;
    padding: 10px;
    line-height: 1.5rem;
    margin-left: 20px;
}

/* applications bullet temp&hum */

.hardware_applications6 .hardware_list6 p::before {
    content: '•'; /* Bullet character */
    position: absolute;
    left: -10px;
    font-size: 22px;
    color: #2348E2;
}

@media (min-width: 1262px) {
    /* Hardware Development service content */

    .hardware_content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
        margin-top: 8%;
    }

    /* main heading hardware development */

    .hardware_content h5{
        font-size: 3.0rem;
        text-align: center;
        align-items: center;
        padding: 10px;
        color: #2348E2;
    }

    /* image hardware development  */

    .hardware_content .hardware_img img{
        width: 55vw;
        height: auto;
        margin-left: 0%;
        margin-bottom: 0%;
    }

    /* text hardware development co2*/

    .hardware_content .hardware_text1 p{
        font-size: 18px;
        padding: 10px;
        text-align: justify;
        /* color: #2348E2; */
        width: 80vw;
        margin-left: 150px;
        padding: 100px;
        margin-top: -18%;
    }

    /*  hardware development text heading co2*/

    .hardware_content .hardware_text1 h4{
        font-size: 25px;
        padding: 10px;
        color: #2348E2;
        margin-left: -30px;
    }

    /* applications list co2*/

    .hardware_applications1{
        position: relative;
        width: 60vw;
        text-align: justify;
        margin: 0 auto;
    }

    /* applications heading co2*/

    .hardware_applications1 h4{
        font-size: 25px;
        padding: 10px;
        color: #2348E2;
        margin-left: -15px;
        margin-top: -80px;
    }

    /* applications content co2*/

    .hardware_applications1 .hardware_data{
        font-size: 18px;
        padding: 10px;
    }

    /* applications para co2*/

    .hardware_applications1 .hardware_list1 p{
        font-size: 18px;
        padding: 10px;
        line-height: 1.5rem;
        margin-left: 20px;
    }

    /* applications bullet co2*/

    .hardware_applications1 .hardware_list1 p::before {
        content: '•'; /* Bullet character */
        position: absolute;
        left: -10px;
        font-size: 22px;
        color: #2348E2;
    }

    /* text hardware development o2*/

    .hardware_content .hardware_text2 p{
        font-size: 18px;
        padding: 10px;
        text-align: justify;
        /* color: #2348E2; */
        width: 73vw;
        margin-left: 10px;
        padding: 100px;
        /* margin-top: 18%; */
    }

    /*  hardware development text heading o2*/

    .hardware_content .hardware_text2 h4{
        font-size: 25px;
        padding: 10px;
        color: #2348E2;
        margin-top: -5%;
        margin-left: -30px;
    }

    /* applications list o2*/

    .hardware_applications2{
        position: relative;
        width: 60vw;
        text-align: justify;
        margin: 0 auto;
    }

    /* applications heading o2*/

    .hardware_applications2 h4{
        font-size: 25px;
        padding: 10px;
        color: #2348E2;
        margin-left: -15px;
        margin-top: -80px;
    }

    /* applications content o2*/

    .hardware_applications2 .hardware_list2 p{
        font-size: 18px;
        padding: 10px;
        line-height: 1.5rem;
        margin-left: 20px;
    }

    /* applications bullet o2*/

    .hardware_applications2 .hardware_list2 p::before {
        content: '•'; /* Bullet character */
        position: absolute;
        left: -10px;
        font-size: 22px;
        color: #2348E2;
    }

    /* text hardware development IAQ*/

    .hardware_content .hardware_text3 p{
        font-size: 18px;
        padding: 10px;
        text-align: justify;
        /* color: #2348E2; */
        width: 73vw;
        margin-left: 10px;
        padding: 100px;
        /* margin-top: 18%; */
    }

    /*  hardware development text heading IAQ*/

    .hardware_content .hardware_text3 h4{
        font-size: 25px;
        padding: 10px;
        color: #2348E2;
        margin-top: -5%;
        margin-left: -30px;
    }

    /* applications list IAQ*/

    .hardware_applications3{
        position: relative;
        width: 60vw;
        text-align: justify;
        margin: 0 auto;
    }

    /* applications heading IAQ*/

    .hardware_applications3 h4{
        font-size: 25px;
        padding: 10px;
        color: #2348E2;
        margin-left: -15px;
        margin-top: -80px;
    }

    /* applications content IAQ*/

    .hardware_applications3 .hardware_list3 p{
        font-size: 18px;
        padding: 10px;
        line-height: 1.5rem;
        margin-left: 20px;
    }

    /* applications bullet IAQ*/

    .hardware_applications3 .hardware_list3 p::before {
        content: '•'; /* Bullet character */
        position: absolute;
        left: -10px;
        font-size: 22px;
        color: #2348E2;
    }

    /* text hardware development co*/

    .hardware_content .hardware_text4 p{
        font-size: 18px;
        padding: 10px;
        text-align: justify;
        /* color: #2348E2; */
        width: 73vw;
        margin-left: 10px;
        padding: 100px;
        /* margin-top: 18%; */
    }

    /*  hardware development text heading co*/

    .hardware_content .hardware_text4 h4{
        font-size: 25px;
        padding: 10px;
        color: #2348E2;
        margin-top: -5%;
        margin-left: -30px;
    }

    /* applications list co*/

    .hardware_applications4{
        position: relative;
        width: 60vw;
        text-align: justify;
        margin: 0 auto;
    }

    /* applications heading co*/

    .hardware_applications4 h4{
        font-size: 25px;
        padding: 10px;
        color: #2348E2;
        margin-left: -15px;
        margin-top: -80px;
    }

    /* applications content co*/

    .hardware_applications4 .hardware_list4 p{
        font-size: 18px;
        padding: 10px;
        line-height: 1.5rem;
        margin-left: 20px;
    }

    /* applications bullet co*/

    .hardware_applications4 .hardware_list4 p::before {
        content: '•'; /* Bullet character */
        position: absolute;
        left: -10px;
        font-size: 22px;
        color: #2348E2;
    }

    /* text hardware development PM*/

    .hardware_content .hardware_text5 p{
        font-size: 18px;
        padding: 10px;
        text-align: justify;
        /* color: #2348E2; */
        width: 73vw;
        margin-left: 10px;
        padding: 100px;
        /* margin-top: 18%; */
    }

    /*  hardware development text heading PM*/

    .hardware_content .hardware_text5 h4{
        font-size: 25px;
        padding: 10px;
        color: #2348E2;
        margin-top: -5%;
        margin-left: -30px;
    }

    /* applications above content PM*/

    .hardware_data_content{
        font-size: 18px;
        padding: 10px;
        line-height: 1.5rem;
        margin-left: 20px;
        margin-top: -6%;
        margin-bottom: 5%;
    }

    /* applications above para PM */

    .hardware_data_content p{
        font-size: 18px;
        padding: 10px;
        line-height: 1.5rem;
        margin-left: 20px;
    }

    /* applications above bullet PM */

    .hardware_data_content p::before {
        content: '•'; /* Bullet character */
        position: absolute;
        left: 30%;
        font-size: 28px;
        /* color: #2348E2; */
    }

    /* applications list PM*/

    .hardware_applications5{
        position: relative;
        width: 60vw;
        text-align: justify;
        margin: 0 auto;
    }

    /* applications heading PM*/ 

    .hardware_applications5 h4{
        font-size: 25px;
        padding: 10px;
        color: #2348E2;
        margin-left: -15px;
        margin-top: -80px;
    }

    /* applications content PM*/

    .hardware_applications5 .hardware_list5 p{
        font-size: 18px;
        padding: 10px;
        line-height: 1.5rem;
        margin-left: 20px;
    }

    /* applications bullet PM*/

    .hardware_applications5 .hardware_list5 p::before {
        content: '•'; /* Bullet character */
        position: absolute;
        left: -10px;
        font-size: 22px;
        color: #2348E2;
    }

    /* text hardware development temp&hum*/

    .hardware_content .hardware_text6 p{
        font-size: 18px;
        padding: 10px;
        text-align: justify;
        /* color: #2348E2; */
        width: 73vw;
        margin-left: 10px;
        padding: 100px;
        /* margin-top: 18%; */
    }

    /*  hardware development text heading temp&hum*/

    .hardware_content .hardware_text6 h4{
        font-size: 25px;
        padding: 10px;
        color: #2348E2;
        margin-top: -5%;
        margin-left: -30px;
    }

    /* applications list temp&hum */

    .hardware_applications6{
        position: relative;
        width: 60vw;
        text-align: justify;
        margin: 0 auto;
    }

    /* applications heading temp&hum */

    .hardware_applications6 h4{
        font-size: 25px;
        padding: 10px;
        color: #2348E2;
        margin-left: -15px;
        margin-top: -80px;
    }

    /* applications content temp&hum */

    .hardware_applications6 .hardware_list6 p{
        font-size: 18px;
        padding: 10px;
        line-height: 1.5rem;
        margin-left: 20px;
    }

    /* applications bullet temp&hum */

    .hardware_applications6 .hardware_list6 p::before {
        content: '•'; /* Bullet character */
        position: absolute;
        left: -10px;
        font-size: 22px;
        color: #2348E2;
    }
}



/* Responsive CSS for tablet view (768px) */

@media (max-width: 768px) {
    /* hardware content for services page */
    .hardware_content {
        padding: 15px;
        margin-top: 30%;
    }

    /* main heading hardware development */

    .hardware_content h5 {
        font-size: 2.5rem;
        padding: 5px;
    }

    /* image hardware development */

    .hardware_content .hardware_img img {
        width: 80vw;
        margin: 0;
    }

    /* text hardware development */

    .hardware_content .hardware_text1 p,
    .hardware_content .hardware_text2 p,
    .hardware_content .hardware_text3 p,
    .hardware_content .hardware_text4 p,
    .hardware_content .hardware_text5 p, 
    .hardware_content .hardware_text6 p {
        font-size: 16px;
        width: 90vw;
        margin-left: 0;
        padding: 20px;
    }

    /* text hardware development */

    .hardware_content .hardware_text1 h4,
    .hardware_content .hardware_text2 h4,
    .hardware_content .hardware_text3 h4,
    .hardware_content .hardware_text4 h4,
    .hardware_content .hardware_text5 h4,
    .hardware_content .hardware_text6 h4 {
        font-size: 22px;
        margin-left: 0;
        margin-top: 0;
    }

    /* applications list */

    .hardware_applications1,
    .hardware_applications2,
    .hardware_applications3,
    .hardware_applications4,
    .hardware_applications5,
    .hardware_applications6 {
        width: 90vw;
        text-align: justify;
        margin: 0 auto;
    }

    /* applications heading */

    .hardware_applications1 h4,
    .hardware_applications2 h4,
    .hardware_applications3 h4,
    .hardware_applications4 h4,
    .hardware_applications5 h4,
    .hardware_applications6 h4 {
        font-size: 22px;
        margin-left: 0;
        margin-top: 0;
    }

    /* applications content */

    .hardware_applications1 .hardware_list1 p,
    .hardware_applications2 .hardware_list2 p,
    .hardware_applications3 .hardware_list3 p,
    .hardware_applications4 .hardware_list4 p,
    .hardware_applications5 .hardware_list5 p,
    .hardware_applications6 .hardware_list6 p {
        font-size: 16px;
    }

    /* content for PM */

    .hardware_data_content p {
        font-size: 16px;
        margin-left: 0;
    }

    /* bullet for PM content */

    .hardware_data_content p::before {
        font-size: 24px;
        left: 10px;
    }
}

