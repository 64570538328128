
/* In home page image with container */

.main_container {
  position: relative;
  height: calc(100vh - 60px); /* Adjust based on navbar height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #ffffff;
  overflow: hidden;
  /* margin-bottom: 5%; */
}

.background_video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1; /* Make sure video is behind other content */
  margin-bottom: 5%;
}

/* in home page image above heading */


.main_container h1 {
  margin-top: -10px;
  font-size: 48px;
  backdrop-filter: blur(40px);
  /* margin-bottom: 10px; */
  margin-left: 2%;
  width: 40vw;
  color: #ffffff;
}

/* in home page image above para */

.main_container p {
  font-size: 30px;
  margin-bottom: 20px;
  margin-left: 2%;
  backdrop-filter: blur(40px);
  width: 40vw;
}

/* in home page image above button */

.main_container .learn_more_buttons {
  background-color: #aa498a;
  width: 10%;
  color: white;
  border: none;
  margin-left: 90%;
  padding: 12px 30px;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 87.5%;
  transition: background-color 0.3s ease;
}

/* in home page image above button hover */

.main_container .learn_more_buttons:hover {
  background-color: #752e91;
}

/* Cards with data */

.sub_container {
height: 100%;
display: flex;
align-items: center;
justify-content: center;
}

/* cards */

.sub_container {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  width: 50%;
  margin-left: 10%;
  margin-bottom: 10%;
  /* align-items: center; */
  /* justify-content: center; */
}

/* cards data text */

.card_data {
  position: relative;
  display: flex;
  justify-content: center;
  cursor: pointer;
  width: 22em;
  max-width: 80%;
  margin-bottom: 30px;
  padding: 2em 0;
  background: #80B3C4;
  box-shadow: 0 0 6px 0 rgba(32, 32, 36, 0.12);
  transition: all 0.35s ease;
}

/* cards hovering before and after */

.card_data::before, .card_data::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #000;
  height: 4px;
}

.card_data::before {
  width: 0;
  opacity: 0;
  transition: opacity 0 ease, width 0 ease;
  transition-delay: 0.5s;
}

.card_data::after {
  width: 100%;
  background: white;
  transition: width 0.5s ease;
}

/* in cards hovering content data */

.content_data {
  width: 18em;
  max-width: 80%;
  /* margin-bottom: 10px; */
}

/* cards heading */

h6 {
  color: #333;
  font-weight: 900;
  font-size: 1.0em;
  text-transform: uppercase;
  margin: 0;
  letter-spacing: 2px;
}

/* hovering effects for content */

.hover_content {
  overflow: hidden;
  max-height: 0;
  transform: translateY(1em);
  transition: all 0.55s ease;
}

.hover_content p {
  margin: 1.5em 0 0;
  color: #333;
  line-height: 1.4em;
  text-align: justify;
}

/* cards with content hover before and after */

.card_data:hover {
  width: 24em;
  box-shadow: 0 10px 20px 0 rgba(32, 32, 36, 0.12);
}

.card_data:hover::before {
  width: 100%;
  opacity: 1;
  transition: opacity 0.5s ease, width 0.5s ease;
  transition-delay: 0;
}

.card_data:hover::after {
  width: 0;
  opacity: 0;
  transition: width 0 ease;
}

.card_data:hover .hover_content {
  max-height: 10em;
  transform: none;
}

/* cards right side text container */

.sub_container2 {
  /* background-image: url('../../Assets/home_background.avif') !important; */
  background-size: cover;
  width: 40%;
  margin-left: 50%;
  margin-top: -23%;
  /* margin-bottom: 5%; */
}

.sub_container2 h3{
  color: #333;
  font-size: 35px;
  font-weight: 800;
  margin-left: 30%;
  /* margin-top: -63%; */
  margin-bottom: 20px;
}

.sub_container2 p{
  color: #fff;
}

/* image with data */

.sub_container3 {
  width: 80%;
  display: flex;
  justify-content: space-between;
  background: #fff;
  margin-left: 10%;
  /* margin-bottom: 10%; */
}

/* image side container */

.sub_first-container {
  background:  url("../../Assets/home_background.gif") center center / cover no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120vw;
  height: 60vh;
}

.sub_first-container .info-container h3 {
  color: #333;
  font-size: 25px;
  font-weight: 800;
  line-height: 1.2;
  padding-bottom: 10px;
  transition: all 0.5s ease;
}

/* right side content */

.second-container {
  padding: 30px;
  width: 75vw;
  margin-left: 20px;
}

.second-container p{
  color: #333;
  font-size: 20px;
  line-height: 1.6;
  font-weight: 500;
  text-align: justify;
}

/* more details button */

.second-container .learn_more_button {
  /* width: 20px; */
  /* height: 100px; */
  /* background: #00ad5f; */
  color: #333;
  font-size: 20px;
  font-weight: 600;
  /* text-transform: uppercase; */
  border: 0;
  left: 1%;
  position: relative;
  /* left: calc(50% - 50px); */
  cursor: pointer;
}

.learn_more_button:hover {
  /* background: #333; */
  text-decoration: underline;
}

@media screen and (max-width: 1920px) {
    
  /* In home page image with container */

  .main_container {
    position: relative;
    height: calc(100vh - 60px); /* Adjust based on navbar height */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #ffffff;
    overflow: hidden;
    margin-bottom: 5%;
  }

  .background_video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1; /* Make sure video is behind other content */
    margin-bottom: 5%;
  }

  /* in home page image above heading */


  .main_container h1 {
    margin-top: -10px;
    font-size: 48px;
    backdrop-filter: blur(40px);
    /* margin-bottom: 10px; */
    margin-left: 2%;
    width: 40vw;
    color: #ffffff;
  }

  /* in home page image above para */

  .main_container p {
    font-size: 30px;
    margin-bottom: 20px;
    margin-left: 2%;
    backdrop-filter: blur(40px);
    width: 40vw;
  }

  /* in home page image above button */

  .main_container .learn_more_buttons {
    background-color: #aa498a;
    width: 10%;
    color: white;
    border: none;
    margin-left: 90%;
    padding: 12px 30px;
    font-size: 18px;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 87.5%;
    transition: background-color 0.3s ease;
  }

  /* in home page image above button hover */

  .main_container .learn_more_buttons:hover {
    background-color: #752e91;
  }

  /* Cards with data */

  .sub_container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  }

  /* cards */

  .sub_container {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    width: 50%;
    margin-left: 10%;
    margin-bottom: 10%;
    /* align-items: center; */
    /* justify-content: center; */
  }

  /* cards data text */

  .card_data {
    position: relative;
    display: flex;
    justify-content: center;
    cursor: pointer;
    width: 22em;
    max-width: 80%;
    margin-bottom: 30px;
    padding: 2em 0;
    background: #80B3C4;
    box-shadow: 0 0 6px 0 rgba(32, 32, 36, 0.12);
    transition: all 0.35s ease;
  }

  /* cards hovering before and after */

  .card_data::before, .card_data::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #000;
    height: 4px;
  }

  .card_data::before {
    width: 0;
    opacity: 0;
    transition: opacity 0 ease, width 0 ease;
    transition-delay: 0.5s;
  }

  .card_data::after {
    width: 100%;
    background: white;
    transition: width 0.5s ease;
  }

  /* in cards hovering content data */

  .content_data {
    width: 18em;
    max-width: 80%;
    /* margin-bottom: 10px; */
  }

  /* cards heading */

  h6 {
    color: #333;
    font-weight: 900;
    font-size: 1.0em;
    text-transform: uppercase;
    margin: 0;
    letter-spacing: 2px;
  }

  /* hovering effects for content */

  .hover_content {
    overflow: hidden;
    max-height: 0;
    transform: translateY(1em);
    transition: all 0.55s ease;
  }

  .hover_content p {
    margin: 1.5em 0 0;
    color: #333;
    line-height: 1.4em;
    text-align: justify;
  }

  /* cards with content hover before and after */

  .card_data:hover {
    width: 24em;
    box-shadow: 0 10px 20px 0 rgba(32, 32, 36, 0.12);
  }

  .card_data:hover::before {
    width: 100%;
    opacity: 1;
    transition: opacity 0.5s ease, width 0.5s ease;
    transition-delay: 0;
  }

  .card_data:hover::after {
    width: 0;
    opacity: 0;
    transition: width 0 ease;
  }

  .card_data:hover .hover_content {
    max-height: 10em;
    transform: none;
  }

  /* cards right side text container */

  .sub_container2 {
    /* background-image: url('../../Assets/home_background.avif') !important; */
    background-size: cover;
    width: 40%;
    margin-left: 50%;
    margin-top: -22%;
    /* margin-bottom: 5%; */
  }

  .sub_container2 h3{
    color: #333;
    font-size: 35px;
    font-weight: 800;
    margin-left: 25%;
    margin-top: 10%;
    /* text-align: justify; */
    /* margin-bottom: 20px; */
  }

  .sub_container2 p{
    color: #fff;
  }

  /* image with data */

  .sub_container3 {
    width: 80%;
    display: flex;
    justify-content: space-between;
    background: #fff;
    margin-left: 10%;
    /* margin-bottom: 10%; */
  }

  /* image side container */

  .sub_first-container {
    background:  url("../../Assets/home_background.gif") center center / cover no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120vw;
    height: 60vh;
  }

  .sub_first-container .info-container h3 {
    color: #333;
    font-size: 25px;
    font-weight: 800;
    line-height: 1.2;
    padding-bottom: 10px;
  }

  /* right side content */

  .second-container {
    padding: 30px;
    width: 75vw;
    margin-left: 20px;
  }

  .second-container p{
    color: #333;
    font-size: 20px;
    line-height: 1.6;
    font-weight: 500;
    text-align: justify;
  }

  /* more details button */

  .second-container .learn_more_button {
    /* width: 20px; */
    /* height: 100px; */
    /* background: #00ad5f; */
    color: #333;
    font-size: 20px;
    font-weight: 600;
    /* text-transform: uppercase; */
    border: 0;
    left: 1%;
    position: relative;
    /* left: calc(50% - 50px); */
    cursor: pointer;
  }

  .learn_more_button:hover {
    /* background: #333; */
    text-decoration: underline;
  }
}

@media screen and (max-width: 1536px){
  
  /* In home page image with container */

  .main_container {
    position: relative;
    height: calc(100vh - 60px); /* Adjust based on navbar height */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #ffffff;
    overflow: hidden;
    margin-bottom: 5%;
  }

  .background_video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1; /* Make sure video is behind other content */
    margin-bottom: 5%;
  }

  /* in home page image above heading */


  .main_container h1 {
    margin-top: -10px;
    font-size: 48px;
    backdrop-filter: blur(40px);
    /* margin-bottom: 10px; */
    margin-left: 2%;
    width: 40vw;
    color: #ffffff;
  }

  /* in home page image above para */

  .main_container p {
    font-size: 30px;
    margin-bottom: 20px;
    margin-left: 2%;
    backdrop-filter: blur(40px);
    width: 40vw;
  }

  /* in home page image above button */

  .main_container .learn_more_buttons {
    background-color: #aa498a;
    width: 10%;
    color: white;
    border: none;
    margin-left: 90%;
    padding: 12px 30px;
    font-size: 18px;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 87.5%;
    transition: background-color 0.3s ease;
  }

  /* in home page image above button hover */

  .main_container .learn_more_buttons:hover {
    background-color: #752e91;
  }

  /* Cards with data */

  .sub_container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  }

  /* cards */

  .sub_container {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    width: 50%;
    margin-left: 10%;
    margin-bottom: 10%;
    /* align-items: center; */
    /* justify-content: center; */
  }

  /* cards data text */

  .card_data {
    position: relative;
    display: flex;
    justify-content: center;
    cursor: pointer;
    width: 22em;
    max-width: 80%;
    margin-bottom: 30px;
    padding: 2em 0;
    background: #80B3C4;
    box-shadow: 0 0 6px 0 rgba(32, 32, 36, 0.12);
    transition: all 0.35s ease;
  }

  /* cards hovering before and after */

  .card_data::before, .card_data::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #000;
    height: 4px;
  }

  .card_data::before {
    width: 0;
    opacity: 0;
    transition: opacity 0 ease, width 0 ease;
    transition-delay: 0.5s;
  }

  .card_data::after {
    width: 100%;
    background: white;
    transition: width 0.5s ease;
  }

  /* in cards hovering content data */

  .content_data {
    width: 18em;
    max-width: 80%;
    /* margin-bottom: 10px; */
  }

  /* cards heading */

  h6 {
    color: #333;
    font-weight: 900;
    font-size: 1.0em;
    text-transform: uppercase;
    margin: 0;
    letter-spacing: 2px;
  }

  /* hovering effects for content */

  .hover_content {
    overflow: hidden;
    max-height: 0;
    transform: translateY(1em);
    transition: all 0.55s ease;
  }

  .hover_content p {
    margin: 1.5em 0 0;
    color: #333;
    line-height: 1.4em;
    text-align: justify;
  }

  /* cards with content hover before and after */

  .card_data:hover {
    width: 24em;
    box-shadow: 0 10px 20px 0 rgba(32, 32, 36, 0.12);
  }

  .card_data:hover::before {
    width: 100%;
    opacity: 1;
    transition: opacity 0.5s ease, width 0.5s ease;
    transition-delay: 0;
  }

  .card_data:hover::after {
    width: 0;
    opacity: 0;
    transition: width 0 ease;
  }

  .card_data:hover .hover_content {
    max-height: 10em;
    transform: none;
  }

  /* cards right side text container */

  .sub_container2 {
    /* background-image: url('../../Assets/home_background.avif') !important; */
    background-size: cover;
    width: 40%;
    margin-left: 50%;
    margin-top: -23%;
    /* margin-bottom: 5%; */
  }

  .sub_container2 h3{
    color: #333;
    font-size: 35px;
    font-weight: 800;
    margin-left: 30%;
    margin-top: -63%;
    margin-bottom: 20px;
  }

  .sub_container2 p{
    color: #fff;
  }

  /* image with data */

  .sub_container3 {
    width: 80%;
    display: flex;
    justify-content: space-between;
    background: #fff;
    margin-left: 10%;
    /* margin-bottom: 10%; */
  }

  /* image side container */

  .sub_first-container {
    background:  url("../../Assets/home_background.gif") center center / cover no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120vw;
    height: 60vh;
  }

  .sub_first-container .info-container h3 {
    color: #333;
    font-size: 25px;
    font-weight: 800;
    line-height: 1.2;
    padding-bottom: 10px;
  }

  /* right side content */

  .second-container {
    padding: 30px;
    width: 75vw;
    margin-left: 20px;
  }

  .second-container p{
    color: #333;
    font-size: 20px;
    line-height: 1.6;
    font-weight: 500;
    text-align: justify;
  }

  /* more details button */

  .second-container .learn_more_button {
    /* width: 20px; */
    /* height: 100px; */
    /* background: #00ad5f; */
    color: #333;
    font-size: 20px;
    font-weight: 600;
    /* text-transform: uppercase; */
    border: 0;
    left: 1%;
    position: relative;
    /* left: calc(50% - 50px); */
    cursor: pointer;
  }

  .learn_more_button:hover {
    /* background: #333; */
    text-decoration: underline;
  }
  
}

@media (max-width: 1265px) {

  /* In home page image with container */

  .main_container {
    position: relative;
    height: calc(100vh - 60px); /* Adjust based on navbar height */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #ffffff;
    overflow: hidden;
    margin-bottom: 5%;
  }

  .background_video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1; /* Make sure video is behind other content */
    margin-bottom: 5%;
  }

  /* in home page image above heading */


  .main_container h1 {
    margin-top: -10px;
    font-size: 48px;
    backdrop-filter: blur(40px);
    /* margin-bottom: 10px; */
    margin-left: 2%;
    width: 40vw;
    color: #ffffff;
  }

  /* in home page image above para */

  .main_container p {
    font-size: 30px;
    margin-bottom: 20px;
    margin-left: 2%;
    backdrop-filter: blur(40px);
    width: 40vw;
  }

  /* in home page image above button */

  .main_container .learn_more_buttons {
    background-color: #aa498a;
    width: 10%;
    color: white;
    border: none;
    margin-left: 90%;
    padding: 12px 30px;
    font-size: 18px;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 87.5%;
    transition: background-color 0.3s ease;
  }

  /* in home page image above button hover */

  .main_container .learn_more_buttons:hover {
    background-color: #752e91;
  }

  /* Cards with data */

  .sub_container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  }

  /* cards */

  .sub_container {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    width: 50%;
    margin-left: 10%;
    margin-bottom: 10%;
    /* align-items: center; */
    /* justify-content: center; */
  }

  /* cards data text */

  .card_data {
    position: relative;
    display: flex;
    justify-content: center;
    cursor: pointer;
    width: 22em;
    max-width: 80%;
    margin-bottom: 30px;
    padding: 2em 0;
    background: #80B3C4;
    box-shadow: 0 0 6px 0 rgba(32, 32, 36, 0.12);
    transition: all 0.35s ease;
  }

  /* cards hovering before and after */

  .card_data::before, .card_data::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #000;
    height: 4px;
  }

  .card_data::before {
    width: 0;
    opacity: 0;
    transition: opacity 0 ease, width 0 ease;
    transition-delay: 0.5s;
  }

  .card_data::after {
    width: 100%;
    background: white;
    transition: width 0.5s ease;
  }

  /* in cards hovering content data */

  .content_data {
    width: 18em;
    max-width: 80%;
    /* margin-bottom: 10px; */
  }

  /* cards heading */

  h6 {
    color: #333;
    font-weight: 900;
    font-size: 1.0em;
    text-transform: uppercase;
    margin: 0;
    letter-spacing: 2px;
  }

  /* hovering effects for content */

  .hover_content {
    overflow: hidden;
    max-height: 0;
    transform: translateY(1em);
    transition: all 0.55s ease;
  }

  .hover_content p {
    margin: 1.5em 0 0;
    color: #333;
    line-height: 1.4em;
    text-align: justify;
  }

  /* cards with content hover before and after */

  .card_data:hover {
    width: 24em;
    box-shadow: 0 10px 20px 0 rgba(32, 32, 36, 0.12);
  }

  .card_data:hover::before {
    width: 100%;
    opacity: 1;
    transition: opacity 0.5s ease, width 0.5s ease;
    transition-delay: 0;
  }

  .card_data:hover::after {
    width: 0;
    opacity: 0;
    transition: width 0 ease;
  }

  .card_data:hover .hover_content {
    max-height: 10em;
    transform: none;
  }

  /* cards right side text container */

  .sub_container2 {
    /* background-image: url('../../Assets/home_background.avif') !important; */
    background-size: cover;
    width: 40%;
    margin-left: 50%;
    /* margin-top: -1vh; */
    /* margin-bottom: 5%; */
  }

  .sub_container2 h3{
    color: #333;
    font-size: 35px;
    font-weight: 800;
    margin-left: 30%;
    margin-top: -63vh;
    /* margin-bottom: 20px; */
  }

  .sub_container2 p{
    color: #fff;
  }

  /* image with data */

  .sub_container3 {
    width: 80%;
    display: flex;
    justify-content: space-between;
    background: #fff;
    margin-left: 10%;
    margin-bottom: 10%;
  }

  /* image side container */

  .sub_first-container {
    background:  url("../../Assets/home_background.gif") center center / cover no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120vw;
    height: 60vh;
  }

  .sub_first-container .info-container h3 {
    color: #333;
    font-size: 25px;
    font-weight: 800;
    line-height: 1.2;
    padding-bottom: 10px;
  }

  /* right side content */

  .second-container {
    padding: 30px;
    width: 75vw;
    margin-left: 20px;
  }

  .second-container p{
    color: #333;
    font-size: 20px;
    line-height: 1.6;
    font-weight: 500;
    text-align: justify;
  }

  /* more details button */

  .second-container .learn_more_button {
    /* width: 20px; */
    /* height: 100px; */
    /* background: #00ad5f; */
    color: #333;
    font-size: 20px;
    font-weight: 600;
    /* text-transform: uppercase; */
    border: 0;
    left: 1%;
    position: relative;
    /* left: calc(50% - 50px); */
    cursor: pointer;
  }

  .learn_more_button:hover {
    /* background: #333; */
    text-decoration: underline;
  }
}


/* Responsive CSS for Tablet View - max-width 768px */
@media (max-width: 768px) {
  /* In home page image */
  .main_container {
    position: relative;
    height: calc(100vh - 60px); /* Adjust based on navbar height */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #ffffff;
    overflow: hidden;
    margin-bottom: 5%;
  }

  /* In home page content heading */

  /* .main_container h1, */
  .main_container h1 {
    font-size: 22px;
    /* margin-top: 20%; */
    margin-bottom: 10px;
    width: 40vw;
  }

  /* .main_container h{
    font-size: 22px;
    margin-bottom: 10px;
  } */

  .main_container p {
    font-size: 18px;
  }

  /* In home page learn more button */

  .main_container .learn_more_buttons {
    width: 40%;
    /* margin: 0 auto; */
    padding: 10px 10px;
    margin-left: 90%;
    font-size: 16px;
  }

  /* In home page cards */

  .sub_container {
    width: 90%;
    margin: 5% auto;
    grid-template-columns: 1fr;
  }

  /* In home page cards */

  .card_data {
    width: 100%;
    margin-bottom: 20px;
    padding: 1em 0;
  }

  /* In home page cards with content data */

  .content_data {
    width: 90%;
  }

  /* In home page cards with content hover effective */

  .hover_content p {
    font-size: 14px;
  }

  /* In home page right side content */

  .sub_container2 {
    width: 100%;
    margin-left: -90px;
    margin-top: 130%;
  }

  /* In home page right side content heading */

  .sub_container2 h3 {
    font-size: 24px;
  }

  .sub_container2 p {
    font-size: 16px;
  }

  /* image with data */

  .sub_container3 {
    width: 100%;
    flex-direction: column;
    margin: 0 auto;
    margin-top: 20%;
  }

  /* image side container */

  .sub_first-container {
    background:  url("../../Assets/home_background.gif") center center / cover no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150vw;
    height: 30vh;
    margin-left: -25%;
  }
  
  /* image above heading */

  .info-container h3 {
    font-size: 20px;
  }

  /* right side content */

  .second-container {
    width: 90%;
    margin: 0 auto;
    padding: 20px;
  }

  /* right side content */

  .second-container p {
    font-size: 18px;
  }

  /* right side content more details button */

  .second-container .more_details_button {
    width: 100%;
    height: 50px;
    left: 0;
    font-size: 15px;
  }
}


