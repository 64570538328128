/* Software Development service content */

.software_content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin-top: 8%;
}

/* main heading software development */

.software_content h5{
    font-size: 3.5rem;
    text-align: center;
    align-items: center;
    padding: 10px;
    color: #2348E2;
}

/* para content */

.software_para_content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.software_para_content p{
    font-size: 22px;
    text-align: justify;
    align-items: center;
    padding: 10px;
    /* color: #2348E2; */
    width: 80vw;
    margin-left: 75px;
    padding: 20px;
}

/* main image content below the heading */

.software_para_content .img1{
    width: 80vw;
    height: 80vh;
    margin-left: 75px;
}

/* benefits content */

.software_benefits{
    position: relative;
    width: 80%;
    margin: 0 auto;
}

/* benefits heading */

.software_benefits h5{
    font-size: 35px;
    text-align: justify;
    align-items: center;
    color: #2348E2;
    width: 50vw;
    margin-left: 25px;
}

/* benefits image */

.software_benefits .img2{
    float: right;
    width: 50%;
    margin-top: 5%;
    margin-left: 20px;
}

.software_benefits .benefits{
    font-size: 20px;
    text-align: justify;
    align-items: center;
    padding: 10px;
    /* color: #2348E2; */
    width: 50vw;
    margin-left: 25px;
    padding: 20px;
}

.software_benefits .benefits p{
    position: relative; /* For positioning the pseudo-element */
    padding-left: 55px; /* Space for the bullet */
    margin: 5px 0; /* Add margin between paragraphs */
    /* color: #2348E2; */
    font-size: 20px;
}

.software_benefits .benefits p::before {
    content: '•'; /* Bullet character */
    position: absolute;
    left: 0;
    /* color: #2348E2; */
}

/* usage content */

.software_usage{
    position: relative;
    width: 80%;
    margin: 0 auto;
    padding-top: 10px;
    margin-top: 10px;
}

/* usage heading */

.software_usage h5{
    font-size: 35px;
    text-align: justify;
    align-items: center;
    padding: 10px;
    color: #2348E2;
    float: right;
    margin-right: 100px;
}

/* usage image */

.software_usage .img3{
    float: left;
    margin-top: -28%;
    width: 30vw;
    height: 50vh;
    margin-left: 60px;
}

.software_usage .usage p{
    position: relative; /* For positioning the pseudo-element */ /* Space for the bullet */
    /* color: #2348E2; */
    width: 35vw;
    float: right;
    margin-left: 60%;
    font-size: 20px;
    line-height: 1.5;
    text-align: justify;
    padding-right: 40px;
} 

.software_usage .usage p::before {
    content: '•'; /* Bullet character */
    position: absolute;
    left: -20px;    
    /* color: #2348E2; */
}

/* applications content */

.software_applications {
    position: relative;
    width: 80%;
    margin: 0 auto;
    padding-top: 10px;
    margin-top: 10px;
}

/* applications heading */

.software_applications h5{
    font-size: 35px;
    text-align: justify;
    align-items: center;
    padding: 10px;
    color: #2348E2;
    float: left;
    margin-right: 210px;
}

/* applications image */

.software_applications .img4{
    float: right;
    margin-top: -25%;
    width: 30vw;
    height: 40vh;
    margin-right: 90px;
}

.software_applications .applications{
    font-size: 20px;
    text-align: justify;
    align-items: center;
    padding: 10px;
    /* color: #2348E2; */
    width: 50vw;
    float: left;
}

.software_applications .applications p{
    position: relative; /* For positioning the pseudo-element */ /* Space for the bullet */
    /* color: #2348E2; */
    width: 35vw;
    float: left;
    font-size: 20px;
    line-height: 1.5;
    padding-left: 75px;
}

.software_applications .applications p::before {
    content: '•'; /* Bullet character */
    position: absolute;
    left: 20px;
    /* color: #2348E2; */
}

/* down image */

.software_down_image{
    width: 60vw;
    margin-left: 100px;
    margin-top: 50px;
}

.software_down_image .img5{
    width: 100%;
    height: auto;
}

@media screen and (max-width: 1263px) {
    /* Software Development service content */

    .software_content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
        margin-top: 8%;
        margin-right: 6%;
    }

    /* main heading software development */

    .software_content h5{
        font-size: 3.5rem;
        text-align: center;
        align-items: center;
        padding: 10px;
        color: #2348E2;
    }

    /* para content */

    .software_para_content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px;
    }

    .software_para_content p{
        font-size: 22px;
        text-align: justify;
        align-items: center;
        padding: 10px;
        /* color: #2348E2; */
        width: 80vw;
        margin-left: 75px;
        padding: 20px;
    }

    /* main image content below the heading */

    .software_para_content .img1{
        width: 80vw;
        height: 80vh;
        margin-left: 75px;
    }

    /* benefits content */

    .software_benefits{
        position: relative;
        width: 80%;
        margin: 0 auto;
    }

    /* benefits heading */

    .software_benefits h5{
        font-size: 35px;
        text-align: justify;
        align-items: center;
        color: #2348E2;
        width: 50vw;
        margin-left: 18%;
    }

    /* benefits image */

    .software_benefits .img2{
        float: right;
        width: 30vw;
        margin-top: 20%;
        margin-left: 20px;
    }

    .software_benefits .benefits{
        font-size: 20px;
        text-align: justify;
        align-items: center;
        padding: 10px;
        /* color: #2348E2; */
        width: 35vw;
        margin-left: 15%;
        /* padding: 20px; */
    }

    .software_benefits .benefits p{
        position: relative; /* For positioning the pseudo-element */
        padding-left: 55px; /* Space for the bullet */
        margin: 5px 0; /* Add margin between paragraphs */
        /* color: #2348E2; */
        font-size: 20px;
    }

    .software_benefits .benefits p::before {
        content: '•'; /* Bullet character */
        position: absolute;
        left: 0;
        /* color: #2348E2; */
    }

    /* usage content */

    .software_usage{
        position: relative;
        width: 80%;
        margin: 0 auto;
        padding-top: 10px;
        margin-top: 10px;
    }

    /* usage heading */

    .software_usage h5{
        font-size: 35px;
        text-align: justify;
        align-items: center;
        padding: 10px;
        color: #2348E2;
        float: right;
        margin-right: 100px;
    }

    /* usage image */

    .software_usage .img3{
        float: left;
        margin-top: -45%;
        width: 20vw;
        height: 45vh;
        margin-left: 18%;
    }

    .software_usage .usage p{
        position: relative; /* For positioning the pseudo-element */ /* Space for the bullet */
        /* color: #2348E2; */
        width: 35vw;
        float: none;
        margin-left: 60%;
        font-size: 20px;
        line-height: 1.5;
        text-align: justify;
        padding-right: 40px;
    } 

    .software_usage .usage p::before {
        content: '•'; /* Bullet character */
        position: absolute;
        left: -20px;    
        /* color: #2348E2; */
    }

    /* applications content */

    .software_applications {
        position: relative;
        width: 80%;
        margin: 0 auto;
        padding-top: 10px;
        margin-top: 10px;
    }

    /* applications heading */

    .software_applications h5{
        font-size: 35px;
        text-align: justify;
        align-items: center;
        padding: 10px;
        color: #2348E2;
        float: left;
        margin-left: 50px;
        /* margin-right: 210px; */
    }

    /* applications image */

    .software_applications .img4{
        float: none;
        margin-top: -120%;
        margin-left: 60%;
        margin-bottom: 16%;
        width: 30vw;
        height: 40vh;
        /* margin-right: 90px; */
    }

    .software_applications .applications{
        font-size: 20px;
        text-align: justify;
        align-items: center;
        padding: 10px;
        /* color: #2348E2; */
        width: 50vw;
        float: left;
    }

    .software_applications .applications p{
        position: relative; /* For positioning the pseudo-element */ /* Space for the bullet */
        /* color: #2348E2; */
        width: 35vw;
        float: left;
        font-size: 20px;
        line-height: 1.5;
        padding-left: 75px;
    }

    .software_applications .applications p::before {
        content: '•'; /* Bullet character */
        position: absolute;
        left: 20px;
        /* color: #2348E2; */
    }

    /* down image */

    .software_down_image{
        width: 60vw;
        margin-left: 100px;
        margin-top: 50px;
    }

    .software_down_image .img5{
        width: 100%;
        height: auto;
    }
    
}

/* Responsive styles for tablet view (max-width: 768px) */

@media (max-width: 768px) {
    /* General adjustments */

    .software_content {
        padding: 10px;
        margin-top: 25%;
    }

    .software_para_content,
    .software_benefits,
    .software_usage,
    .software_applications {
        width: 90%;
        margin: 0 auto;
        padding: 10px;
    }

    /* Main heading software development */
    .software_content h5 {
        font-size: 2.5rem;
        padding: 5px;
    }

    /* Para content */
    .software_para_content p {
        font-size: 18px;
        width: 100%;
        margin-left: 0;
    }

    /* Main image content below the heading */
    .software_para_content .img1 {
        width: 100%;
        height: auto;
        margin-left: 0;
    }

    /* Benefits content */
    .software_benefits {
        width: 100%;
        padding: 10px 0;
    }

    /* Benefits heading */
    .software_benefits h5 {
        font-size: 28px;
        width: 100%;
        margin-left: 0;
    }

    /* Benefits image */
    .software_benefits .img2 {
        width: 100%;
        margin-top: 10px;
        margin-left: 0;
    }

    /* Benefits text */
    .software_benefits .benefits {
        width: 100%;
        font-size: 18px;
        padding: 10px 0;
        margin-left: 0;
    }

    /* Usage content */
    .software_usage {
        width: 100%;
        padding: 10px 0;
    }

    /* Usage heading */
    .software_usage h5 {
        font-size: 28px;
        float: none;
        margin-left: -6%;
        margin-right: 0;
    }

    /* Usage image */
    .software_usage .img3 {
        width: 100%;
        height: auto;
        margin-left: 0;
        margin-top: 10px;
    }

    /* Usage text */
    .software_usage .usage p {
        width: 100%;
        font-size: 18px;
        margin-left: 0;
        padding-right: 0;
        text-align: justify;
    }

    /* Applications content */
    .software_applications {
        width: 100vw;
        padding: 10px 0;
    }

    /* Applications heading */
    .software_applications h5 {
        font-size: 28px;
        float: none;
        margin-right: 0;
        margin-left: 10%;
    }

    /* Applications image */
    .software_applications .img4 {
        width: 60vw;
        height: auto;
        margin-left: 15%;
        margin-top: 5%;
        /* margin-right: 0; */
        /* margin-top: 10px; */
    }

    /* Applications text */
    .software_applications .applications p{
        width: 80vw;
        font-size: 18px;
        margin-left: 15%;
        padding: 10px 0;
    }

    .software_applications .applications p::before {
        left: -30px;
    }

    /* Down image */
    .software_down_image .img5{
        width: 80vw;
        margin-left: -30%;
        /* margin-right: 100%; */
        margin-top: 20px;
    }
}


@media (max-width: 429px) and (min-width: 426px) {
    /* General adjustments */
    .software_content {
        padding: 10px;
        margin-top: 15%;
    }

    /* Main heading software development */
    .software_content h5 {
        font-size: 2.5rem; /* Adjusted font size */
        padding: 5px;
    }

    /* Para content */
    .software_para_content p {
        font-size: 18px; /* Smaller font size */
        width: 90vw; /* Adjust width for mobile */
        margin-left: 0; /* Remove left margin */
        padding: 15px; /* Adjust padding */
    }

    /* Main image content below the heading */
    .software_para_content .img1 {
        width: 90vw; /* Adjust image width */
        height: auto; /* Maintain aspect ratio */
        margin-left: 0; /* Remove left margin */
    }

    /* Benefits content */
    .software_benefits h5 {
        font-size: 30px; /* Adjusted font size */
        width: 90vw; /* Adjust width */
        margin-left: 0; /* Remove left margin */
    }

    .software_benefits .img2 {
        width: 80%; /* Adjust image width */
        margin-top: 5%;
        margin-left: 0; /* Remove left margin */
    }

    .software_benefits .benefits {
        width: 90vw; /* Adjust width */
        margin-left: 0; /* Remove left margin */
    }

    /* Usage content */
    .software_usage h5 {
        font-size: 30px; /* Adjusted font size */
        float: none; /* Reset float for mobile */
        text-align: center; /* Center-align text */
        margin: 0 auto; /* Center align the heading */
    }

    .software_usage .img3 {
        width: 80vw; /* Adjust image width */
        height: auto; /* Maintain aspect ratio */
        margin-left: 0; /* Remove left margin */
    }

    .software_usage .usage p {
        width: 90vw; /* Adjust width */
        float: none; /* Reset float for mobile */
        margin: 0 auto; /* Center align the paragraph */
    }

    /* Applications content */
    .software_applications h5 {
        font-size: 30px; /* Adjusted font size */
        float: none; /* Reset float for mobile */
        text-align: center; /* Center-align text */
        margin: 0 auto; /* Center align the heading */
    }

    .software_applications .img4 {
        width: 80vw; /* Adjust image width */
        height: auto; /* Maintain aspect ratio */
        margin-left: 10%; /* Remove right margin */
    }

    .software_applications .applications p{
        width: 80vw; /* Adjust width */
        float: none; /* Reset float for mobile */
        margin: 0 auto; /* Center align the applications section */
        margin-left: 10%;
    }

    /* Down image */
    .software_down_image {
        width: 90vw; /* Adjust image width */
        margin-left: 0; /* Remove left margin */
        margin-top: 20px; /* Adjust top margin */
    }

    .software_down_image .img5 {
        width: 100%;
        height: auto; /* Maintain aspect ratio */
        margin-left: 2%;
    }
}


/* Mobile view adjustments for max-width 425px and 320px */
@media only screen and (max-width: 425px)  {
    .software_content {
        padding: 10px;
        margin-top: 20%;
    }

    .software_content h5 {
        font-size: 2.5rem;
        padding: 5px;
    }

    .software_para_content {
        padding: 5px;
    }

    .software_para_content p {
        font-size: 18px;
        width: 100%;
        margin-left: 0;
        padding: 10px;
    }

    .software_para_content .img1 {
        width: 100%;
        height: auto;
        margin-left: 0;
    }

    .software_benefits {
        width: 100%;
        margin: 0;
    }

    .software_benefits h5 {
        font-size: 28px;
        width: 100%;
        margin-left: 0;
    }

    .software_benefits .img2 {
        width: 100%;
        float: none;
        margin-top: 10px;
        margin-left: 0;
    }

    .software_benefits .benefits {
        font-size: 18px;
        width: 80vw;
        margin-left: 0;
        padding: 10px;
    }

    .software_benefits .benefits p {
        padding-left: 30px;
        margin: 5px 0;
    }

    .software_benefits .benefits p::before {
        left: 10px;
    }

    .software_usage {
        width: 80vw;
        margin: 0;
        padding-top: 5px;
        margin-top: 5px;
    }

    .software_usage h5 {
        font-size: 28px;
        float: none;
        margin-right: 0;
        text-align: center;
    }

    .software_usage .img3 {
        width: 80%;
        height: auto;
        float: none;
        margin-left: 0;
        margin-top: 10px;
    }

    .software_usage .usage p {
        width: 100%;
        float: none;
        margin-left: 0;
        padding-right: 0;
        font-size: 18px;
    }

    .software_usage .usage p::before {
        left: -10px;
    }

    .software_applications {
        width: 80vw;
        margin: 0;
        padding-top: 5px;
        margin-top: 5px;
    }

    .software_applications h5 {
        font-size: 28px;
        float: none;
        margin-right: 0;
        text-align: center;
    }

    .software_applications .img4 {
        width: 80%;
        height: auto;
        float: none;
        margin-right: 0;
        margin-top: 10px;
    }

    .software_applications .applications p{
        font-size: 18px;
        width: 80vw;
        float: none;
    }

    .software_applications .applications p {
        padding-left: 0px;
        font-size: 18px;
    }

    .software_applications .applications p::before {
        left: -15px;
    }

    .software_down_image {
        width: 100%;
        margin-left: 0;
        margin-top: 20px;
    }

    .software_down_image .img5 {
        width: 100%;
        height: auto;
        margin-left: 3%;
    }
}


