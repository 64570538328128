/* privacy policy container page */
.policy-container {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 2%;
    margin-bottom: 5%;
}

/* heading for all  */

.policy-container h1{
    font-size: 28px;
    text-align: left;
    margin-bottom: 20px;
    color: black;
}

/* policy text para 1 */

.policy-container .policy-text p{
    font-size: 18px;
    text-align: justify;
    margin-bottom: 20px;
    /* line-height: 1.5; */
    color: black;
}

/* policy text para 2 */

.policy-container .policy-text1 h1{
    font-size: 24px;
    text-align: left;
    margin-bottom: 20px;
    margin-top: -1%;
    color: black;
}

.policy-container .policy-text1 p{
    font-size: 18px;
    text-align: justify;
    margin-bottom: 20px;
    /* margin-top: -5%; */
    /* line-height: 1.5; */
    color: black;
}

/* policy text para 3 */

.policy-container .policy-text2 h1{
    font-size: 24px;
    text-align: left;
    margin-bottom: 20px;
    margin-top: -1%;
    color: black;
}

.policy-container .policy-text2 p{
    font-size: 18px;
    text-align: justify;
    margin-bottom: 20px;
    /* line-height: 1.5; */
    color: black;
}

/* policy text para 4 */

.policy-container .policy-text3 h1{
    font-size: 24px;
    text-align: left;
    margin-bottom: 20px;
    margin-top: -1%;
    color: black;
}

.policy-container .policy-text3 p{
    font-size: 18px;
    text-align: justify;
    margin-bottom: 20px;
    /* line-height: 1.5; */
    color: black;
}

/* policy text para 5 */

.policy-container .policy-text4 h1{
    font-size: 24px;
    text-align: left;
    margin-bottom: 20px;
    margin-top: -1%;
    color: black;
}

.policy-container .policy-text4 p{
    font-size: 18px;
    text-align: justify;
    margin-bottom: 20px;
    /* line-height: 1.5; */
    color: black;
}

/* policy text para 6 */

.policy-container .policy-text5 h1{
    font-size: 24px;
    text-align: left;
    margin-bottom: 20px;
    margin-top: -1%;
    color: black;
}

.policy-container .policy-text5 p{
    font-size: 18px;
    text-align: justify;
    margin-bottom: 20px;
    /* line-height: 1.5; */
    color: black;
}

/* policy text para 7 */

.policy-container .policy-text6 h1{
    font-size: 24px;
    text-align: left;
    margin-bottom: 20px;
    margin-top: -1%;
    color: black;
}

.policy-container .policy-text6 p{
    font-size: 18px;
    text-align: justify;
    margin-bottom: 20px;
    /* line-height: 1.5; */
    color: black;
}

/* policy text para 8 */

.policy-container .policy-text7 h1{
    font-size: 24px;
    text-align: left;
    margin-bottom: 20px;
    margin-top: -1%;
    color: black;
}

.policy-container .policy-text7 p{
    font-size: 18px;
    text-align: justify;
    margin-bottom: 20px;
    /* line-height: 1.5; */
    color: black;
}

/* policy text para 9 */

.policy-container .policy-text8 h1{
    font-size: 24px;
    text-align: left;
    margin-bottom: 20px;
    margin-top: -1%;
    color: black;
}

.policy-container .policy-text8 p{
    font-size: 18px;
    text-align: justify;
    margin-bottom: 20px;
    /* line-height: 1.5; */
    color: black;
}

/* policy text para 10 */

.policy-container .policy-text9 h1{
    font-size: 24px;
    text-align: left;
    margin-bottom: 20px;
    margin-top: -1%;
    color: black;
}

.policy-container .policy-text9 p{
    font-size: 18px;
    text-align: justify;
    margin-bottom: 20px;
    /* line-height: 1.5; */
    color: black;
}

/* policy text para 11 */

.policy-container .policy-text10 h1{
    font-size: 24px;
    text-align: left;
    margin-bottom: 20px;
    margin-top: -1%;
    color: black;
}

.policy-container .policy-text10 p{
    font-size: 18px;
    text-align: justify;
    margin-bottom: 20px;
    /* line-height: 1.5; */
    color: black;
}

/* policy text para 12 */

.policy-container .policy-text11 h1{
    font-size: 24px;
    text-align: left;
    margin-bottom: 20px;
    margin-top: -1%;
    color: black;
}

.policy-container .policy-text11 p{
    font-size: 18px;
    text-align: justify;
    margin-bottom: 20px;
    /* line-height: 1.5; */
    color: black;
}

/* policy text para 13 */

.policy-container .policy-text12 h1{
    font-size: 24px;
    text-align: left;
    margin-bottom: 20px;
    margin-top: -1%;
    color: black;
}

.policy-container .policy-text12 p{
    font-size: 18px;
    text-align: justify;
    margin-bottom: 20px;
    /* line-height: 1.5; */
    color: black;
}

/* media query for tablet view */

@media only screen and (max-width: 768px) {
    /* privacy policy container */
    .policy-container {
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
        margin-top: 5%;
        /* margin-bottom: 5%; */
    }

    /* heading for all */

    .policy-container h1 {
        font-size: 24px;
        text-align: center;
        margin-bottom: 15px;
    }

    /* policy text para all */

    .policy-container .policy-text p,
    .policy-container .policy-text1 p,
    .policy-container .policy-text2 p,
    .policy-container .policy-text3 p,
    .policy-container .policy-text4 p,
    .policy-container .policy-text5 p,
    .policy-container .policy-text6 p,
    .policy-container .policy-text7 p,
    .policy-container .policy-text8 p,
    .policy-container .policy-text9 p,
    .policy-container .policy-text10 p,
    .policy-container .policy-text11 p,
    .policy-container .policy-text12 p {
        font-size: 16px;
        text-align: justify;
        /* margin-bottom: 15px; */

    }
}
