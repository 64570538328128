/* Navbar Container */
/* Sticky header visibility */
.sticky-header {
    display: flex;
    position: fixed;
    z-index: 5000;
    top: 0;
    left: -70px;
    right: 0;
    height: 90px;
    backdrop-filter: blur(10px);
    background: rgba(0, 0, 0, 0.85); /* Slightly transparent */
    justify-content: space-between;
    padding: 10px;
    width: 100vw;
    transition: top 0.3s ease;
}
  
.sticky-header.visible {
    display: flex;
}

.sticky-header .logo {
    width: 60px;
    margin-left: 90px;
}

.navbar {
    width: 90%;
    height: 80px; /* Reduced height for a sleeker look */
    background-color: #333; /* White background */
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: space-between; /* Space between logo and menu */
    align-items: center;
    padding: 0 3%; /* Adds spacing on left and right */
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    position: absolute; /* Fixes navbar at the top */
    /* border-radius: 20%; */
    top: 20px;
    left: 70px;
    z-index: 10; /* Keeps navbar on top of all other content */
    border-radius: 40px 40px 40px 40px; /* This creates a semi-circle effect */
}

/* Active class for sticky header */
.navbar.is-sticky {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: rgba(51, 51, 51, 0.9); /* Semi-transparent for sticky */
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
    transition: top 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

/* Logo Styling */
.logo {
    width: 60px;
    margin-top: 5px;
    cursor: pointer;
    z-index: -1;
}

.navbar li a:hover {
    color: #fff;
}
.navbar ul li a::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: rgb(255, 255, 255);
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
}
.navbar ul li a:hover::before {
    visibility: visible;
    transform: scaleX(1);
    color: white;
}

/* Navigation Menu */
ul {
    flex: 1;
    display: flex; /* Changed to flex for better alignment */
    justify-content: center; /* Center-aligns the menu items */
    align-items: center; /* Aligns items vertically */
    list-style: none;
    margin: -80px; /* Removes default margin */
    margin-left: 20px;
    padding: 0; /* Removes default padding */
}

ul li {
    position: relative; /* Necessary for dropdown positioning */
    margin: 0 20px; /* Adjusted margin for better spacing */
    font-size: 18px;
    cursor: pointer;
}

ul li a {
    text-decoration: none;
    color: #fff;
    padding: 10px 15px; /* Adds padding to anchor tags */
    display: block; /* Ensures the entire area is clickable */
}

ul li a:hover {
    color: #fff;
}

ul li p {
    margin-left:75px;
    margin-top: -20px;
    transition: all 0.3s ease;
}

.dropdown-menu ul li:hover a{
    color: #fff;
}

/* Dropdown Title Styles */
.dropdown-title {
    display: flex;
    justify-content: space-between; /* Space between text and icon */
    align-items: center; /* Vertically center the items */
    padding: 10px; /* Add some padding for better click area */
    cursor: pointer; /* Change cursor to pointer on hover */
    background-color: transparent; /* Change this to your desired background color */
    color: #fff; /* Set text color to white */
}

/* Dropdown Link Styles */
.dropdown-title a {
    color: inherit; /* Inherit the color from parent */
    text-decoration: none; /* Remove underline */
}

/* Icon Styles */
.icon {
    color: white; /* Set icon color to white */
    margin-left: -10px;
}

/* Dropdown Menu */
ul li .dropdown-menu {
    position: absolute;
    top: 100%;
    left: -35px;
    /* color: #234e82; */
    background-color: #333;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 1px;
    display: none; /* Hidden by default */
    z-index: 9999; /* Ensures the dropdown stays on top */
    flex-direction: column;
    border-radius: 0px 0px 40px 40px; /* This creates a semi-circle effect */
}

.dropdown-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 20px;
    color: #234e82;
    display: flex;
    flex-direction: column; /* Stack items vertically */
}

.dropdown-menu ul li {
    padding: 5px;
    width: 150px;
    color: #fff;
}

ul li:hover .dropdown-menu{
    display: flex;
    flex-direction: column; /* Shows dropdown on hover */
    align-items: flex-start; /* Align items to the start */
    opacity: 1;
    visibility: visible;
}

/* services dropdown menu */

.services-menu{
    position: absolute;
    top: 40px;
    background: #333;
    width: 35vw;
    height: 28vh;
    /* min-width: 28vw; */
    left: -160%;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    z-index: 9999;
    border-radius: 0px 0px 40px 40px; /* This creates a semi-circle effect */
}

/* Ensure the services menu stays visible when hovered */
ul li:hover .services-menu,
.services-menu:hover {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    opacity: 1;
    visibility: visible;
}

.services-menu .services-content{
    background: #333;
    padding: 10px 35px 10px 35px;
    display: flex;
    width: 15vw;
    justify-content: space-between;
}

.services-content .services-row{
    /* width: calc(25% - 30px);
    line-height: 45px; */
    flex: 1; /* Allow equal space distribution among rows */
    line-height: 45px;
}

/* .service-content .service-row img{
    width: 100%;
    height: 100%;
    object-fit: cover;
} */

.services-content .services-row header{   
    color: #fff;
    font-size: 22px;
    font-weight: 900;
    gap: 200px;
    /* padding-left: 10px; */
    margin-left: 10px;
    text-decoration: underline;
    /* padding: 0 0 0 75px; */
}
.services-content .services-row h5{   
    color: #fff;
    font-size: 22px;
    font-weight: 900;
    gap: 200px;
    margin-top: 1%;
    padding-left: 60px;
    /* margin-left: 50px; */
    text-decoration: underline;
    /* padding: 0 0 0 75px; */
}

.services-content .services-row .services-list{
    margin-left: -20%;
    padding-left: 80px;
    width: 100%;
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    /* border-left: 1px solid #234e82; */
}

.services-content .services-row:nth-child(1),
.services-content .services-row:nth-child(2){
    border-left: 0;
}

.services-row .services-list li{
    padding: 0 5px;
}
.services-row .services-list li a{
    padding: 0 0px 0 5px;
    color: #fff;
    font-size: 17px;
    line-height: 2.5rem;
    display: block;
}

.services-row .services-list li a:hover{
    color: #fff;
}

/* Hamburger Menu */
.hamburger-menu {
    display: none;
    cursor: pointer;
    z-index: 1100;
}

/* Call-to-Action Button */
.buttons {
    display: flex;
    align-items: center;
    background: #234e82;
    padding: 10px 20px;
    border-radius: 15px;
    margin-left: 85%;
    margin-top: -50px;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 150px;
}

.buttons .btn {
    padding: 6px;
    background: transparent;
    border: 0;
    outline: 0;
    color: #fff;
    font-size: 18px;
    width: 110px;
    cursor: pointer; /* Adds a pointer cursor */
}

@media (max-width: 1920px) {
    .navbar {
        width: 90%;
        height: 80px; /* Reduced height for a sleeker look */
        background-color: #333; /* White background */
        backdrop-filter: blur(10px);
        display: flex;
        justify-content: space-between; /* Space between logo and menu */
        align-items: center;
        padding: 0 3%; /* Adds spacing on left and right */
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
        position: absolute; /* Fixes navbar at the top */
        /* border-radius: 20%; */
        top: 20px;
        left: 70px;
        z-index: 10; /* Keeps navbar on top of all other content */
        border-radius: 40px 40px 40px 40px; /* This creates a semi-circle effect */
    }
    
    /* Active class for sticky header */
    /* .navbar.is-sticky {
        position: fixed;
        top: 0;
        width: 100%;
        background-color: rgba(51, 51, 51, 0.9); /* Semi-transparent for sticky 
        box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
        transition: top 0.3s ease-in-out, background-color 0.3s ease-in-out;
    } */
    
    /* Logo Styling */
    .logo {
        width: 60px;
        margin-top: 5px;
        cursor: pointer;
        z-index: -1;
    }
    
    .navbar li a:hover {
        color: #fff;
    }
    .navbar ul li a::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: rgb(255, 255, 255);
        visibility: hidden;
        transform: scaleX(0);
        transition: all 0.3s ease-in-out 0s;
    }
    .navbar ul li a:hover::before {
        visibility: visible;
        transform: scaleX(1);
        color: white;
    }
    
    /* Navigation Menu */
    ul {
        flex: 1;
        display: flex; /* Changed to flex for better alignment */
        justify-content: center; /* Center-aligns the menu items */
        align-items: center; /* Aligns items vertically */
        list-style: none;
        margin: -80px; /* Removes default margin */
        margin-left: 20px;
        padding: 0; /* Removes default padding */
    }
    
    ul li {
        position: relative; /* Necessary for dropdown positioning */
        margin: 0 20px; /* Adjusted margin for better spacing */
        font-size: 18px;
        cursor: pointer;
    }
    
    ul li a {
        text-decoration: none;
        color: #fff;
        padding: 10px 15px; /* Adds padding to anchor tags */
        display: block; /* Ensures the entire area is clickable */
    }
    
    ul li a:hover {
        color: #007bff;
    }
    
    .dropdown-menu ul li:hover a{
        color: #fff;
    }
    
    /* Dropdown Menu */
    ul li .dropdown-menu {
        position: absolute;
        top: 100%;
        left: -35px;
        /* color: #234e82; */
        background-color: #333;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        padding: 1px;
        display: none; /* Hidden by default */
        z-index: 9999; /* Ensures the dropdown stays on top */
        flex-direction: column;
        border-radius: 0px 0px 40px 40px; /* This creates a semi-circle effect */
    }
    
    .dropdown-menu ul {
        list-style: none;
        padding: 0;
        margin: 0;
        margin-bottom: 20px;
        color: #234e82;
        display: flex;
        flex-direction: column; /* Stack items vertically */
    }
    
    .dropdown-menu ul li {
        padding: 5px;
        width: 150px;
        color: #234e82;
    }
    
    ul li:hover .dropdown-menu{
        display: flex;
        flex-direction: column; /* Shows dropdown on hover */
        align-items: flex-start; /* Align items to the start */
        opacity: 1;
        visibility: visible;
    }
    
    /* services dropdown menu */
    
    .services-menu{
        position: absolute;
        top: 40px;
        background: #333;
        width: 16vw;
        height: 25vh;
        /* min-width: 10vw; */
        left: -80%;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease;
        z-index: 9999;
        border-radius: 0px 0px 40px 40px; /* This creates a semi-circle effect */
    }
    
    /* Ensure the services menu stays visible when hovered */
    ul li:hover .services-menu,
    .services-menu:hover {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        opacity: 1;
        visibility: visible;
    }
    
    .services-menu .services-content{
        background: #333;
        padding: 10px 15px 10px 10px;
        display: flex;
        width: 15vw;
        justify-content: space-between;
    }
    
    .services-content .services-row{
        /* width: calc(25% - 30px);
        line-height: 45px; */
        flex: 1; /* Allow equal space distribution among rows */
        line-height: 45px;
    }
    
    /* .service-content .service-row img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    } */
    
    .services-content .services-row header{   
        color: #fff;
        font-size: 22px;
        font-weight: 900;
        gap: 200px;
        /* padding-left: 10px; */
        /* margin-left: 10px; */
        text-decoration: underline;
        /* padding: 0 0 0 75px; */
    }
    .services-content .services-row h5{   
        color: #fff;
        font-size: 22px;
        font-weight: 900;
        gap: 200px;
        margin-top: 1%;
        /* padding-left: 60px; */
        /* margin-left: 50px; */
        text-decoration: underline;
        /* padding: 0 0 0 75px; */
    }
    
    .services-content .services-row .services-list{
        margin-left: -20%;
        padding-left: 80px;
        width: 100%;
        margin-top: 5px;
        display: flex;
        flex-direction: column;
        /* border-left: 1px solid #234e82; */
    }
    
    .services-content .services-row:nth-child(1),
    .services-content .services-row:nth-child(2){
        border-left: 0;
    }
    
    .services-row .services-list li{
        padding: 0 5px;
    }
    .services-row .services-list li a{
        padding: 0 0px 0 5px;
        color: #fff;
        font-size: 17px;
        line-height: 2.5rem;
        display: block;
    }
    
    .services-row .services-list li a:hover{
        color: #fff;
    }
    
    /* Hamburger Menu */
    .hamburger-menu {
        display: none;
        cursor: pointer;
        z-index: 1100;
    }
    
    /* Call-to-Action Button */
    .buttons {
        display: flex;
        align-items: center;
        background: #234e82;
        padding: 10px 20px;
        border-radius: 15px;
        margin-left: 85%;
        margin-top: -50px;
        cursor: pointer;
        transition: all 0.3s ease;
        width: 150px;
    }
    
    .buttons .btn {
        padding: 6px;
        background: transparent;
        border: 0;
        outline: 0;
        color: #fff;
        font-size: 18px;
        width: 110px;
        cursor: pointer; /* Adds a pointer cursor */
    }
    
}

@media(max-width: 1919px) and (min-width: 1536px) {
    .navbar {
        width: 90%;
        height: 80px; /* Reduced height for a sleeker look */
        background-color: #333; /* White background */
        backdrop-filter: blur(10px);
        display: flex;
        justify-content: space-between; /* Space between logo and menu */
        align-items: center;
        padding: 0 3%; /* Adds spacing on left and right */
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
        position: absolute; /* Fixes navbar at the top */
        /* border-radius: 20%; */
        top: 20px;
        left: 70px;
        z-index: 10; /* Keeps navbar on top of all other content */
        border-radius: 40px 40px 40px 40px; /* This creates a semi-circle effect */
    }
    
    /* Active class for sticky header */
    /* .navbar.is-sticky {
        position: fixed;
        top: 0;
        width: 100%;
        background-color: rgba(51, 51, 51, 0.9); /* Semi-transparent for sticky 
        box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
        transition: top 0.3s ease-in-out, background-color 0.3s ease-in-out;
    } */
    
    /* Logo Styling */
    .logo {
        width: 60px;
        margin-top: 5px;
        cursor: pointer;
        z-index: -1;
    }
    
    .navbar li a:hover {
        color: #fff;
    }
    .navbar ul li a::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: rgb(255, 255, 255);
        visibility: hidden;
        transform: scaleX(0);
        transition: all 0.3s ease-in-out 0s;
    }
    .navbar ul li a:hover::before {
        visibility: visible;
        transform: scaleX(1);
        color: white;
    }
    
    /* Navigation Menu */
    ul {
        flex: 1;
        display: flex; /* Changed to flex for better alignment */
        justify-content: center; /* Center-aligns the menu items */
        align-items: center; /* Aligns items vertically */
        list-style: none;
        margin: -80px; /* Removes default margin */
        margin-left: 20px;
        padding: 0; /* Removes default padding */
    }
    
    ul li {
        position: relative; /* Necessary for dropdown positioning */
        margin: 0 20px; /* Adjusted margin for better spacing */
        font-size: 18px;
        cursor: pointer;
    }
    
    ul li a {
        text-decoration: none;
        color: #fff;
        padding: 10px 15px; /* Adds padding to anchor tags */
        display: block; /* Ensures the entire area is clickable */
    }
    
    ul li a:hover {
        color: #007bff;
    }
    
    .dropdown-menu ul li:hover a{
        color: #fff;
    }
    
    /* Dropdown Menu */
    ul li .dropdown-menu {
        position: absolute;
        top: 100%;
        left: -35px;
        /* color: #234e82; */
        background-color: #333;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        padding: 1px;
        display: none; /* Hidden by default */
        z-index: 9999; /* Ensures the dropdown stays on top */
        flex-direction: column;
        border-radius: 0px 0px 40px 40px; /* This creates a semi-circle effect */
    }
    
    .dropdown-menu ul {
        list-style: none;
        padding: 0;
        margin: 0;
        margin-bottom: 20px;
        color: #234e82;
        display: flex;
        flex-direction: column; /* Stack items vertically */
    }
    
    .dropdown-menu ul li {
        padding: 5px;
        width: 150px;
        color: #234e82;
    }
    
    ul li:hover .dropdown-menu{
        display: flex;
        flex-direction: column; /* Shows dropdown on hover */
        align-items: flex-start; /* Align items to the start */
        opacity: 1;
        visibility: visible;
    }
    
    /* services dropdown menu */
    
    .services-menu{
        position: absolute;
        top: 60px;
        background: #333;
        width: 20vw;
        height: 32vh;
        /* min-width: 10vw; */
        left: -70%;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease;
        z-index: 9999;
        border-radius: 0px 0px 40px 40px; /* This creates a semi-circle effect */
    }
    
    /* Ensure the services menu stays visible when hovered */
    ul li:hover .services-menu,
    .services-menu:hover {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        opacity: 1;
        visibility: visible;
    }
    
    .services-menu .services-content{
        background: #333;
        padding: 10px 15px 10px 10px;
        display: flex;
        width: 15vw;
        justify-content: space-between;
    }
    
    .services-content .services-row{
        /* width: calc(25% - 30px);
        line-height: 45px; */
        flex: 1; /* Allow equal space distribution among rows */
        line-height: 45px;
    }
    
    /* .service-content .service-row img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    } */
    
    .services-content .services-row header{   
        color: #fff;
        font-size: 22px;
        font-weight: 900;
        gap: 200px;
        /* padding-left: 10px; */
        /* margin-left: 10px; */
        text-decoration: underline;
        /* padding: 0 0 0 75px; */
    }
    .services-content .services-row h5{   
        color: #fff;
        font-size: 22px;
        font-weight: 900;
        gap: 200px;
        margin-top: 1%;
        /* padding-left: 60px; */
        /* margin-left: 50px; */
        text-decoration: underline;
        /* padding: 0 0 0 75px; */
    }
    
    .services-content .services-row .services-list{
        margin-left: -20%;
        padding-left: 80px;
        width: 100%;
        margin-top: 5px;
        display: flex;
        flex-direction: column;
        /* border-left: 1px solid #234e82; */
    }
    
    .services-content .services-row:nth-child(1),
    .services-content .services-row:nth-child(2){
        border-left: 0;
    }
    
    .services-row .services-list li{
        padding: 0 5px;
    }
    .services-row .services-list li a{
        padding: 0 0px 0 5px;
        color: #fff;
        font-size: 17px;
        line-height: 2.5rem;
        display: block;
    }
    
    .services-row .services-list li a:hover{
        color: #fff;
    }
    
    /* Hamburger Menu */
    .hamburger-menu {
        display: none;
        cursor: pointer;
        z-index: 1100;
    }
    
    /* Call-to-Action Button */
    .buttons {
        display: flex;
        align-items: center;
        background: #234e82;
        padding: 10px 20px;
        border-radius: 15px;
        margin-left: 85%;
        margin-top: -50px;
        cursor: pointer;
        transition: all 0.3s ease;
        width: 150px;
    }
    
    .buttons .btn {
        padding: 6px;
        background: transparent;
        border: 0;
        outline: 0;
        color: #fff;
        font-size: 18px;
        width: 110px;
        cursor: pointer; /* Adds a pointer cursor */
    }
}

@media(max-width: 1535px) {
    .navbar {
        width: 90%;
        height: 80px; /* Reduced height for a sleeker look */
        background-color: #333; /* White background */
        /* backdrop-filter: blur(10px); */
        display: flex;
        justify-content: space-between; /* Space between logo and menu */
        align-items: center;
        padding: 0 3%; /* Adds spacing on left and right */
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
        position: absolute; /* Fixes navbar at the top */
        /* border-radius: 20%; */
        /* top: 20px; */
        left: 70px;
        z-index: 10; /* Keeps navbar on top of all other content */
        border-radius: 40px 40px 40px 40px; /* This creates a semi-circle effect */
    }
    
    /* Active class for sticky header */
    /* .navbar.is-sticky {
        position: fixed;
        top: 0;
        width: 100%;
        background-color: rgba(51, 51, 51, 0.9); /* Semi-transparent for sticky 
        box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
        transition: top 0.3s ease-in-out, background-color 0.3s ease-in-out;
    } */
    
    /* Logo Styling */
    .logo {
        width: 60px;
        margin-top: 5px;
        cursor: pointer;
        z-index: -1;
    }
    
    .navbar li a:hover {
        color: #fff;
    }
    .navbar ul li a::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: rgb(255, 255, 255);
        visibility: hidden;
        transform: scaleX(0);
        transition: all 0.3s ease-in-out 0s;
    }
    .navbar ul li a:hover::before {
        visibility: visible;
        transform: scaleX(1);
        color: white;
    }
    
    /* Navigation Menu */
    ul {
        flex: 1;
        display: flex; /* Changed to flex for better alignment */
        justify-content: center; /* Center-aligns the menu items */
        align-items: center; /* Aligns items vertically */
        list-style: none;
        margin: -80px; /* Removes default margin */
        margin-left: 20px;
        padding: 0; /* Removes default padding */
    }
    
    ul li {
        position: relative; /* Necessary for dropdown positioning */
        margin: 0 20px; /* Adjusted margin for better spacing */
        font-size: 18px;
        cursor: pointer;
    }
    
    ul li a {
        text-decoration: none;
        color: #fff;
        padding: 10px 15px; /* Adds padding to anchor tags */
        display: block; /* Ensures the entire area is clickable */
    }
    
    ul li a:hover {
        color: #fff;
    }
    
    .dropdown-menu ul li:hover a{
        color: #fff;
    }
    
    /* Dropdown Menu */
    ul li .dropdown-menu {
        position: absolute;
        top: 100%;
        left: -35px;
        /* color: #234e82; */
        background-color: #333;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        padding: 1px;
        display: none; /* Hidden by default */
        z-index: 9999; /* Ensures the dropdown stays on top */
        flex-direction: column;
        border-radius: 0px 0px 40px 40px; /* This creates a semi-circle effect */
    }
    
    .dropdown-menu ul {
        list-style: none;
        padding: 0;
        margin: 0;
        margin-bottom: 20px;
        color: #234e82;
        display: flex;
        flex-direction: column; /* Stack items vertically */
    }
    
    .dropdown-menu ul li {
        padding: 5px;
        width: 150px;
        color: #fff;
    }
    
    ul li:hover .dropdown-menu{
        display: flex;
        flex-direction: column; /* Shows dropdown on hover */
        align-items: flex-start; /* Align items to the start */
        opacity: 1;
        visibility: visible;
    }
    
    /* services dropdown menu */
    
    .services-menu{
        position: absolute;
        top: 60px;
        background: #333;
        width: 23vw;
        height: 39vh;
        /* min-width: 23vw; */
        left: -80%;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease;
        z-index: 9999;
        border-radius: 0px 0px 40px 40px; /* This creates a semi-circle effect */
    }
    
    /* Ensure the services menu stays visible when hovered */
    ul li:hover .services-menu,
    .services-menu:hover {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        opacity: 1;
        visibility: visible;
    }
    
    .services-menu .services-content{
        background: #333;
        padding: 10px 10px 10px 5px;
        display: flex;
        width: 15vw;
        justify-content: space-between;
    }
    
    .services-content .services-row{
        /* width: calc(25% - 30px);
        line-height: 45px; */
        flex: 1; /* Allow equal space distribution among rows */
        line-height: 45px;
    }
    
    /* .service-content .service-row img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    } */
    
    .services-content .services-row header{   
        color: #fff;
        font-size: 22px;
        font-weight: 900;
        gap: 200px;
        padding-left: -20px;
        /* margin-left: 10px; */
        text-decoration: underline;
        /* padding: 0 0 0 75px; */
    }
    .services-content .services-row h5{   
        color: #fff;
        font-size: 22px;
        font-weight: 900;
        gap: 200px;
        margin-top: 1%;
        padding-left: 50px;
        /* margin-left: 50px; */
        text-decoration: underline;
        /* padding: 0 0 0 75px; */
    }
    
    .services-content .services-row .services-list{
        margin-left: -20%;
        padding-left: 75px;
        width: 100%;
        margin-top: 5px;
        display: flex;
        flex-direction: column;
        /* border-left: 1px solid #234e82; */
    }
    
    .services-content .services-row:nth-child(1),
    .services-content .services-row:nth-child(2){
        border-left: 0;
    }
    
    .services-row .services-list li{
        padding: 0 5px;
    }
    .services-row .services-list li a{
        padding: 0 0px 0 5px;
        color: #fff;
        font-size: 17px;
        line-height: 2.5rem;
        display: block;
    }
    
    .services-row .services-list li a:hover{
        color: #fff;
    }
    
    /* Hamburger Menu */
    .hamburger-menu {
        display: none;
        cursor: pointer;
        z-index: 1100;
    }
    
    /* Call-to-Action Button */
    .buttons {
        display: flex;
        align-items: center;
        background: #234e82;
        padding: 10px 20px;
        border-radius: 15px;
        margin-left: 85%;
        margin-top: -50px;
        cursor: pointer;
        transition: all 0.3s ease;
        width: 150px;
    }
    
    .buttons .btn {
        padding: 6px;
        background: transparent;
        border: 0;
        outline: 0;
        color: #fff;
        font-size: 18px;
        width: 110px;
        cursor: pointer; /* Adds a pointer cursor */
    }
}

/* Responsive CSS for Tablet View (768px) */
@media (max-width: 768px) and (min-width: 430px) {
    /* Navbar Container */
    .navbar {
        height: 100px; /* Reduced height for tablet */
        padding: 0px 3%; /* Adjusted padding */
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
        margin-left: -12%;
        position: fixed;
    }

    /* Logo Styling */
    .logo {
        width: 60px; /* Smaller logo for tablet */
        margin-top: 10px;
    }

    /* Navigation Menu */
        /* Active class for sticky header */
    .navbar.is-sticky {
        position: fixed;
        top: 0;
        width: 90%;
        background-color: rgba(51, 51, 51, 0.9); /* Semi-transparent for sticky */
        box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
        transition: top 0.3s ease-in-out, background-color 0.3s ease-in-out;
    }


    ul {
        display: none; /* Hides navigation menu */
    }

    ul.open{
        display: block;
        background-color: #333;
        width: 40%;
        margin-left: 45%;
        margin-right: -2%;
        margin-top: 150px;
        border-radius: 40px 40px 40px 40px;
    }

    ul li {
        margin: 10px 0; /* Adds vertical spacing between items */
        font-size: 16px; /* Slightly smaller font size */
    }

        /* Dropdown Title Styles */
    .dropdown-title {
        display: flex;
        justify-content: space-between; /* Space between text and icon */
        align-items: center; /* Vertically center the items */
        padding: 10px; /* Add some padding for better click area */
        cursor: pointer; /* Change cursor to pointer on hover */
        background-color: transparent; /* Change this to your desired background color */
        color: #fff; /* Set text color to white */
    }

    /* Dropdown Link Styles */
    .dropdown-title a {
        color: inherit; /* Inherit the color from parent */
        text-decoration: none; /* Remove underline */
    }

    /* Icon Styles */
    .icon {
        color: white; /* Set icon color to white */
        margin-left: -9%;
    }

    /* Dropdown Menu */
    ul li .dropdown-menu {
        top: 100%; /* Keeps dropdown below menu item */
        left: 0; /* Aligns dropdown with parent item */
        width: 100%; /* Full width dropdown */
    }

    /* Services dropdown menu  */

    .services-menu {
        top: 30px;
        width: 38vw; /* Adjust the width for better fit on tablets */
        height: 70vh; /* Allow the height to adjust to content */
        min-width: 100%; /* Expand to full width on smaller screens */
        left: 0; /* Center the menu on smaller screens */
        border-radius: 0 40px 40px 40px; /* Remove the rounded corners */
    }

    .services-content {
        flex-direction: column; /* Stack the content vertically */
        padding: 10px 15px; /* Reduce padding for better fit */
        width: 30%; /* Ensure the content takes full width */
    }

    .services-content .services-row {
        flex: none; /* Remove flex grow to allow for column stacking */
        width: 50%; /* Full width for each row */
        margin-bottom: 0px; /* Add spacing between rows */
    }

    .services-content .services-row header {
        font-size: 19px; /* Slightly larger header */
        margin-left: -20px; /* Adjust margin */
    }

    .services-content .services-row h5 {
        font-size: 19px; /* Slightly larger header */
        margin-left: -70px; /* Adjust margin */
        margin-top: 10vh; /*Adjust margin */
    }

    .services-content .services-row .services-list {
        margin-left: 0; /* Adjust the list margin */
        padding-left: 15px; /* Decrease padding for better alignment */
        width: 100%; /* Full width for the list */
    }

    .services-row .services-list li a {
        font-size: 15px; /* Reduce font size */
        line-height: 2rem; /* Adjust line height */
    }

    /* Call-to-Action Button */
    .buttons {
       display: none;
    }
    /* Hamburger Menu */
    .hamburger-menu {
        display: block; /* Display hamburger menu */
        position: absolute; /* Absolute positioning */
        right: 20px; /* Align to the right */
        top: 30px; /* Align to the top */
        color: #fff;
    }
    .sticky-header {
        display: none;
    }
}

/* Responsive Styles for mobile view 426px to 429px */
@media screen and (max-width: 429px) and (min-width: 426px) {
    
    /* Navbar Container */
    .navbar {
        width: 100%; /* Full width for mobile */
        height: 90px; /* Slightly increased height */
        left: 0; /* Align navbar to the left */
        top: 0; /* Stick it to the top */
        border-radius: 0; /* Remove border-radius */
        position: fixed;
    }

    /* Logo Styling */
    .logo {
        width: 50px; /* Slightly larger logo size */
    }

    /* Navigation Menu */
    ul {
        flex-direction: column; /* Stack menu items vertically */
        padding: 15px 0; /* More padding */
    }

    ul.open {
        display: block;
        background-color: #444; /* Slightly lighter background */
        width: 40vw;
        margin-left: 45%;
        margin-right: -2%;
        margin-top: 135px;
        border-radius: 20px; /* Adjust border-radius */
    }

    ul li {
        margin: 5px 0; /* Add margin between menu items */
        font-size: 17px; /* Slightly larger font for mobile */
    }

        /* Dropdown Title Styles */
    .dropdown-title {
        display: flex;
        justify-content: space-between; /* Space between text and icon */
        align-items: center; /* Vertically center the items */
        padding: 10px; /* Add some padding for better click area */
        cursor: pointer; /* Change cursor to pointer on hover */
        background-color: transparent; /* Change this to your desired background color */
        color: #fff; /* Set text color to white */
    }

    /* Dropdown Link Styles */
    .dropdown-title a {
        color: inherit; /* Inherit the color from parent */
        text-decoration: none; /* Remove underline */
    }

    /* Icon Styles */
    .icon {
        color: white; /* Set icon color to white */
        margin-left: -90%;
    }

    /* Dropdown Menu */
    ul li .dropdown-menu {
        width: 42vw; /* Slightly wider for better visibility */
        left: 0; /* Align dropdown to the left */
        border-radius: 0 0 15px 15px; /* Maintain border-radius */
    }

    .dropdown-menu ul li {
        width: 100%; /* Full width for items */
        font-size: 16px; /* Slightly larger font size */
    }

    /* Services Menu */
    .services-menu {
        width: 50vw; /* Slightly wider for better visibility */
        height: auto; /* Adjust height */
        left: 0; /* Align to the left */
        top: 80%;
        padding: 20px; /* More padding */
        border-radius: 0 0 15px 15px; /* Maintain border-radius */
    }

    .services-content {
        flex-direction: column; /* Stack services vertically */
        align-items: flex-start; /* Align to start */
    }

    .services-content .services-row header {
        font-size: 19px; /* Slightly larger header */
        margin-left: -40px; /* Adjust margin */
    }

    .services-content .services-row h5 {
        font-size: 19px; /* Slightly larger header */
        margin-left: -90px; /* Adjust margin */
        margin-top: 120%; /* Adjust margin */
    }

    .services-content .services-list {
        padding-left: 0; /* Adjust padding */
        margin-left: 20px; /* Align content properly */
    }

    .services-row .services-list li {
        padding: 6px 0; /* More padding between items */
    }

    .services-row .services-list li a {
        font-size: 15px; /* Larger font size */
        margin-left: -80px; /* Adjust margin */
    }

    .services-menu .services-content .services-row .services-list li a:hover {
        color: #fff; /* Hover effect */
    }

    .services-menu .services-content .services-row .services-list li a::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 150%;
        height: 2px;
        background-color: rgb(255, 255, 255);
        visibility: hidden;
        transform: scaleX(0);
        transition: all 0.3s ease-in-out 0s;
    }
    
    .services-menu .services-content .services-row .services-list li a:hover::before {
        visibility: visible;
        transform: scaleX(500);
        color: white;
    }

    /* Call-to-Action Button */
    .buttons {
        margin-left: auto; /* Center the button */
        margin-top: 15px; /* Adjust margin */
        width: 130px; /* Slightly wider button */
    }

    .buttons .btn {
        font-size: 15px; /* Slightly larger font for mobile */
        width: 100%; /* Full width of button */
    }

    /* Hamburger Menu */
    .hamburger-menu {
        display: block; /* Show hamburger on mobile */
        position: absolute;
        top: 20px; /* Adjust position */
        right: 20px; /* Adjust position */
        font-size: 26px; /* Increase size for better visibility */
        color: #fff;
    }

    /* Hide navigation menu initially on mobile */
    ul {
        display: none; /* Hide menu on mobile */
    }

    /* Show menu when hamburger is clicked */
    .navbar.active ul {
        display: flex; /* Show menu in column format */
    }
    .sticky-header {
        display: none;
    }
}



/* Responsive Styles for mobile view 320px to 425px */
@media screen and (max-width: 425px) and (min-width: 320px) {
    
    /* Navbar Container */
    .navbar {
        width: 100%; /* Full width for mobile */
        height: 60px; /* Reduce height for mobile */
        /* padding: 10 0px 0 -20px; Less padding for mobile */
        left: 0px; /* Align navbar to the left */
        top: 0; /* Stick it to the top */
        border-radius: 0; /* Remove border-radius for mobile */
        position: fixed;
    }

    /* Logo Styling */
    .logo {
        width: 40px; /* Smaller logo size */
    }

    /* Navigation Menu */
    ul {
        flex-direction: column; /* Stack menu items vertically */
        justify-content: flex-start;
        /* margin: 0; Remove margin for mobile */
        padding: 10px 0; /* Add padding */
    }

    ul.open{
        display: block;
        background-color: #333;
        width: 45vw;
        margin-left: 45%;
        margin-right: -2%;
        margin-top: 150px;
        border-radius: 40px 40px 40px 40px;
    }

    ul li {
        margin: 0px 0; /* Add margin between menu items */
        font-size: 16px; /* Smaller font for mobile */
    }

    /* Dropdown Menu */
    ul li .dropdown-menu {
        width: 45vw; /* Full width of the screen */
        left: 0; /* Align dropdown to the left */
        border-radius: 0 0 15px 15px; /* Reduce border-radius */
    }

    .dropdown-menu ul li {
        width: 100%; /* Full width for items */
        font-size: 15px; /* Reduce font size */
    }

    /* Services Menu */
    .services-menu {
        /* width: 100vw; Full screen width */
        width: 45vw;
        height: 70vh; /* Adjust height */
        left: 0; /* Align to the left */
        top: 80%;
        padding: 15px; /* Add padding */
        border-radius: 0 0 15px 15px; /* Adjust for mobile */
    }

    .services-content {
        flex-direction: column; /* Stack services vertically */
        align-items: flex-start; /* Align to start */
        /* margin-top: 80%; */
    }

    .services-content .services-row header {
        font-size: 18px; /* Slightly smaller header */
        margin-left: -20px; /* Remove extra margin */
        margin-top: -30%;
    }
    .services-content .services-row h5 {
        font-size: 18px; /* Slightly smaller header */
        margin-left: -75px; /* Remove extra margin */
        margin-top: 165%;
    }

    .services-content .services-list {
        padding-left: 0; /* Adjust padding */
        margin-left: 30px; /* Align content properly */
    }

    .services-row .services-list li {
        padding: 5px 0; /* More padding between items */
    }

    .services-row .services-list li a {
        font-size: 14px; /* Smaller font size */
        margin-left: -100px;
    }

    .services-menu .services-content .services-row .services-list li a:hover {
        color: #fff;
    }
    .services-menu .services-content .services-row .services-list li a::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 150%;
        height: 2px;
        background-color: rgb(255, 255, 255);
        visibility: hidden;
        transform: scaleX(0);
        transition: all 0.3s ease-in-out 0s;
    }
    .services-menu .services-content .services-row .services-list li a:hover::before {
        visibility: visible;
        transform: scaleX(500);
        color: white;
    }

    /* Call-to-Action Button */
    .buttons {
        margin-left: auto; /* Center the button */
        margin-top: 10px; /* Adjust margin */
        width: 120px; /* Smaller width */
    }

    .buttons .btn {
        font-size: 14px; /* Smaller font for mobile */
        width: 100%; /* Full width of button */
    }

    /* Hamburger Menu */
    .hamburger-menu {
        display: block; /* Show hamburger on mobile */
        position: absolute;
        top: 15px;
        right: 15px;
        font-size: 24px; /* Increase size for better visibility */
        color: #fff;
    }

    /* Hide navigation menu initially on mobile */
    ul {
        display: none; /* Hide menu on mobile */
    }

    /* Show menu when hamburger is clicked */
    .navbar.active ul {
        display: flex; /* Show menu in column format */
    }

    .sticky-header {
        display: none;
    }
}



