/* Agriculture service page content */

.main_content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin-top: 5%;
}

/* main heading */

.main_content h2 {
    font-size: 3.5rem;
    text-align: center;
    align-items: center;
    padding: 10px;
    color: #2348E2;
}

/* para content */

.sub_content1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.sub_content1 p{
    font-size: 22px;
    text-align: justify;
    align-items: center;
    padding: 10px;
    /* color: #2348E2; */
    width: 70vw;
    margin-left: 75px;
    padding: 20px;
}

/* main image content below the heading */

.sub_content1 .img1 {
    width: 70vw;
    height: 85vh;
    margin-left: 75px;
}

/* benefits content */

.sub_content2 {
    position: relative;
    width: 80%;
    margin: 0 auto;
    padding-top: 10px;
    margin-top: 10px;
}

/* benefits images */

.sub_content2 .img2 {
    float: right;
    width: 30vw;
    height: 45vh;
    margin-right: 40px;
}

/* benefits heading */

.sub_content2 h5 {
    font-size: 35px;
    text-align: justify;
    align-items: center;
    padding: 10px;
    color: #2348E2;
    width: 50vw;
    margin-left: 85px;
    padding: 20px;
}

.sub_content2 .benefits {
    font-size: 20px;
    text-align: justify;
    align-items: center;
    /* color: #2348E2; */
    width: 50vw;
    margin-left: 85px;
}

.sub_content2 .benefits p {
    position: relative; /* For positioning the pseudo-element */
    padding-left: 55px; /* Space for the bullet */
    margin: 5px 0; /* Add margin between paragraphs */
    /* color: #2348E2; */
    text-align: justify;
    width: 35vw;
    font-size: 20px;
}

.sub_content2 .benefits p::before {
    content: '•'; /* Bullet character */
    position: absolute;
    left: 0;
    /* color: #2348E2;  */
    font-size: 24px; /* Bullet size */
    line-height: 1; /* Adjust bullet alignment */
}

/* applications content */

.sub_content3 {
    position: relative;
    width: 80%;
    /* margin: 0 auto;
    padding-top: 10px;
    margin-top: 10px; */
}

/* application heading */

.sub_content3 h5 {
    font-size: 35px;
    text-align: justify;
    align-items: center;
    padding: 10px;
    color: #2348E2;
    /* width: 50vw; */
    float: right;
    /* margin-left: 25px; */
    /* padding: 20px; */
}

.sub_content3 .applications {
    font-size: 20px;
    text-align: justify;
    align-items: center;
    /* color: #2348E2; */
    width: 30vw;
    float: right;
    margin-left: 60%;
}

.sub_content3 .applications p {
    position: relative; /* For positioning the pseudo-element */ /* Space for the bullet */
    /* color: #2348E2; */
    width: 30vw;
    float: right;
    font-size: 20px;
    line-height: 1.5;
    /* margin-left: 120%; */
    text-align: justify;
    /* padding: 10px; */
    padding-left: 75px;
}

.sub_content3 .applications p::before {
    content: '•'; /* Bullet character */
    position: absolute;
    left: 0;
    /* color: #2348E2;  */
    font-size: 24px; /* Bullet size */
    line-height: 1.5; /* Adjust bullet alignment */
    padding-left: 30px;
}

/* applications image */

.sub_content3 .img3 {
    float: left;
    width: 35vw;
    margin-top: -20%;
    margin-left: 10%;
    /* margin-right: 20px; */
}

/* Agriculture down image */

.sub_content4 .img4{
    width: 50vw;
    margin-top: 50px;
    margin-left: 80px;
}

@media screen and (max-width: 1920px) {
    /* Agriculture service page content */

    .main_content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
        margin-top: 5%;
    }

    /* main heading */

    .main_content h2 {
        font-size: 3.5rem;
        text-align: center;
        align-items: center;
        padding: 10px;
        color: #2348E2;
    }

    /* para content */

    .sub_content1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px;
    }

    .sub_content1 p{
        font-size: 22px;
        text-align: justify;
        align-items: center;
        padding: 10px;
        /* color: #2348E2; */
        width: 70vw;
        margin-left: 75px;
        padding: 20px;
    }

    /* main image content below the heading */

    .sub_content1 .img1 {
        width: 70vw;
        height: 85vh;
        margin-left: 75px;
    }

    /* benefits content */

    .sub_content2 {
        position: relative;
        width: 80%;
        margin: 0 auto;
        padding-top: 10px;
        margin-top: 10px;
    }

    /* benefits images */

    .sub_content2 .img2 {
        float: none;
        width: 20vw;
        height: 30vh;
        margin-left: 60%;
        /* margin-right: 40px; */
    }

    /* benefits heading */

    .sub_content2 h5 {
        font-size: 35px;
        text-align: justify;
        align-items: center;
        padding: 10px;
        color: #2348E2;
        width: 50vw;
        margin-left: 25%;
        padding: 20px;
    }

    .sub_content2 .benefits {
        font-size: 20px;
        text-align: justify;
        align-items: center;
        /* color: #2348E2; */
        width: 30vw;
        margin-left: 25%;
        margin-top: -20%;
    }

    .sub_content2 .benefits p {
        position: relative; /* For positioning the pseudo-element */
        padding-left: 55px; /* Space for the bullet */
        margin: 5px 0; /* Add margin between paragraphs */
        /* color: #2348E2; */
        text-align: justify;
        width: 25vw;
        font-size: 20px;
    }

    .sub_content2 .benefits p::before {
        content: '•'; /* Bullet character */
        position: absolute;
        left: 0;
        /* color: #2348E2;  */
        font-size: 24px; /* Bullet size */
        line-height: 1; /* Adjust bullet alignment */
    }

    /* applications content */

    .sub_content3 {
        position: relative;
        width: 80%;
        /* margin: 0 auto;
        padding-top: 10px;
        margin-top: 10px; */
    }

    /* application heading */

    .sub_content3 h5 {
        font-size: 35px;
        text-align: justify;
        align-items: center;
        padding: 10px;
        color: #2348E2;
        /* width: 50vw; */
        float: none;
        margin-left: 60%;
        /* padding: 20px; */
    }

    .sub_content3 .applications {
        font-size: 20px;
        text-align: justify;
        align-items: center;
        /* color: #2348E2; */
        width: 30vw;
        float: none;
        margin-left: 60%;
        margin-top: 0%;
    }

    .sub_content3 .applications p {
        position: relative; /* For positioning the pseudo-element */ /* Space for the bullet */
        /* color: #2348E2; */
        width: 25vw;
        float: none;
        font-size: 20px;
        line-height: 1.5;
        /* margin-left: 120%; */
        text-align: justify;
        /* padding: 10px; */
        padding-left: 75px;
    }

    .sub_content3 .applications p::before {
        content: '•'; /* Bullet character */
        position: absolute;
        left: 0;
        /* color: #2348E2;  */
        font-size: 24px; /* Bullet size */
        line-height: 1.5; /* Adjust bullet alignment */
        padding-left: 30px;
    }

    /* applications image */

    .sub_content3 .img3 {
        float: left;
        width: 20vw;
        margin-top: -20%;
        margin-left: 30%;
        /* margin-right: 20px; */
    }

    /* Agriculture down image */

    .sub_content4 .img4{
        width: 50vw;
        margin-top: 50px;
        margin-left: 80px;
    }
}

@media screen and (max-width: 1536px) {
    /* Agriculture service page content */

    .main_content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
        margin-top: 5%;
    }

    /* main heading */

    .main_content h2 {
        font-size: 3.5rem;
        text-align: center;
        align-items: center;
        padding: 10px;
        color: #2348E2;
    }

    /* para content */

    .sub_content1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px;
    }

    .sub_content1 p{
        font-size: 22px;
        text-align: justify;
        align-items: center;
        padding: 10px;
        /* color: #2348E2; */
        width: 70vw;
        margin-left: 75px;
        padding: 20px;
    }

    /* main image content below the heading */

    .sub_content1 .img1 {
        width: 70vw;
        height: 85vh;
        margin-left: 75px;
    }

    /* benefits content */

    .sub_content2 {
        position: relative;
        width: 80%;
        margin: 0 auto;
        padding-top: 10px;
        margin-top: 10px;
    }

    /* benefits images */

    .sub_content2 .img2 {
        /* float: right; */
        width: 25vw;
        height: 45vh;
        margin-left: 55%;
        /* margin-right: 40px; */
    }

    /* benefits heading */

    .sub_content2 h5 {
        font-size: 35px;
        text-align: justify;
        align-items: center;
        padding: 10px;
        color: #2348E2;
        width: 50vw;
        margin-left: 85px;
        padding: 20px;
    }

    .sub_content2 .benefits {
        font-size: 20px;
        text-align: justify;
        align-items: center;
        /* color: #2348E2; */
        width: 30vw;
        margin-left: 85px;
        margin-top: -28%;
    }

    .sub_content2 .benefits p {
        position: relative; /* For positioning the pseudo-element */
        padding-left: 55px; /* Space for the bullet */
        margin: 5px 0; /* Add margin between paragraphs */
        /* color: #2348E2; */
        text-align: justify;
        width: 30vw;
        font-size: 20px;
    }

    .sub_content2 .benefits p::before {
        content: '•'; /* Bullet character */
        position: absolute;
        left: 0;
        /* color: #2348E2;  */
        font-size: 24px; /* Bullet size */
        line-height: 1; /* Adjust bullet alignment */
    }

    /* applications content */

    .sub_content3 {
        position: relative;
        width: 80%;
        /* margin: 0 auto;
        padding-top: 10px;
        margin-top: 10px; */
    }

    /* application heading */

    .sub_content3 h5 {
        font-size: 35px;
        text-align: justify;
        align-items: center;
        padding: 10px;
        color: #2348E2;
        /* width: 50vw; */
        float: none;
        margin-left: 53%;
        /* padding: 20px; */
    }

    .sub_content3 .applications {
        font-size: 20px;
        text-align: justify;
        align-items: center;
        /* color: #2348E2; */
        width: 30vw;
        float: none;
        margin-left: 50%;
    }

    .sub_content3 .applications p {
        position: relative; /* For positioning the pseudo-element */ /* Space for the bullet */
        /* color: #2348E2; */
        width: 30vw;
        float: none;
        font-size: 20px;
        line-height: 1.5;
        /* margin-left: 120%; */
        text-align: justify;
        /* padding: 10px; */
        padding-left: 75px;
    }

    .sub_content3 .applications p::before {
        content: '•'; /* Bullet character */
        position: absolute;
        left: 0;
        /* color: #2348E2;  */
        font-size: 24px; /* Bullet size */
        line-height: 1.5; /* Adjust bullet alignment */
        padding-left: 30px;
    }

    /* applications image */

    .sub_content3 .img3 {
        float: left;
        width: 30vw;
        margin-top: -25%;
        margin-left: 10%;
        /* margin-right: 20px; */
    }

    /* Agriculture down image */

    .sub_content4 .img4{
        width: 50vw;
        margin-top: 50px;
        margin-left: 80px;
    }
    
}

@media  screen and (max-width: 1263px) {
    /* Agriculture service page content */

    .main_content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
        margin-top: 5%;
    }

    /* main heading */

    .main_content h2 {
        font-size: 3.5rem;
        text-align: center;
        align-items: center;
        padding: 10px;
        color: #2348E2;
    }

    /* para content */

    .sub_content1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px;
    }

    .sub_content1 p{
        font-size: 22px;
        text-align: justify;
        align-items: center;
        padding: 10px;
        /* color: #2348E2; */
        width: 70vw;
        margin-left: 75px;
        padding: 20px;
    }

    /* main image content below the heading */

    .sub_content1 .img1 {
        width: 70vw;
        height: 85vh;
        margin-left: 75px;
    }

    /* benefits content */

    .sub_content2 {
        position: relative;
        width: 80%;
        margin: 0 auto;
        padding-top: 10px;
        margin-top: 10px;
    }

    /* benefits images */

    .sub_content2 .img2 {
        /* float: right; */
        width: 30vw;
        height: 45vh;
        /* margin-top: 20%; */
        margin-right: 40px;
    }

    /* benefits heading */

    .sub_content2 h5 {
        font-size: 35px;
        text-align: justify;
        align-items: center;
        padding: 10px;
        color: #2348E2;
        width: 50vw;
        margin-left: 85px;
        padding: 20px;
    }

    .sub_content2 .benefits {
        font-size: 20px;
        text-align: justify;
        align-items: center;
        /* color: #2348E2; */
        width: 50vw;
        margin-left: 85px;
    }

    .sub_content2 .benefits p {
        position: relative; /* For positioning the pseudo-element */
        padding-left: 55px; /* Space for the bullet */
        margin: 5px 0; /* Add margin between paragraphs */
        /* color: #2348E2; */
        text-align: justify;
        width: 35vw;
        font-size: 20px;
    }

    .sub_content2 .benefits p::before {
        content: '•'; /* Bullet character */
        position: absolute;
        left: 0;
        /* color: #2348E2;  */
        font-size: 24px; /* Bullet size */
        line-height: 1; /* Adjust bullet alignment */
    }

    /* applications content */

    .sub_content3 {
        position: relative;
        width: 80%;
        /* margin: 0 auto;
        padding-top: 10px;
        margin-top: 10px; */
    }

    /* application heading */

    .sub_content3 h5 {
        font-size: 35px;
        text-align: justify;
        align-items: center;
        padding: 10px;
        color: #2348E2;
        /* width: 50vw; */
        float: right;
        /* margin-left: 25px; */
        /* padding: 20px; */
    }

    .sub_content3 .applications {
        font-size: 20px;
        text-align: justify;
        align-items: center;
        /* color: #2348E2; */
        width: 30vw;
        float: right;
        margin-left: 60%;
    }

    .sub_content3 .applications p {
        position: relative; /* For positioning the pseudo-element */ /* Space for the bullet */
        /* color: #2348E2; */
        width: 30vw;
        float: right;
        font-size: 20px;
        line-height: 1.5;
        /* margin-left: 120%; */
        text-align: justify;
        /* padding: 10px; */
        padding-left: 75px;
    }

    .sub_content3 .applications p::before {
        content: '•'; /* Bullet character */
        position: absolute;
        left: 0;
        /* color: #2348E2;  */
        font-size: 24px; /* Bullet size */
        line-height: 1.5; /* Adjust bullet alignment */
        padding-left: 30px;
    }

    /* applications image */

    .sub_content3 .img3 {
        float: left;
        width: 35vw;
        margin-top: -50%;
        margin-left: 10%;
        /* margin-right: 20px; */
    }

    /* Agriculture down image */

    .sub_content4 .img4{
        width: 50vw;
        margin-top: 50px;
        margin-left: 80px;
    }
    
}

/* Responsive for tablet view(768px) */

@media screen and (max-width: 768px) {
    /* Responsive Styles for Tablet View (max-width: 768px) */

    /* Main content */
    .main_content {
        padding: 10px;
        margin-top: 20%;
    }

    /* Main heading */
    .main_content h2 {
        font-size: 2.5rem;
        padding: 5px;
        color: #2348E2;
    }

    /* Paragraph content */
    .sub_content1 p {
        font-size: 18px;
        width: 90vw;
        margin-left: 0;
        padding: 15px;
    }

    /* Main image */
    .sub_content1 .img1 {
        width: 90vw;
        height: auto;
        margin-left: 0;
    }

    /* Benefits content */
    .sub_content2 {
        width: 80vw;
        margin: 0 auto;
        padding-top: 5px;
        margin-top: 10px;
    }

    /* Benefits images */
    .sub_content2 .img2 {
        float: none;
        width: 80vw;
        height: auto;
        margin-right: 0;
        margin-bottom: 20px;
        margin-left: 3%;
    }

    /* Benefits heading */
    .sub_content2 h5 {
        font-size: 28px;
        width: 90vw;
        margin-left: 0;
        padding: 15px;
    }

    /* Benefits text */
    .sub_content2 .benefits {
        font-size: 18px;
        width: 80vw;
        margin-left: 0;
        margin-top: 7%;
    }

    /* Benefits bullet points */
    .sub_content2 .benefits p {
        padding-left: 35px;
        margin: 5px 0;
        font-size: 18px;
        width: 80vw;
    }

    /* Applications content */
    .sub_content3 {
        width: 90%;
        margin: 0 auto;
    }

    /* Application heading */
    .sub_content3 h5 {
        font-size: 28px;
        float: none;
        width: 90vw;
        margin-left: 0;
        padding: 15px;
    }

    /* Applications text */
    .sub_content3 .applications {
        font-size: 18px;
        width: 80vw;
        /* margin-left: 90px; */
        margin-left: -5%;
        float: none;
    }

    /* Applications bullet points */
    .sub_content3 .applications p {
        margin: 5px 0;
        font-size: 18px;
        width: 80vw;

    }

    .sub_content3 .applications p::before {
        left: -5px;
        font-size: 20px;
    }

    /* Applications image */
    .sub_content3 .img3 {
        float: none;
        width: 90vw;
        margin-top: 20px;
        margin-left: 0;
    }

    /* Agriculture down image */
    .sub_content4 .img4 {
        width: 90vw;
        margin-top: 30px;
        margin-left: 0;
    }
}

@media only screen and (max-width: 425px) {
    /* Main content */
    .main_content {
        padding: 10px;
    }

    /* Main heading */
    .main_content h2 {
        font-size: 2.5rem; /* Smaller font size for mobile */
        padding: 5px;
    }

    /* Paragraph content */
    .sub_content1 p {
        font-size: 18px; /* Adjust font size */
        width: 90vw; /* Adjust width to fit screen */
        margin: 0; /* Remove left margin */
        padding: 10px;
    }

    /* Main image content below the heading */
    .sub_content1 .img1 {
        width: 90vw; /* Adjust width */
        height: auto; /* Maintain aspect ratio */
        margin: 0; /* Remove left margin */
    }

    /* Benefits content */
    .sub_content2 {
        width: 90%;
        margin: 0 auto;
    }

    /* Benefits images */
    .sub_content2 .img2 {
        width: 80vw; /* Adjust width */
        height: auto; /* Maintain aspect ratio */
        margin-right: 0;
        float: none; /* Remove float */
    }

    /* Benefits heading */
    .sub_content2 h5 {
        font-size: 20px; /* Adjust font size */
        width: 90vw; /* Adjust width */
        margin: 0; /* Remove left margin */
        /* padding: 10px; */
    }

    .sub_content2 .benefits {
        font-size: 18px; /* Adjust font size */
        width: 90vw; /* Adjust width */
        margin: 0; /* Remove left margin */
    }

    .sub_content2 .benefits p {
        width: 90vw; /* Adjust width */
        padding-left: 30px; /* Adjust padding */
        margin: 5px 0; /* Adjust margin */
    }

    /* Applications content */
    .sub_content3 {
        width: 90%;
        margin: 0 auto;
    }

    /* Application heading */
    .sub_content3 h5 {
        font-size: 20px; /* Adjust font size */
        float: none; /* Remove float */
        width: 90vw; /* Adjust width */
        margin: 0; /* Remove left margin */
        padding: 10px;
    }

    .sub_content3 .applications {
        font-size: 18px; /* Adjust font size */
        width: 80vw; /* Adjust width */
        float: none; /* Remove float */
        margin: 0; /* Remove left margin */
    }

    .sub_content3 .applications p {
        width: 90vw; /* Adjust width */
        padding-left: -80px; /* Adjust padding */
        margin: 5px 0; /* Adjust margin */
    }

    .sub_content3 .applications p::before {
        left: -5px; /* Adjust left position */
        font-size: 20px; /* Adjust font size */
    }

    /* Applications image */
    .sub_content3 .img3 {
        width: 80vw; /* Adjust width */
        height: auto; /* Maintain aspect ratio */
        margin: 0; /* Remove margins */
        float: none; /* Remove float */
    }

    /* Agriculture down image */
    .sub_content4 .img4 {
        width: 80vw; /* Adjust width */
        margin: 20px auto; /* Center image */
        display: block; /* Center image horizontally */
    }
}

