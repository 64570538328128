
/* embedded software engineer page */

.job-description-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin-left: 5%;
  margin-top: 6%;
}

/* embedded software engineer description */

.job-details {
  width: 60%;
  margin-right: 40px;
  text-align: justify;
}

/* embedded software engineer heading */

.job-details h2 {
  font-size: 30px;
  margin-bottom: 20px;
  color: #23458e;
  text-align: left;
}

/* embedded software engineer responsibilities description and required skills */

.job-details h3 {
  font-size: 24px;
  margin-bottom: 20px;
}

/* embedded software engineer description content */

.job-details .job-description p{
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 10px;
}

/* embedded software engineer description bullets */

.job-details .job-description p:before{
  content: '• ';
  margin-right: 15px;
  font-size: 18px;
}

/* embedded software engineer additional */

.job-description-info p {
  display: block;
  /* padding: 10px; */
  justify-content: space-between;
  margin-top: 20px;
}

/* embedded software engineer apply form */

.apply-form {
  width: 35%;
  height: 45%;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-right: 5%;
}

/* embedded software engineer apply form heading */

.apply-form h3 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  color: #23458e;
}

/* embedded software engineer apply form box */

.apply-form form {
  display: flex;
  flex-direction: column;
}

/* embedded software engineer apply form labels */

.apply-form label {
  margin-bottom: 5px;
  font-weight: bold;
}

/* embedded software engineer apply form input and textarea */

.apply-form input,
.apply-form textarea {
  margin-bottom: 15px;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

/* embedded software engineer apply form button */

.apply-form button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

/* embedded software engineer apply form button hover */

.apply-form button:hover {
  background-color: #0056b3;
}

/* Responsive Styles for Laptop View (max-width: 1268px) */
@media (max-width: 1263px) {
  .job-description-container {
    flex-direction: row;
    align-items: center;
    /* margin-left: -35%; */
    margin-top: -2%;
    /* margin-bottom:50% ; */
  }

  .job-details {
    width: 55%; /* Full width on smaller screens */
    margin-right: 0;
  }

  .apply-form {
    width: 40%; /* Adjust width for smaller screens */
    height: auto; /* Allow height to adjust based on content */
    margin-right: 0;
    margin-top: -40%;
    /* margin-left: 70%; */
  }

  .job-details h2 {
    font-size: 28px; /* Slightly smaller heading */
  }

  .job-details h3 {
    font-size: 22px; /* Slightly smaller subheading */
  }

  .job-details .job-description p {
    font-size: 15px; /* Adjust paragraph size */
  }

  .apply-form h3 {
    font-size: 22px; /* Adjust heading size in form */
  }

  .apply-form input,
  .apply-form textarea {
    font-size: 14px; /* Maintain font size for inputs */
  }

  .apply-form button {
    font-size: 15px; /* Adjust button font size */
  }
}


/* Responsive styles for tablet view (768px) */
@media (max-width: 768px) {

  /* embedded software engineer page */
  .job-description-container {
    flex-direction: column;
    margin-left: 0;
    margin-top: 18%;
    padding: 10px;
  }

  /* embedded software engineer description */

  .job-details {
    width: 100%;
    margin-right: 0;
  }

  /* embedded software engineer heading */

  .job-details h2 {
    font-size: 24px;
    margin-bottom: 15px;
  }

  /* embedded software engineer responsibilities description and required skills */

  .job-details h3 {
    font-size: 20px;
    margin-bottom: 15px;
  }

  /* embedded software engineer description content */

  .job-details .job-description p {
    font-size: 14px;
  }

  /* embedded software engineer description bullets */

  .job-details .job-description p:before {
    font-size: 16px;
  }

  /* embedded software engineer apply form */

  .apply-form {
    width: 100%;
    height: auto;
    margin-right: 0;
    margin-top: 20px;
  }

  /* embedded software engineer apply form heading */

  .apply-form h3 {
    font-size: 20px;
  }

  /* embedded software engineer apply form box and labels, input and textarea */

  .apply-form input,
  .apply-form textarea {
    font-size: 12px;
  }

  /* embedded software engineer apply form button */

  .apply-form button {
    font-size: 14px;
  }
}

