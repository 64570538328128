
/* animation for AgriSens */

*{
    font-family: Play;
}

/* AgriSens page content */

.agrisens-container{
    width: 80%;
    margin-left: 15%;
    margin-right: 10%;
    margin-top:10%;
    margin-bottom: 5%;
}

/* AgriSens content */

.agrisens-container .agrisens-content{
    display: flex;
    flex-direction: column;
    animation: 3s slide-right;
}

/***** Animations *****/
@keyframes slide-right {
    from {
      margin-left: -100%;
    }
    to {
      margin-left: 0%;
    }
  }
  @keyframes slide-left {
    from {
      margin-left: 100%;
    }
    to {
      margin-left: 0%;
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .fade-in {
    animation: fadeIn ease 3s;
  }
  .slide-right {
    animation: 3s slide-right;
  }
  .slide-left {
    animation: 3s slide-left;
  }

/* AgriSens content heading */

.agrisens-container .agrisens-content h4{
    font-size: 30px;
    margin-bottom: 30px;
    text-align: center;
    color: #2348e2;
}

/* AgriSens content image */

.agrisens-container .agrisens-content img{
    width: 90%;
    /* margin-left: 5%; */
    margin-bottom: 10px;
}

/* AgriSens content text para */

.agrisens-container .agrisens-content .agrisens-text {
    margin-bottom: 10px;
}

/* AgriSens content text para */

.agrisens-container .agrisens-content .agrisens-text p {
    margin-top: 20px;
    font-size: 18px;
    margin-bottom: 20px;
    width: 40vw;
    text-align: justify;
}

/* AgriSens content image for text 1  */

.agrisens-container .agrisens-content .agrisens-text img {
    width: 30vw;
    margin-top: -15%;
    margin-left: 55%;
}

/* AgriSens content for text 2 */

.agrisens-container .agrisens-content .agrisens-text2 {
    margin-bottom: 10px;
}

/* AgriSens content for text 2 para */

.agrisens-container .agrisens-content .agrisens-text2 p {
    font-size: 18px;
    margin-bottom: 20px;
    margin-top: 10px;
    width: 75vw;
    text-align: justify;
    /* margin-left: 55%; */
}

/* AgriSens content for text 2 sub-text */

.agrisens-container .agrisens-content .agrisens-text2 .sub-agrisens-text {
    margin-bottom: 10px;
    margin-left: 55%;
    margin-top: -20%;
}

/* AgriSens content for text 2 sub-text bullets */

.agrisens-container .agrisens-content .agrisens-text2 .sub-agrisens-text p::before {
    content: "• ";
    font-size: 22px;
    margin-bottom: 20px;
}

/* AgriSens content for text 2 image */

.agrisens-container .agrisens-content .agrisens-text2 img {
    width: 30vw;
    margin-top: 5%;
    margin-left: 5%;
}

/* AgriSens content for text 3 */

.agrisens-container .agrisens-content .agrisens-text3{
    margin-bottom: 10px;
}

/* AgriSens content for text 3 para */

.agrisens-container .agrisens-content .agrisens-text3 p {
    font-size: 18px;
    margin-bottom: 20px;
    margin-top: 20px;
    width: 40vw;
    text-align: justify;
    /* margin-left: 55%; */
}

/* AgriSens content for text 3 image */

.agrisens-container .agrisens-content .agrisens-text3 img {
    width: 30vw;
    margin-top: -18%;
    margin-left: 55%;
    margin-bottom: 5%;
}

/* AgriSens content for text 4 */

.agrisens-container .agrisens-content .agrisens-text4{
    margin-bottom: 10px;
}

/* AgriSens content for text 4 image */

.agrisens-container .agrisens-content .agrisens-text4 img {
    width: 75vw;
    /* margin-top: -30%; */
    /* margin-left: 5%; */
}

/* AgriSens content for text 4 para */

.agrisens-container .agrisens-content .agrisens-text4 p {
    font-size: 18px;
    margin-bottom: 20px;
    width: 75vw;
    text-align: justify;
    /* margin-left: 55%; */
}

/* AgriSens content for text 4 sub-text */

.agrisens-container .agrisens-content .agrisens-text4 .sub-agrisens-text4 {
    margin-bottom: 10px;
    /* margin-left: 55%; */
    margin-top: -1%;
}

/* AgriSens content for text 4 sub-text bullets */

.agrisens-container .agrisens-content .agrisens-text4 .sub-agrisens-text4 p::before {
    content: "• ";
    font-size: 22px;
    margin-bottom: 20px;
}

@media screen and (max-width: 1920px) {
        
    /* animation for AgriSens */

    *{
        font-family: Play;
    }

    /* AgriSens page content */

    .agrisens-container{
        width: 80%;
        margin-left: 15%;
        margin-right: 10%;
        margin-top:10%;
        margin-bottom: 5%;
    }

    /* AgriSens content */

    .agrisens-container .agrisens-content{
        display: flex;
        flex-direction: column;
        animation: 3s slide-right;
    }

    /***** Animations *****/
    @keyframes slide-right {
        from {
        margin-left: -100%;
        }
        to {
        margin-left: 0%;
        }
    }
    @keyframes slide-left {
        from {
        margin-left: 100%;
        }
        to {
        margin-left: 0%;
        }
    }
    @keyframes fadeIn {
        0% {
        opacity: 0;
        }
        100% {
        opacity: 1;
        }
    }
    .fade-in {
        animation: fadeIn ease 3s;
    }
    .slide-right {
        animation: 3s slide-right;
    }
    .slide-left {
        animation: 3s slide-left;
    }

    /* AgriSens content heading */

    .agrisens-container .agrisens-content h4{
        font-size: 30px;
        margin-bottom: 30px;
        text-align: center;
        color: #2348e2;
    }

    /* AgriSens content image */

    .agrisens-container .agrisens-content img{
        width: 90%;
        /* margin-left: 5%; */
        margin-bottom: 10px;
    }

    /* AgriSens content text para */

    .agrisens-container .agrisens-content .agrisens-text {
        margin-bottom: 10px;
    }

    /* AgriSens content text para */

    .agrisens-container .agrisens-content .agrisens-text p {
        margin-top: 20px;
        font-size: 18px;
        margin-bottom: 20px;
        width: 40vw;
        text-align: justify;
    }

    /* AgriSens content image for text 1  */

    .agrisens-container .agrisens-content .agrisens-text img {
        width: 30vw;
        margin-top: -15%;
        margin-left: 55%;
    }

    /* AgriSens content for text 2 */

    .agrisens-container .agrisens-content .agrisens-text2 {
        margin-bottom: 10px;
        margin-top: -1%;
    }

    /* AgriSens content for text 2 para */

    .agrisens-container .agrisens-content .agrisens-text2 p {
        font-size: 18px;
        margin-bottom: 20px;
        margin-top: 10px;
        width: 75vw;
        text-align: justify;
        /* margin-left: 55%; */
    }

    /* AgriSens content for text 2 sub-text */

    .agrisens-container .agrisens-content .agrisens-text2 .sub-agrisens-text {
        margin-bottom: 10px;
        margin-left: 55%;
        margin-top: -20%;
    }

    /* AgriSens content for text 2 sub-text bullets */

    .agrisens-container .agrisens-content .agrisens-text2 .sub-agrisens-text p::before {
        content: "• ";
        font-size: 22px;
        margin-bottom: 20px;
    }

    /* AgriSens content for text 2 image */

    .agrisens-container .agrisens-content .agrisens-text2 img {
        width: 30vw;
        margin-top: 2%;
        margin-left: 5%;
        margin-bottom: 5%;
    }

    /* AgriSens content for text 3 */

    .agrisens-container .agrisens-content .agrisens-text3{
        margin-bottom: 10px;
    }

    /* AgriSens content for text 3 para */

    .agrisens-container .agrisens-content .agrisens-text3 p {
        font-size: 18px;
        margin-bottom: 20px;
        margin-top: 20px;
        width: 40vw;
        text-align: justify;
        /* margin-left: 55%; */
    }

    /* AgriSens content for text 3 image */

    .agrisens-container .agrisens-content .agrisens-text3 img {
        width: 30vw;
        margin-top: -18%;
        margin-left: 55%;
        margin-bottom: 5%;
    }

    /* AgriSens content for text 4 */

    .agrisens-container .agrisens-content .agrisens-text4{
        margin-bottom: 10px;
    }

    /* AgriSens content for text 4 image */

    .agrisens-container .agrisens-content .agrisens-text4 img {
        width: 75vw;
        /* margin-top: -30%; */
        /* margin-left: 5%; */
    }

    /* AgriSens content for text 4 para */

    .agrisens-container .agrisens-content .agrisens-text4 p {
        font-size: 18px;
        margin-bottom: 20px;
        width: 75vw;
        text-align: justify;
        /* margin-left: 55%; */
    }

    /* AgriSens content for text 4 sub-text */

    .agrisens-container .agrisens-content .agrisens-text4 .sub-agrisens-text4 {
        margin-bottom: 10px;
        /* margin-left: 55%; */
        margin-top: -1%;
    }

    /* AgriSens content for text 4 sub-text bullets */

    .agrisens-container .agrisens-content .agrisens-text4 .sub-agrisens-text4 p::before {
        content: "• ";
        font-size: 22px;
        margin-bottom: 20px;
    }
    
}

@media screen and (max-width: 1536px) and (min-width: 1263px) {
        
    /* animation for AgriSens */

    *{
        font-family: Play;
    }

    /* AgriSens page content */

    .agrisens-container{
        width: 80%;
        margin-left: 15%;
        margin-right: 10%;
        margin-top:10%;
        margin-bottom: 5%;
    }

    /* AgriSens content */

    .agrisens-container .agrisens-content{
        display: flex;
        flex-direction: column;
        animation: 3s slide-right;
    }

    /***** Animations *****/
    @keyframes slide-right {
        from {
        margin-left: -100%;
        }
        to {
        margin-left: 0%;
        }
    }
    @keyframes slide-left {
        from {
        margin-left: 100%;
        }
        to {
        margin-left: 0%;
        }
    }
    @keyframes fadeIn {
        0% {
        opacity: 0;
        }
        100% {
        opacity: 1;
        }
    }
    .fade-in {
        animation: fadeIn ease 3s;
    }
    .slide-right {
        animation: 3s slide-right;
    }
    .slide-left {
        animation: 3s slide-left;
    }

    /* AgriSens content heading */

    .agrisens-container .agrisens-content h4{
        font-size: 30px;
        margin-bottom: 30px;
        text-align: center;
        color: #2348e2;
    }

    /* AgriSens content image */

    .agrisens-container .agrisens-content img{
        width: 90%;
        /* margin-left: 5%; */
        margin-bottom: 10px;
    }

    /* AgriSens content text para */

    .agrisens-container .agrisens-content .agrisens-text {
        margin-bottom: 10px;
    }

    /* AgriSens content text para */

    .agrisens-container .agrisens-content .agrisens-text p {
        margin-top: 20px;
        font-size: 18px;
        margin-bottom: 20px;
        width: 40vw;
        text-align: justify;
    }

    /* AgriSens content image for text 1  */

    .agrisens-container .agrisens-content .agrisens-text img {
        width: 30vw;
        margin-top: -15%;
        margin-left: 55%;
    }

    /* AgriSens content for text 2 */

    .agrisens-container .agrisens-content .agrisens-text2 {
        margin-bottom: 10px;
    }

    /* AgriSens content for text 2 para */

    .agrisens-container .agrisens-content .agrisens-text2 p {
        font-size: 18px;
        margin-bottom: 20px;
        margin-top: 10px;
        width: 75vw;
        text-align: justify;
        /* margin-left: 55%; */
    }

    /* AgriSens content for text 2 sub-text */

    .agrisens-container .agrisens-content .agrisens-text2 .sub-agrisens-text {
        margin-bottom: 10px;
        margin-left: 55%;
        margin-top: -20%;
    }

    /* AgriSens content for text 2 sub-text bullets */

    .agrisens-container .agrisens-content .agrisens-text2 .sub-agrisens-text p::before {
        content: "• ";
        font-size: 22px;
        margin-bottom: 20px;
    }

    /* AgriSens content for text 2 image */

    .agrisens-container .agrisens-content .agrisens-text2 img {
        width: 30vw;
        margin-top: -1%;
        margin-left: 5%;
    }

    /* AgriSens content for text 3 */

    .agrisens-container .agrisens-content .agrisens-text3{
        margin-bottom: 10px;
    }

    /* AgriSens content for text 3 para */

    .agrisens-container .agrisens-content .agrisens-text3 p {
        font-size: 18px;
        margin-bottom: 20px;
        margin-top: 20px;
        width: 40vw;
        text-align: justify;
        /* margin-left: 55%; */
    }

    /* AgriSens content for text 3 image */

    .agrisens-container .agrisens-content .agrisens-text3 img {
        width: 30vw;
        margin-top: -18%;
        margin-left: 55%;
        margin-bottom: 5%;
    }

    /* AgriSens content for text 4 */

    .agrisens-container .agrisens-content .agrisens-text4{
        margin-bottom: 10px;
    }

    /* AgriSens content for text 4 image */

    .agrisens-container .agrisens-content .agrisens-text4 img {
        width: 75vw;
        /* margin-top: -30%; */
        /* margin-left: 5%; */
    }

    /* AgriSens content for text 4 para */

    .agrisens-container .agrisens-content .agrisens-text4 p {
        font-size: 18px;
        margin-bottom: 20px;
        width: 75vw;
        text-align: justify;
        /* margin-left: 55%; */
    }

    /* AgriSens content for text 4 sub-text */

    .agrisens-container .agrisens-content .agrisens-text4 .sub-agrisens-text4 {
        margin-bottom: 10px;
        /* margin-left: 55%; */
        margin-top: -1%;
    }

    /* AgriSens content for text 4 sub-text bullets */

    .agrisens-container .agrisens-content .agrisens-text4 .sub-agrisens-text4 p::before {
        content: "• ";
        font-size: 22px;
        margin-bottom: 20px;
    }
    
}

/* Responsive CSS for Tablet View at 768px */
@media only screen and (max-width: 768px) {
    /* AgriSens page content */
    .agrisens-container {
        width: 90%;
        margin-top: 30%;
        margin-left: 5%;
        margin-right: 5%;
    }

    /* AgriSens content for heading */

    .agrisens-container .agrisens-content h4 {
        font-size: 24px;
    }

    /* AgriSens content for main image */

    .agrisens-container .agrisens-content img {
        width: 100%;
    }

    /* AgriSens content text for all */

    .agrisens-container .agrisens-content .agrisens-text p,
    .agrisens-container .agrisens-content .agrisens-text2 p,
    .agrisens-container .agrisens-content .agrisens-text3 p,
    .agrisens-container .agrisens-content .agrisens-text4 p {
        width: 85vw;
        font-size: 16px;
    }

    /* AgriSens content image for all */

    .agrisens-container .agrisens-content .agrisens-text img,
    .agrisens-container .agrisens-content .agrisens-text2 img,
    .agrisens-container .agrisens-content .agrisens-text3 img {
        width: 55vw;
        margin-left: 0;
        margin-top: 10px;
    }

    /* AgriSens content text2 sub para */

    .agrisens-container .agrisens-content .agrisens-text2 .sub-agrisens-text{
        margin-left: 0;
        margin-top: 20px;
    }

    .agrisens-container .agrisens-content .agrisens-text4 img {
        width: 100%;
    }
}

/* General adjustments for mobile view */
@media only screen and (max-width: 430px) {
    /* AgriSens page content` */
    .agrisens-container {
        width: 95%;
        margin-left: 2.5%;
        margin-right: 2.5%;
        margin-top: 38%;
        margin-bottom: 5%;
    }

    /* AgriSens content  */

    .agrisens-container .agrisens-content {
        display: flex;
        flex-direction: column;
        animation: 3s slide-right;
    }

    /* AgriSens content heading */

    .agrisens-container .agrisens-content h4 {
        font-size: 24px;
        margin-bottom: 20px;
    }

    /* AgriSens content image */

    .agrisens-container .agrisens-content img {
        width: 100%; /* Make image full width for smaller screens */
        margin-bottom: 10px;
    }

    /* AgriSens content text para */

    .agrisens-container .agrisens-content .agrisens-text p {
        font-size: 16px;
        width: 90vw;
        margin-left: 0;
        text-align: justify;
    }

    /* AgriSens content text image */

    .agrisens-container .agrisens-content .agrisens-text img {
        width: 100%;
        margin-top: 10px;
        margin-left: 0;
    }

    /* AgriSens content text2 para */

    .agrisens-container .agrisens-content .agrisens-text2 p {
        font-size: 16px;
        width: 90vw;
        margin-left: 0;
        text-align: justify;
    }

    /* AgriSens content text2 sub para */

    .agrisens-container .agrisens-content .agrisens-text2 .sub-agrisens-text{
        margin-left: -10%;
        margin-top: 20px;
    }

    /* AgriSens content text2 image */

    .agrisens-container .agrisens-content .agrisens-text2 img {
        width: 100%;
        margin-top: 10px;
        margin-left: 0;
    }

    /* AgriSens content text3 para */

    .agrisens-container .agrisens-content .agrisens-text3 p {
        font-size: 16px;
        width: 90vw;
        text-align: justify;
    }

    /* AgriSens content text3 image */

    .agrisens-container .agrisens-content .agrisens-text3 img {
        width: 100%;
        margin-left: 0;
        margin-bottom: 10px;
    }

    /* AgriSens content text4 image */

    .agrisens-container .agrisens-content .agrisens-text4 img {
        width: 100%;
    }

    /* AgriSens content text4 para */

    .agrisens-container .agrisens-content .agrisens-text4 p {
        font-size: 16px;
        width: 90vw;
        text-align: justify;
        left: 40px;
    }

    /* AgriSens content text4 sub para */

    .agrisens-container .agrisens-content .agrisens-text4 .sub-agrisens-text4 p::before {
        font-size: 18px;
    }
}

@media screen and (max-width: 429px) and (min-width: 426px) {
    
    /* animation for AgriSens */

    *{
        font-family: Play;
    }

    /* AgriSens page content */

    .agrisens-container{
        width: 80%;
        margin-left: 15%;
        margin-right: 10%;
        margin-top:20%;
        margin-bottom: 5%;
    }

    /* AgriSens content */

    .agrisens-container .agrisens-content{
        display: flex;
        flex-direction: column;
        animation: 3s slide-right;
    }

    /***** Animations *****/
    @keyframes slide-right {
        from {
        margin-left: -100%;
        }
        to {
        margin-left: 0%;
        }
    }
    @keyframes slide-left {
        from {
        margin-left: 100%;
        }
        to {
        margin-left: 0%;
        }
    }
    @keyframes fadeIn {
        0% {
        opacity: 0;
        }
        100% {
        opacity: 1;
        }
    }
    .fade-in {
        animation: fadeIn ease 3s;
    }
    .slide-right {
        animation: 3s slide-right;
    }
    .slide-left {
        animation: 3s slide-left;
    }

    /* AgriSens content heading */

    .agrisens-container .agrisens-content h4{
        font-size: 30px;
        margin-bottom: 30px;
        text-align: center;
        color: #2348e2;
    }

    /* AgriSens content image */

    .agrisens-container .agrisens-content img{
        width: 90%;
        /* margin-left: 5%; */
        margin-bottom: 10px;
    }

    /* AgriSens content text para */

    .agrisens-container .agrisens-content .agrisens-text {
        margin-bottom: 10px;
    }

    /* AgriSens content text para */

    .agrisens-container .agrisens-content .agrisens-text p {
        margin-top: 20px;
        font-size: 18px;
        margin-bottom: 20px;
        width: 80vw;
        margin-left: -10%;
        text-align: justify;
    }

    /* AgriSens content image for text 1  */

    .agrisens-container .agrisens-content .agrisens-text img {
        width: 70vw;
        margin-top: 3%;
        margin-left: 5%;
    }

    /* AgriSens content for text 2 */

    .agrisens-container .agrisens-content .agrisens-text2 {
        margin-bottom: 10px;
    }

    /* AgriSens content for text 2 para */

    .agrisens-container .agrisens-content .agrisens-text2 p {
        font-size: 18px;
        margin-bottom: 20px;
        margin-top: 10px;
        width: 80vw;
        text-align: justify;
        margin-left: -5%;
    }

    /* AgriSens content for text 2 sub-text */

    .agrisens-container .agrisens-content .agrisens-text2 .sub-agrisens-text {
        margin-bottom: 10px;
        margin-left: 15%;
        margin-top: 5%;
    }

    /* AgriSens content for text 2 sub-text bullets */

    .agrisens-container .agrisens-content .agrisens-text2 .sub-agrisens-text p::before {
        content: "• ";
        font-size: 22px;
        margin-bottom: 20px;
    }

    /* AgriSens content for text 2 image */

    .agrisens-container .agrisens-content .agrisens-text2 img {
        width: 80vw;
        margin-top: 5%;
        margin-left: -5%;
    }

    /* AgriSens content for text 3 */

    .agrisens-container .agrisens-content .agrisens-text3{
        margin-bottom: 10px;
    }

    /* AgriSens content for text 3 para */

    .agrisens-container .agrisens-content .agrisens-text3 p {
        font-size: 18px;
        /* margin-bottom: 20px; */
        margin-top: 20px;
        width: 80vw;
        text-align: justify;
        /* margin-left: 55%; */
    }

    /* AgriSens content for text 3 image */

    .agrisens-container .agrisens-content .agrisens-text3 img {
        width: 80vw;
        margin-top: 10%;
        margin-left: 0%;
        margin-bottom: 5%;
    }

    /* AgriSens content for text 4 */

    .agrisens-container .agrisens-content .agrisens-text4{
        margin-bottom: 10px;
    }

    /* AgriSens content for text 4 image */

    .agrisens-container .agrisens-content .agrisens-text4 img {
        width: 75vw;
        /* margin-top: -30%; */
        /* margin-left: 5%; */
    }

    /* AgriSens content for text 4 para */

    .agrisens-container .agrisens-content .agrisens-text4 p {
        font-size: 18px;
        margin-bottom: 20px;
        width: 75vw;
        text-align: justify;
        /* margin-left: 55%; */
    }

    /* AgriSens content for text 4 sub-text */

    .agrisens-container .agrisens-content .agrisens-text4 .sub-agrisens-text4 {
        margin-bottom: 10px;
        /* margin-left: 55%; */
        margin-top: -1%;
    }

    /* AgriSens content for text 4 sub-text bullets */

    .agrisens-container .agrisens-content .agrisens-text4 .sub-agrisens-text4 p::before {
        content: "• ";
        font-size: 22px;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 425px) and (min-width: 320px) {
        
    /* animation for AgriSens */

    *{
        font-family: Play;
    }

    /* AgriSens page content */

    .agrisens-container{
        width: 80%;
        margin-left: 15%;
        margin-right: 10%;
        margin-top:20%;
        margin-bottom: 5%;
    }

    /* AgriSens content */

    .agrisens-container .agrisens-content{
        display: flex;
        flex-direction: column;
        animation: 3s slide-right;
    }

    /***** Animations *****/
    @keyframes slide-right {
        from {
        margin-left: -100%;
        }
        to {
        margin-left: 0%;
        }
    }
    @keyframes slide-left {
        from {
        margin-left: 100%;
        }
        to {
        margin-left: 0%;
        }
    }
    @keyframes fadeIn {
        0% {
        opacity: 0;
        }
        100% {
        opacity: 1;
        }
    }
    .fade-in {
        animation: fadeIn ease 3s;
    }
    .slide-right {
        animation: 3s slide-right;
    }
    .slide-left {
        animation: 3s slide-left;
    }

    /* AgriSens content heading */

    .agrisens-container .agrisens-content h4{
        font-size: 30px;
        margin-bottom: 30px;
        text-align: center;
        color: #2348e2;
    }

    /* AgriSens content image */

    .agrisens-container .agrisens-content img{
        width: 90%;
        /* margin-left: 5%; */
        margin-bottom: 10px;
    }

    /* AgriSens content text para */

    .agrisens-container .agrisens-content .agrisens-text {
        margin-bottom: 10px;
    }

    /* AgriSens content text para */

    .agrisens-container .agrisens-content .agrisens-text p {
        margin-top: 20px;
        font-size: 18px;
        margin-bottom: 20px;
        width: 80vw;
        margin-left: -10%;
        text-align: justify;
    }

    /* AgriSens content image for text 1  */

    .agrisens-container .agrisens-content .agrisens-text img {
        width: 70vw;
        margin-top: 3%;
        margin-left: 5%;
    }

    /* AgriSens content for text 2 */

    .agrisens-container .agrisens-content .agrisens-text2 {
        margin-bottom: 10px;
    }

    /* AgriSens content for text 2 para */

    .agrisens-container .agrisens-content .agrisens-text2 p {
        font-size: 18px;
        margin-bottom: 20px;
        margin-top: 10px;
        width: 80vw;
        text-align: justify;
        margin-left: -5%;
    }

    /* AgriSens content for text 2 sub-text */

    .agrisens-container .agrisens-content .agrisens-text2 .sub-agrisens-text {
        margin-bottom: 10px;
        margin-left: 15%;
        margin-top: 5%;
    }

    /* AgriSens content for text 2 sub-text bullets */

    .agrisens-container .agrisens-content .agrisens-text2 .sub-agrisens-text p::before {
        content: "• ";
        font-size: 22px;
        margin-bottom: 20px;
    }

    /* AgriSens content for text 2 image */

    .agrisens-container .agrisens-content .agrisens-text2 img {
        width: 80vw;
        margin-top: 5%;
        margin-left: -5%;
    }

    /* AgriSens content for text 3 */

    .agrisens-container .agrisens-content .agrisens-text3{
        margin-bottom: 10px;
    }

    /* AgriSens content for text 3 para */

    .agrisens-container .agrisens-content .agrisens-text3 p {
        font-size: 18px;
        /* margin-bottom: 20px; */
        margin-top: 20px;
        width: 80vw;
        text-align: justify;
        /* margin-left: 55%; */
    }

    /* AgriSens content for text 3 image */

    .agrisens-container .agrisens-content .agrisens-text3 img {
        width: 80vw;
        margin-top: 10%;
        margin-left: 0%;
        margin-bottom: 5%;
    }

    /* AgriSens content for text 4 */

    .agrisens-container .agrisens-content .agrisens-text4{
        margin-bottom: 10px;
    }

    /* AgriSens content for text 4 image */

    .agrisens-container .agrisens-content .agrisens-text4 img {
        width: 75vw;
        /* margin-top: -30%; */
        /* margin-left: 5%; */
    }

    /* AgriSens content for text 4 para */

    .agrisens-container .agrisens-content .agrisens-text4 p {
        font-size: 18px;
        margin-bottom: 20px;
        width: 75vw;
        text-align: justify;
        /* margin-left: 55%; */
    }

    /* AgriSens content for text 4 sub-text */

    .agrisens-container .agrisens-content .agrisens-text4 .sub-agrisens-text4 {
        margin-bottom: 10px;
        /* margin-left: 55%; */
        margin-top: -1%;
    }

    /* AgriSens content for text 4 sub-text bullets */

    .agrisens-container .agrisens-content .agrisens-text4 .sub-agrisens-text4 p::before {
        content: "• ";
        font-size: 22px;
        margin-bottom: 20px;
    }
}
