.profile{
    width: 100%;
}

.profile .title{
    font-size: 2rem;
    padding: 5px;
    text-decoration: underline;
}

.profile .subtitle{
    font-size: 1.3rem;
    padding: 5px;
    line-height: 0.9rem;
    margin-left: 0%;
    margin-top: 10px;
    color: #23458e;
    margin-bottom: 20px;
}

.profile .logout-button{
    background-color: #00ad5f;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    margin-bottom: 11%;
}

.profile .logout-button:hover{
    background-color: #007f4e;
}

@media screen and (max-width: 768px) {

    .profile{
        width: 100%;
    }
    .profile .title .subtitle{
        font-size: 1.1rem;
        line-height: 0.9rem;
    }

    .profile .logout-button{
        width: 50%;
        margin-left: 90px;
    }
}