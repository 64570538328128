
/* pcb design page */

.pcb-description-container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    margin-left: 5%;
    margin-top: 6%;
  }
  
  /* pcb description */
  
  .pcb-details {
    width: 60%;
    margin-right: 40px;
    text-align: justify;
  }
  
  /* pcb heading */
  
  .pcb-details h2 {
    font-size: 30px;
    margin-bottom: 20px;
    color: #23458e;
    text-align: left;
  }
  
  /* pcb responsibilities description and required skills */
  
  .pcb-details h3 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  /* pcb description content */
  
  .pcb-details .pcb-description p{
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 10px;
  }
  
  /* pcb description bullets */
  
  .pcb-details .pcb-description p:before{
    content: '• ';
    margin-right: 15px;
    font-size: 18px;
  }
  
  /* pcb additional */
  
  .pcb-description-info p {
    display: block;
    /* padding: 10px; */
    justify-content: space-between;
    margin-top: 20px;
  }
  
  /* pcb apply form */
  
  .pcb-apply-form {
    width: 35%;
    height: 45%;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-right: 5%;
  }
  
  /* pcb apply form heading */
  
  .pcb-apply-form h3 {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
    color: #23458e;
  }
  
  /* pcb apply form box */
  
  .pcb-apply-form form {
    display: flex;
    flex-direction: column;
  }
  
  /* pcb apply form labels */
  
  .pcb-apply-form label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  /* pcb apply form input and textarea */
  
  .pcb-apply-form input,
  .pcb-apply-form textarea {
    margin-bottom: 15px;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  /* pcb apply form button */
  
  .pcb-apply-form button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  /* pcb apply form button hover */
  
  .pcb-apply-form button:hover {
    background-color: #0056b3;
  }

  @media (max-width: 1263px) {
    /* pcb description container */
    .pcb-description-container {
      flex-direction: row; /* Stack elements vertically */
      margin-left: 5%; /* Remove left margin */
      margin-right: 5%;
      margin-top: -2%; /* Reduce top margin */
    }
  
    /* pcb details */
    .pcb-details {
      width: 55%; /* Full width on smaller screens */
      margin-right: 0; /* Remove right margin */
    }
  
    /* pcb apply form */
    .pcb-apply-form {
      width: 40%; /* Full width on smaller screens */
      /* height: auto; Adjust height */
      margin-right: 0; /* Remove right margin */
      margin-top: 10%;
    }
  
    /* pcb apply form heading */
    .pcb-apply-form h3 {
      font-size: 22px; /* Slightly smaller font size */
    }
  
    /* pcb apply form input and textarea */
    .pcb-apply-form input,
    .pcb-apply-form textarea {
      font-size: 16px; /* Increase font size for better readability */
    }
  
    /* pcb apply form button */
    .pcb-apply-form button {
      font-size: 18px; /* Slightly larger font size */
    }
  }
  
  
  /* Responsive styles for tablet view (768px) */
  @media (max-width: 768px) {
  
    /* pcb page */
    .pcb-description-container {
      flex-direction: column;
      margin-left: 0;
      padding: 10px;
      margin-top: 18%;
    }
  
    /* pcb description */
  
    .pcb-details {
      width: 100%;
      margin-right: 0;
    }
  
    /* pcb heading */
  
    .pcb-details h2 {
      font-size: 24px;
      margin-bottom: 15px;
    }
  
    /* pcb responsibilities description and required skills */
  
    .pcb-details h3 {
      font-size: 20px;
      margin-bottom: 15px;
    }
  
    /* pcb description content */
  
    .pcb-details .pcb-description p {
      font-size: 14px;
    }
  
    /* pcb description bullets */
  
    .pcb-details .pcb-description p:before {
      font-size: 16px;
    }
  
    /* pcb apply form */
  
    .pcb-apply-form {
      width: 100%;
      height: auto;
      margin-right: 0;
      margin-top: 20px;
    }
  
    /* pcb apply form heading */
  
    .pcb-apply-form h3 {
      font-size: 20px;
    }
  
    /* pcb apply form box and labels, input and textarea */
  
    .pcb-apply-form input,
    .pcb-apply-form textarea {
      font-size: 12px;
    }
  
    /* pcb apply form button */
  
    .pcb-apply-form button {
      font-size: 14px;
    }
  }
  
  