
/* database developer page */

.database-description-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin-left: 5%;
  margin-top: 6%;
}

/* database description */

.database-details {
  width: 60%;
  margin-right: 40px;
  text-align: justify;
}

/* database description heading */

.database-details h2 {
  font-size: 30px;
  margin-bottom: 20px;
  color: #23458e;
  text-align: left;
}

/* database description heading for responsibilities and education */

.database-details h3 {
  font-size: 350px;
  margin-bottom: 20px;
}

/* database description content */

.database-details .database-description p{
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 10px;
}

/* database description list style with bullet points */

.database-details .database-description p:before{
  content: '• ';
  margin-right: 15px;
  font-size: 18px;
}

/* database additional description */

.database-description-info p {
  display: block;
  /* padding: 10px; */
  justify-content: space-between;
  margin-top: 20px;
}

/* database apply form */

.database-apply-form {
  width: 35%;
  height: 45%;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-right: 5%;
}

/* database apply form heading */

.database-apply-form h3 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  color: #23458e;
}

/* database apply form box */

.database-apply-form form {
  display: flex;
  flex-direction: column;
}

/* database apply form labeling with fields */

.database-apply-form label {
  margin-bottom: 5px;
  font-weight: bold;
}

/* database apply form input and textarea */

.database-apply-form input,
.database-apply-form textarea {
  margin-bottom: 15px;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

/* database apply form button */

.database-apply-form button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

/* database apply form button hover */

.database-apply-form button:hover {
  background-color: #0056b3;
}

/* Base styles */

/* database developer page */
.database-description-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin-left: 5%;
  margin-top: 6%;
}

/* database description */
.database-details {
  width: 60%;
  margin-right: 40px;
  text-align: justify;
}

/* database description heading */
.database-details h2 {
  font-size: 30px;
  margin-bottom: 20px;
  color: #23458e;
  text-align: left;
}

/* database description heading for responsibilities and education */
.database-details h3 {
  font-size: 350px; /* This seems unusually large; consider adjusting */
  margin-bottom: 20px;
}

/* database description content */
.database-details .database-description p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 10px;
}

/* database description list style with bullet points */
.database-details .database-description p:before {
  content: '• ';
  margin-right: 15px;
  font-size: 18px;
}

/* database additional description */
.database-description-info p {
  display: block;
  margin-top: 20px;
}

/* database apply form */
.database-apply-form {
  width: 35%;
  height: 45%;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-right: 5%;
}

/* database apply form heading */
.database-apply-form h3 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  color: #23458e;
}

/* database apply form box */
.database-apply-form form {
  display: flex;
  flex-direction: column;
}

/* database apply form labeling with fields */
.database-apply-form label {
  margin-bottom: 5px;
  font-weight: bold;
}

/* database apply form input and textarea */
.database-apply-form input,
.database-apply-form textarea {
  margin-bottom: 15px;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

/* database apply form button */
.database-apply-form button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

/* database apply form button hover */
.database-apply-form button:hover {
  background-color: #0056b3;
}

/* Responsive styles for laptop view (max-width: 1268px) */
@media (max-width: 1263px) {
  .database-description-container {
    flex-direction: row;
    align-items: center;
    margin-top: -1%;
  }

  .database-details {
    width: 50%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .database-apply-form {
    width: 40%;
    height: auto;
    margin-right: 5%;
    margin-top: -20%;
  }

  .database-details h3 {
    font-size: 60px; /* Adjusted size for better readability */
  }

  .database-apply-form h3 {
    font-size: 20px;
  }
}

  
/* Tablet view (screen width: 768px) */
@media (max-width: 768px) {
  /* database developer page */
  .database-description-container {
    flex-direction: column;
    margin-left: 0;
    margin-top: 15%;
  }

  /* database description */

  .database-details {
    width: 100%;
    margin-right: 0;
  }

  /* database description heading */

  .database-details h2 {
    font-size: 24px;
  }

  /* database description heading for responsibilities and education */

  .database-details h3 {
    font-size: 200px;
  }

  /* database description content */

  .database-details .database-description p {
    font-size: 14px;
  }

  /* database apply form */

  .database-apply-form {
    width: 100%;
    height: auto;
    margin-right: 0;
    margin-top: 20px;
  }

  /* database apply form heading */

  .database-apply-form h3 {
    font-size: 20px;
  }

  /* database apply form box */

  .database-apply-form input,
  .database-apply-form textarea {
    font-size: 16px;
  }

  /* database apply form button */

  .database-apply-form button {
    font-size: 14px;
  }
}
