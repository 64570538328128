/* Healthcare service content */

.healthcare_content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin-top: 8%;
}

/* main heading */

.healthcare_content h5 {
    font-size: 3.5rem;
    text-align: center;
    align-items: center;
    padding: 10px;
    color: #2348E2;
    margin-left: 30px;
}

/* para content */

.healthcare_para_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.healthcare_para_content p{
    font-size: 22px;
    text-align: justify;
    align-items: center;
    padding: 10px;
    /* color: #2348E2; */
    width: 70vw;
    margin-left: 75px;
    padding: 20px;
}

/* main image content below the heading */

.healthcare_para_content .img1 {
    width: 60vw;
    height: 65vh;
    margin-left: 75px;
}

/* benefits content */

.healthcare_benefits {
    position: relative;
    width: 80%;
    margin: 0 auto;
    /* padding-top: 10px;
    margin-top: 10px; */
}

/* benefits heading */

.healthcare_benefits h5{
    font-size: 35px;
    text-align: justify;
    align-items: center;
    padding: 10px;
    color: #2348E2;
    width: 50vw;
    margin-left: 35px;
    /* padding: 20px; */
}

/* benefits image */

.healthcare_benefits .img2{
    float: right;
    width: 50%;
    margin-left: 20px;
    margin-bottom: 30px;
    /* margin-left: 20px; */
}

.healthcare_benefits .benefits{
    font-size: 20px;
    /* text-align: justify; */
    align-items: center;
    /* padding: 10px; */
    /* color: #2348E2; */
    width: 30vw;
    margin-left: 65px;
    /* padding: 20px; */
}

.healthcare_benefits .benefits p{
    position: relative; /* For positioning the pseudo-element */
    padding-left: 5px; /* Space for the bullet */
    margin: 5px 0; /* Add margin between paragraphs */
    /* color: #2348E2; */
    width: 30vw;
    text-align: justify;
    font-size: 20px;
}

.healthcare_benefits .benefits p::before {
    content: '•'; /* Bullet character */
    position: absolute;
    left: -20px;
    /* color: #2348E2;  */
    font-size: 24px; /* Bullet size */
    line-height: 1; /* Adjust bullet alignment */
}

/* healthcare applications */ 

.healthcare_applications {
    position: relative;
    width: 80%;
    margin: 0 auto;
    padding-top: 10px;
    margin-top: 10px;
}

/* applications heading */

.healthcare_applications h5{
    font-size: 35px;
    text-align: justify;
    align-items: center;
    color: #2348E2;
    float: right;
    margin-left: -90%;
    /* margin-right: 160px; */
}

/* applications image */

.healthcare_applications .img3{
    float: left;
    width: 35vw;
    margin-bottom: 30px;
}
.healthcare_applications .applications{
    font-size: 20px;
    text-align: justify;
    align-items: center;
    padding: 10px;
    margin-top: 65px;
    /* color: #2348E2; */
    width: 35vw;
    float: right;
    /* margin-left: 25px;
    padding: 20px; */
}

.healthcare_applications .applications p{
    position: relative; /* For positioning the pseudo-element */
    padding-left: 5px; /* Space for the bullet */
    /* color: #2348E2; */
    text-align: justify;
    font-size: 20px;
}

.healthcare_applications .applications p::before {
    content: '•'; /* Bullet character */
    position: absolute; /* For positioning the pseudo-element */
    left: -20px;
    /* color: #2348E2;  */
    font-size: 24px; /* Bullet size */
    line-height: 1; /* Adjust bullet alignment */
}

/* healthcare down image */

.healthcare_down_image .img4{
    width: 80vw;
}

@media screen and (max-width: 1920px) {
    /* Healthcare service content */

    .healthcare_content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
        margin-top: 8%;
    }

    /* main heading */

    .healthcare_content h5 {
        font-size: 3.5rem;
        text-align: center;
        align-items: center;
        padding: 10px;
        color: #2348E2;
        margin-left: 30px;
    }

    /* para content */

    .healthcare_para_content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px;
    }

    .healthcare_para_content p{
        font-size: 22px;
        text-align: justify;
        align-items: center;
        padding: 10px;
        /* color: #2348E2; */
        width: 70vw;
        margin-left: 75px;
        padding: 20px;
    }

    /* main image content below the heading */

    .healthcare_para_content .img1 {
        width: 60vw;
        height: 65vh;
        margin-left: 75px;
    }

    /* benefits content */

    .healthcare_benefits {
        position: relative;
        width: 80%;
        margin: 0 auto;
        /* padding-top: 10px;
        margin-top: 10px; */
    }

    /* benefits heading */

    .healthcare_benefits h5{
        font-size: 35px;
        text-align: justify;
        align-items: center;
        padding: 10px;
        color: #2348E2;
        width: 50vw;
        margin-left: 20%;
        /* padding: 20px; */
    }

    /* benefits image */

    .healthcare_benefits .img2{
        float: right;
        width: 20vw;
        margin-right: 20%;
        margin-top: 5%;
        margin-bottom: 30px;
        /* margin-left: 20px; */
    }

    .healthcare_benefits .benefits{
        font-size: 20px;
        /* text-align: justify; */
        align-items: center;
        /* padding: 10px; */
        /* color: #2348E2; */
        width: 30vw;
        margin-left: 20%;
        /* padding: 20px; */
    }

    .healthcare_benefits .benefits p{
        position: relative; /* For positioning the pseudo-element */
        padding-left: 5px; /* Space for the bullet */
        margin: 5px 0; /* Add margin between paragraphs */
        /* color: #2348E2; */
        width: 20vw;
        text-align: justify;
        font-size: 20px;
    }

    .healthcare_benefits .benefits p::before {
        content: '•'; /* Bullet character */
        position: absolute;
        left: -20px;
        /* color: #2348E2;  */
        font-size: 24px; /* Bullet size */
        line-height: 1; /* Adjust bullet alignment */
    }

    /* healthcare applications */ 

    .healthcare_applications {
        position: relative;
        width: 80%;
        margin: 0 auto;
        padding-top: 10px;
        margin-top: 10px;
    }

    /* applications heading */

    .healthcare_applications h5{
        font-size: 35px;
        text-align: justify;
        align-items: center;
        color: #2348E2;
        float: right;
        margin-right: 15%;
        /* margin-right: 160px; */
    }

    /* applications image */

    .healthcare_applications .img3{
        float: left;
        width: 20vw;
        margin-bottom: 30px;
        margin-left: 20%;
        margin-top: -22%;
    }
    .healthcare_applications .applications{
        font-size: 20px;
        text-align: justify;
        align-items: center;
        padding: 10px;
        margin-top: 5%;
        /* color: #2348E2; */
        width: 30vw;
        float: none;
        margin-left: 58%;
        /* margin-left: 25px;
        padding: 20px; */
    }

    .healthcare_applications .applications p{
        position: relative; /* For positioning the pseudo-element */
        padding-left: 5px; /* Space for the bullet */
        /* color: #2348E2; */
        text-align: justify;
        width: 20vw;
        font-size: 20px;
    }

    .healthcare_applications .applications p::before {
        content: '•'; /* Bullet character */
        position: absolute; /* For positioning the pseudo-element */
        left: -20px;
        /* color: #2348E2;  */
        font-size: 24px; /* Bullet size */
        line-height: 1; /* Adjust bullet alignment */
    }

    /* healthcare down image */

    .healthcare_down_image .img4{
        width: 80vw;
    }
    
}

@media screen and (max-width: 1536px){
    /* Healthcare service content */

    .healthcare_content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
        margin-top: 8%;
    }

    /* main heading */

    .healthcare_content h5 {
        font-size: 3.5rem;
        text-align: center;
        align-items: center;
        padding: 10px;
        color: #2348E2;
        margin-left: 30px;
    }

    /* para content */

    .healthcare_para_content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px;
    }

    .healthcare_para_content p{
        font-size: 22px;
        text-align: justify;
        align-items: center;
        padding: 10px;
        /* color: #2348E2; */
        width: 70vw;
        margin-left: 75px;
        padding: 20px;
    }

    /* main image content below the heading */

    .healthcare_para_content .img1 {
        width: 60vw;
        height: 65vh;
        margin-left: 75px;
    }

    /* benefits content */

    .healthcare_benefits {
        position: relative;
        width: 80%;
        margin: 0 auto;
        /* padding-top: 10px;
        margin-top: 10px; */
    }

    /* benefits heading */

    .healthcare_benefits h5{
        font-size: 35px;
        text-align: justify;
        align-items: center;
        padding: 10px;
        color: #2348E2;
        width: 50vw;
        margin-left: 20%;
        margin-top: -30%;
        /* padding: 20px; */
    }

    /* benefits image */

    .healthcare_benefits .img2{
        float: none;
        width: 20vw;
        margin-right: 10%;
        /* margin-bottom: -20px; */
        margin-top: 8%;
        margin-left: 58%;
    }

    .healthcare_benefits .benefits{
        font-size: 20px;
        /* text-align: justify; */
        align-items: center;
        /* padding: 10px; */
        /* color: #2348E2; */
        width: 25vw;
        margin-left: 20%;
        /* margin-top: -30%; */
        /* padding: 20px; */
    }

    .healthcare_benefits .benefits p{
        position: relative; /* For positioning the pseudo-element */
        padding-left: 5px; /* Space for the bullet */
        margin: 5px 0; /* Add margin between paragraphs */
        /* color: #2348E2; */
        width: 25vw;
        text-align: justify;
        font-size: 20px;
    }

    .healthcare_benefits .benefits p::before {
        content: '•'; /* Bullet character */
        position: absolute;
        left: -20px;
        /* color: #2348E2;  */
        font-size: 24px; /* Bullet size */
        line-height: 1; /* Adjust bullet alignment */
    }

    /* healthcare applications */ 

    .healthcare_applications {
        position: relative;
        width: 80%;
        margin: 0 auto;
        padding-top: 10px;
        margin-top: 10px;
    }

    /* applications heading */

    .healthcare_applications h5{
        font-size: 35px;
        text-align: justify;
        align-items: center;
        color: #2348E2;
        float: right;
        margin-left: -60%;
        /* margin-right: 160px; */
    }

    /* applications image */

    .healthcare_applications .img3{
        float: left;
        width: 25vw;
        margin-top: -30%;
        margin-bottom: 30px;
    }
    .healthcare_applications .applications{
        font-size: 20px;
        text-align: justify;
        align-items: center;
        padding: 10px;
        margin-top: 65px;
        /* color: #2348E2; */
        width: 35vw;
        float: right;
        /* margin-left: 25px;
        padding: 20px; */
    }

    .healthcare_applications .applications p{
        position: relative; /* For positioning the pseudo-element */
        padding-left: 5px; /* Space for the bullet */
        /* color: #2348E2; */
        text-align: justify;
        font-size: 20px;
    }

    .healthcare_applications .applications p::before {
        content: '•'; /* Bullet character */
        position: absolute; /* For positioning the pseudo-element */
        left: -20px;
        /* color: #2348E2;  */
        font-size: 24px; /* Bullet size */
        line-height: 1; /* Adjust bullet alignment */
    }

    /* healthcare down image */

    .healthcare_down_image .img4{
        width: 80vw;
    }
}

@media screen and (max-width: 1263px) {
    /* Healthcare service content */

    .healthcare_content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
        margin-top: 8%;
    }

    /* main heading */

    .healthcare_content h5 {
        font-size: 3.5rem;
        text-align: center;
        align-items: center;
        padding: 10px;
        color: #2348E2;
        margin-left: 30px;
    }

    /* para content */

    .healthcare_para_content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px;
    }

    .healthcare_para_content p{
        font-size: 22px;
        text-align: justify;
        align-items: center;
        padding: 10px;
        /* color: #2348E2; */
        width: 70vw;
        margin-left: 75px;
        padding: 20px;
    }

    /* main image content below the heading */

    .healthcare_para_content .img1 {
        width: 60vw;
        height: 65vh;
        margin-left: 75px;
    }

    /* benefits content */

    .healthcare_benefits {
        position: relative;
        width: 80%;
        margin: 0 auto;
        /* padding-top: 10px;
        margin-top: 10px; */
    }

    /* benefits heading */

    .healthcare_benefits h5{
        font-size: 35px;
        text-align: justify;
        align-items: center;
        padding: 10px;
        color: #2348E2;
        width: 50vw;
        margin-left: 35px;
        margin-top: 1%;
        /* padding: 20px; */
    }

    /* benefits image */

    .healthcare_benefits .img2{
        float: right;
        width: 25vw;
        margin-left: 20px;
        margin-bottom: 30px;
        /* margin-left: 20px; */
    }

    .healthcare_benefits .benefits{
        font-size: 20px;
        /* text-align: justify; */
        align-items: center;
        /* padding: 10px; */
        /* color: #2348E2; */
        width: 30vw;
        margin-left: 65px;
        margin-top: 2%;
        /* padding: 20px; */
    }

    .healthcare_benefits .benefits p{
        position: relative; /* For positioning the pseudo-element */
        padding-left: 5px; /* Space for the bullet */
        margin: 5px 0; /* Add margin between paragraphs */
        /* color: #2348E2; */
        width: 30vw;
        text-align: justify;
        font-size: 20px;
    }

    .healthcare_benefits .benefits p::before {
        content: '•'; /* Bullet character */
        position: absolute;
        left: -20px;
        /* color: #2348E2;  */
        font-size: 24px; /* Bullet size */
        line-height: 1; /* Adjust bullet alignment */
    }

    /* healthcare applications */ 

    .healthcare_applications {
        position: relative;
        width: 80%;
        margin: 0 auto;
        padding-top: 10px;
        margin-top: 10px;
    }

    /* applications heading */

    .healthcare_applications h5{
        font-size: 35px;
        text-align: left;
        width: 80vw;
        align-items: center;
        color: #2348E2;
        float: none;
        margin-left: 53%;
        /* margin-right: 160px; */
    }

    /* applications image */

    .healthcare_applications .img3{
        float: left;
        width: 30vw;
        margin-bottom: 30px;
        margin-left: 8%;
        margin-top: -32%;
    }
    .healthcare_applications .applications{
        font-size: 20px;
        text-align: justify;
        align-items: center;
        padding: 10px;
        margin-top: 0px;
        /* color: #2348E2; */
        width: 35vw;
        float: right;
        /* margin-left: 25px;
        padding: 20px; */
    }

    .healthcare_applications .applications p{
        position: relative; /* For positioning the pseudo-element */
        padding-left: 5px; /* Space for the bullet */
        /* color: #2348E2; */
        text-align: justify;
        font-size: 20px;
        width: 35vw;
    }

    .healthcare_applications .applications p::before {
        content: '•'; /* Bullet character */
        position: absolute; /* For positioning the pseudo-element */
        left: -20px;
        /* color: #2348E2;  */
        font-size: 24px; /* Bullet size */
        line-height: 1; /* Adjust bullet alignment */
    }

    /* healthcare down image */

    .healthcare_down_image .img4{
        width: 80vw;
    }
    
}

/* Tablet view (max-width: 768px) */

@media (max-width: 768px) {
    /* Healthcare content */
    .healthcare_content {
        padding: 10px;
        margin-top: 30%;
    }

    /* Main heading */
    .healthcare_content h5 {
        font-size: 2.5rem;
        margin-left: 10px;
    }

    /* Paragraph content */
    .healthcare_para_content {
        padding: 5px;
    }

    .healthcare_para_content p {
        font-size: 18px;
        width: 90vw;
        margin-left: 10px;
        padding: 10px;
    }

    /* Main image content below the heading */
    .healthcare_para_content .img1 {
        width: 90vw;
        height: auto; /* Adjust height for responsiveness */
        margin-left: 10px;
    }

    /* Benefits content */
    .healthcare_benefits {
        width: 90%;
    }

    /* Benefits heading */
    .healthcare_benefits h5 {
        font-size: 28px;
        width: 90vw;
        margin-left: 10px;
    }

    /* Benefits image */
    .healthcare_benefits .img2 {
        width: 80vw;
        margin-left: 0;
        margin-bottom: 20px;
    }

    .healthcare_benefits .benefits {
        width: 90vw;
        margin-left: 0;
    }

    .healthcare_benefits .benefits p {
        font-size: 18px;
        width: 100%;
    }

    /* Healthcare applications */
    .healthcare_applications {
        width: 80vw;
        padding-top: 5px;
        margin-top: 10px;
        margin-left: 2%;
    }

    /* Applications heading */
    .healthcare_applications h5 {
        font-size: 28px;
        margin-left: 0;
        margin-right: 0;
        float: none;
        text-align: center;
    }

    /* Applications image */
    .healthcare_applications .img3 {
        width: 80vw;
        float: none;
        margin-bottom: 20px;
        margin-top: 10%;
    }

    .healthcare_applications .applications p{
        width: 80vw;
        float: none;
        margin-top: 20px;
        margin-left: -120%;
    }

    .healthcare_applications .applications p {
        font-size: 18px;
        text-align: justify;
    }

    /* Healthcare down image */
    .healthcare_down_image .img4 {
        width: 90vw;
    }
}

/* Mobile view (max-width: 430px) */

@media (max-width: 429px) {
    /* Healthcare service content */

    .healthcare_content {
        padding: 10px;
        margin-top: 20%;
    }

    .healthcare_content h5 {
        font-size: 2.5rem;
        margin-left: 0;
    }

    /* Para content */

    .healthcare_para_content {
        padding: 5px;
    }

    .healthcare_para_content p {
        font-size: 18px;
        width: 90vw;
        margin-left: 0;
        padding: 10px;
    }

    /* Main image content below the heading */

    .healthcare_para_content .img1 {
        width: 90vw;
        height: auto;
        margin-left: 0;
    }

    /* Benefits content */

    .healthcare_benefits {
        width: 90%;
    }

    /* Benefits heading */

    .healthcare_benefits h5 {
        font-size: 25px;
        width: 90vw;
        margin-left: 0;
    }

    /* Benefits image */

    .healthcare_benefits .img2 {
        float: none;
        width: 100%;
        margin-left: 0;
        margin-bottom: 20px;
    }

    /* Benefits text */

    .healthcare_benefits .benefits {
        width: 90vw;
        margin-left: 0;
    }

    /* Benefits bullet points */

    .healthcare_benefits .benefits p::before {
        left: -15px;
        font-size: 20px;
    }

    /* Healthcare applications */

    .healthcare_applications {
        width: 80vw;
        padding-top: 5px;
        margin-top: 10px;
    }

    /* Applications heading */

    .healthcare_applications h5 {
        font-size: 25px;
        margin-left: 0;
        margin-right: 0;
    }

    /* Applications image */

    .healthcare_applications .img3 {
        float: none;
        width: 80vw;
        margin-bottom: 20px;
    }

    /* Applications text */

    .healthcare_applications .applications p{
        width: 80vw;
        float: none;
        margin-top: 20px;
    }

    /* Applications bullet points */

    .healthcare_applications .applications p::before {
        left: -15px;
        font-size: 20px;
    }

    /* Healthcare down image */

    .healthcare_down_image .img4 {
        width: 90vw;
    }

}

/* Mobile View (max-width: 425px) */

@media (max-width: 425px) {

    /* Healthcare Service Content */
    .healthcare_content {
        padding: 10px;
        margin-top: 20%;
    }

    .healthcare_content h5 {
        font-size: 2.5rem; /* Adjusted font size */
        margin-left: 0; /* Removed margin */
    }

    /* Paragraph Content */
    .healthcare_para_content {
        padding: 5px;
    }

    .healthcare_para_content p {
        font-size: 18px; /* Adjusted font size */
        width: 90vw; /* Adjusted width */
        margin-left: 0; /* Removed margin */
    }

    /* Main Image Content */
    .healthcare_para_content .img1 {
        width: 90vw; /* Adjusted width */
        height: auto; /* Adjust height to auto */
        margin-left: 0; /* Removed margin */
    }

    /* Benefits Content */
    .healthcare_benefits {
        width: 100%; /* Full width */
        margin: 0;
    }

    .healthcare_benefits h5 {
        font-size: 28px; /* Adjusted font size */
        width: 90vw; /* Adjusted width */
        margin-left: 0; /* Removed margin */
    }

    .healthcare_benefits .img2 {
        width: 100%; /* Full width */
        margin-left: 0; /* Removed margin */
        margin-bottom: 20px; /* Adjusted margin */
    }

    .healthcare_benefits .benefits {
        font-size: 18px; /* Adjusted font size */
        width: 90vw; /* Adjusted width */
        margin-left: 15px; /* Removed margin */
    }

    .healthcare_benefits .benefits p::before {
        font-size: 20px; /* Adjusted bullet size */
        left: -15px; /* Adjusted position */
    }

    /* Healthcare Applications */
    .healthcare_applications {
        width: 100%; /* Full width */
        margin: 0;
        padding-top: 5px; /* Adjusted padding */
        margin-top: 5px; /* Adjusted margin */
    }

    .healthcare_applications h5 {
        font-size: 28px; /* Adjusted font size */
        margin-left: -20px; /* Removed margin */
        margin-right: 0; /* Removed margin */
        text-align: center; /* Centered heading */
    }

    .healthcare_applications .img3 {
        width: 80vw; /* Full width */
        margin-bottom: 20px; /* Adjusted margin */
    }

    .healthcare_applications .applications p{
        font-size: 18px; /* Adjusted font size */
        width: 80vw; /* Adjusted width */
        float: none; /* Removed float */
        margin-top: 30px; /* Adjusted margin */
        margin-left: -170%;
    }

    .healthcare_applications .applications p::before {
        font-size: 20px; /* Adjusted bullet size */
        left: -25px; /* Adjusted position */
    }

    /* Healthcare Down Image */
    .healthcare_down_image .img4 {
        width: 90vw; /* Adjusted width */
    }
}

