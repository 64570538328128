/* src/ContactForm.css */
@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');

/* Complete Contact form */

.contact-form {
  width: 80vw;
  display: flex;
  justify-content: space-between;
  background: #fff;
  margin: 150px 0;
  margin-left: 13%;
}

.contact-form > * {
  width: 50%;
}

/* Office Info with image */

.first-container {
  background: linear-gradient(45deg, rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url("https://colorlib.com/etc/cf/ContactFrom_v17/images/bg-01.jpg") center center / cover no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-container div {
  margin: 24px 0;
}

 /* Office address headings */

.info-container h3 {
  color: #fff;
  font-size: 25px;
  font-weight: 800;
  line-height: 1.2;
  padding-bottom: 10px;
}

/* Office address suggestions area's */

.info-container p {
  font-size: 20px;
  line-height: 1.6;
  color: #00ad5f;
}

/* Contact form with input fields */

.second-container {
  padding: 30px;
}

/* Contact form headings */

.second-container h2 {
  font-size: 30px;
  font-weight: 400;
  color: #333;
  line-height: 1.2;
  text-align: center;
  margin-bottom: 20px;
}

/* Contact form input fields */

.form-group {
  margin-bottom: 10px;
}

.form-group * {
  min-height: 55px;
  border: 1px solid #e6e6e6;
  padding: 0 20px;
}

/* Contact form labels */

.form-group label {
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid #e6e6e6;
  font-size: 16px;
  color: #333;
  text-transform: uppercase;
  margin-top: -1px;
}

/* Contact form input fields */

.form-group input {
  width: 100%;
  font-size: 15px;
  margin-top: -2px;
}

/* Contact form placeholders */

.form-group input::placeholder, 
.form-group textarea::placeholder {
  color: #999;
}

/* Contact form textareas */

.form-group textarea {
  width: 100%;
  min-height: 80px;
  resize: none;
  padding: 10px 20px;
  margin-top: -1px;
}

/* Contact form submit button */

button {
  width: 200px;
  height: 50px;
  background: #00ad5f;
  color: #fff;
  font-size: 17px;
  font-weight: 600;
  text-transform: uppercase;
  border: 0;
  position: relative;
  left: calc(50% - 100px);
  cursor: pointer;
}

/* Contact form hover effect */

button:hover {
  background: #333;
}


/* Responsive CSS for tablet view (max-width: 768px) */
@media (max-width: 768px) {
  .contact-form {
    flex-direction: column;
    width: 90vw;
    margin-left: 5%;
  }

  .contact-form > * {
    width: 100%;
    margin-bottom: 20px;
  }

  .first-container {
    height: 350px;
    background-size: cover;
  }

  .second-container {
    padding: 20px;
  }

  button {
    width: 100%;
    left: 0;
  }
}

/* Responsive view for screens up to 425px */

@media (max-width: 425px) {
  /* src/ContactForm.css */

  /* Base styles */
  @import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');

  .contact-form {
    width: 100vw;
    display: block;
    background: #fff;
    margin: 90px 0;
    margin-left: 0;
  }

  .contact-form > * {
    width: 100%;
  }

  /* Office Info */

  .first-container {
    background: linear-gradient(45deg, rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url("https://colorlib.com/etc/cf/ContactFrom_v17/images/bg-01.jpg") center center / cover no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px; /* Adjusted height for smaller screens */
  }

  /* Office Info */

  .info-container div {
    margin: 12px 0;
  }

  /* Office Info headings */

  .info-container h3 {
    color: #fff;
    font-size: 20px; /* Smaller font size for mobile */
    font-weight: 800;
    line-height: 1.2;
    padding-bottom: 8px;
  }

  /* Office Info suggestions */

  .info-container p {
    font-size: 16px; /* Smaller font size for mobile */
    line-height: 1.4;
    color: #00ad5f;
  }

  /* Contact form layout for mobile */

  .second-container {
    padding: 20px; /* Reduced padding for mobile */
  }

  /* Contact form headings */

  .second-container h2 {
    font-size: 24px; /* Smaller font size for mobile */
    font-weight: 400;
    color: #333;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 15px; /* Reduced margin for mobile */
  }

  /* Contact form input fields */

  .form-group {
    margin-bottom: 15px; /* Increased margin for mobile */
  }

  /* Contact form input fields */

  .form-group * {
    min-height: 45px; /* Adjusted min-height for smaller screens */
    border: 1px solid #e6e6e6;
    padding: 0 15px; /* Adjusted padding for smaller screens */
  }

  /* Contact form labels */

  .form-group label {
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid #e6e6e6;
    font-size: 14px; /* Smaller font size for mobile */
    color: #333;
    text-transform: uppercase;
    margin-top: -1px;
  }

  /* Contact form input fields */

  .form-group input {
    width: 100%;
    font-size: 14px; /* Smaller font size for mobile */
    margin-top: -2px;
  }

  /* Contact form input fields placeholder */

  .form-group input::placeholder, 
  .form-group textarea::placeholder {
    color: #999;
  }

  /* Contact form textarea */

  .form-group textarea {
    width: 100%;
    min-height: 60px; /* Adjusted min-height for smaller screens */
    resize: none;
    padding: 8px 15px; /* Adjusted padding for smaller screens */
    margin-top: -1px;
  }

  /* Contact form submit button */

  button {
    width: 100%; /* Full width for mobile */
    height: 45px; /* Adjusted height for mobile */
    background: #00ad5f;
    color: #fff;
    font-size: 15px; /* Smaller font size for mobile */
    font-weight: 600;
    text-transform: uppercase;
    border: 0;
    position: relative;
    left: 0;
    cursor: pointer;
  }

  /* Contact form submit button hover */

  button:hover {
    background: #333;
  }

}
