
/* fault detection content */

.faultdetection{
    width: 80%;
    margin-left: 10%;
    margin-top: 5%; 
    /* margin-bottom: 5%; */
    margin-right: 10%;
}

/* fault detection heading */

.faultdetection h1{
    font-size: 28px;
    margin-bottom: 10px;
    text-align: center;
    color: #23458e;
}

/***** Animations *****/
@keyframes slide-right {
    from {
      margin-left: -100%;
    }
    to {
      margin-left: 0%;
    }
  }
  @keyframes slide-left {
    from {
      margin-left: 100%;
    }
    to {
      margin-left: 0%;
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .fade-in {
    animation: fadeIn ease 3s;
  }
  .slide-right {
    animation: 3s slide-right;
  }
  .slide-left {
    animation: 3s slide-left;
  }

  /* fault detection image */

.faultdetection img{
    width: 100%;
    height: auto;
}

/* fault detection para */

.faultdetection p{
    margin-top: 20px;
    font-size: 18px;
    /* margin-bottom: 10px; */
    text-align: justify;
    /* color: #23458e; */
}

/* sub-faultdetection */

.faultdetection .sub-faultdetection{
    /* margin-bottom: 10px; */
    margin-top: -10%;
}

/* sub-faultdetection heading */

.faultdetection .sub-faultdetection h1{
    font-size: 22px;
    text-align: left;
    margin-bottom: 10px;
    color: black;
}

/* sub-faultdetection para */

.faultdetection .sub-faultdetection p{
    font-size: 18px;
    text-align: justify;
    margin-bottom: 10px;
    margin-left: 80px;
    color: black;
}

/* sub-faultdetection para before bullets */

.faultdetection .sub-faultdetection p::before{
    content: '•';
    position: absolute;
    left: 12%;
    margin-right: 10px;
    color: black;
    font-size: 18px;
    font-weight: bold;
}

/* sub-faultdetection2 para */

.faultdetection .sub-faultdetection2 p{
    font-size: 18px;
    text-align: justify;
    margin-bottom: 10px;
    /* margin-left: 100px; */
    color: black;
}

/* sub-faultdetection3 para */

.faultdetection .sub-faultdetection3 p{
    font-size: 18px;
    text-align: justify;
    margin-bottom: 10px;
    /* margin-left: 100px; */
    color: black;
}

/* sub-faultdetection3 sub-faultdetection-para */

.faultdetection .sub-faultdetection3 .sub-faultdetection-para{
    margin-bottom: 10px;
    margin-left: 80px;
    color: black;
}

/* sub-faultdetection3 sub-faultdetection-para before bullets */

.faultdetection .sub-faultdetection3 .sub-faultdetection-para p::before{
    content: '•';
    position: absolute;
    left: 12%;
    margin-right: 10px;
    color: black;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}

/* Responsive CSS for tablet view (max-width: 768px) */
@media (max-width: 768px) {
  /* fault detection content */
    .faultdetection {
        width: 90%; /* Increase width for better use of screen space */
        margin-left: 5%; /* Adjust margins for smaller screen */
        margin-right: 5%;
        margin-top: 30%; 
        /* margin-bottom: 3%; */
    }

    /* fault detection heading */

    .faultdetection h1 {
        font-size: 24px; /* Reduce font size for better readability */
    }

    /* fault detection para */

    .faultdetection p {
        font-size: 16px; /* Reduce paragraph font size */
        margin-left: 0; /* Remove any unnecessary left margin */
    }

    .faultdetection .sub-faultdetection{
      /* margin-bottom: 10px; */
        margin-top: 0%;
    }

    /* sub-faultdetection heading */

    .faultdetection .sub-faultdetection h1 {
        font-size: 20px; /* Slightly smaller heading font size */
        text-align: left; /* Keep left alignment */
        margin-top:3%;
    }

    /* sub-faultdetection para */

    .faultdetection .sub-faultdetection p {
        font-size: 16px; /* Reduce font size */
        margin-left: 10px; /* Adjust left margin for smaller screen */
    }

    /* sub-faultdetection3 sub-faultdetection-para */

    .faultdetection .sub-faultdetection3 .sub-faultdetection-para {
        margin-left: 10px; /* Reduce left margin */
    }

    /* fault detection image */

    .faultdetection img {
        width: 100%; /* Keep image width to fill available space */
        height: auto; /* Maintain aspect ratio */
    }

    /* fault detection para with bullet points */

    /* Adjust bullet point positioning for smaller screens */
    .faultdetection .sub-faultdetection p::before,
    .faultdetection .sub-faultdetection3 .sub-faultdetection-para p::before {
        left: 5%; /* Move bullet points closer to the text */
    }

    /* Animation adjustments */
    .fade-in {
        animation: fadeIn ease 2s; /* Slightly faster animation for tablet */
    }
    .slide-right, .slide-left {
        animation: 2s; /* Faster slide animations for smaller screens */
    }
}

/* Responsive Styles for Mobile View (320px to 425px) */

@media (max-width: 425px) {
  .faultdetection {
      width: 90%; /* Increase width for smaller screens */
      margin-left: 5%; /* Adjust margins */
      margin-right: 5%;
      margin-top: 20%;
  }

  .faultdetection h1 {
      font-size: 24px; /* Smaller heading size */
  }

  .faultdetection p {
      font-size: 16px; /* Slightly smaller paragraph text */
      margin-top: 15px; /* Adjust margin for spacing */
  }

  .faultdetection img {
      width: 100%; /* Ensure images are responsive */
      height: auto; /* Maintain aspect ratio */
  }

  .faultdetection .sub-faultdetection {
      margin-top: 5%; /* Adjusted margin for better readability */
  }

  .faultdetection .sub-faultdetection h1 {
      font-size: 20px; /* Smaller subheading size */
  }

  .faultdetection .sub-faultdetection p {
      margin-left: 20px; /* Reset margin for better alignment */
  }

  .faultdetection .sub-faultdetection2 p,
  .faultdetection .sub-faultdetection3 p {
      font-size: 16px; /* Adjust font size for sub paragraphs */
  }

  .faultdetection .sub-faultdetection3 .sub-faultdetection-para {
      margin-left: 20px; /* Reset margin for better alignment */
  }

  .faultdetection .sub-faultdetection p::before,
  .faultdetection .sub-faultdetection3 .sub-faultdetection-para p::before {
      left: 5%; /* Adjust bullet position */
      font-size: 16px; /* Smaller bullet size */
  }
}


