
/* Terms and Conditions text */

.terms-and-conditions {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 2%;
    margin-bottom: 5%;
}

/* heading for all */

.terms-and-conditions h1 {
    font-size: 28px;
    margin-bottom: 10px;
    text-align: left;
    color: black;
}

/* terms and conditions para text */

.terms-and-conditions .terms-and-conditions-text p {
    font-size: 20px;
    margin-bottom: 10px;
    text-align: justify;
    color: black;
}

/* terms and conditions para text2 */

.terms-and-conditions .terms-and-conditions-text2 h1 {
    font-size: 28px;
    margin-bottom: 10px;
    text-align: left;
    margin-top: 1%;
    color: black;
}

.terms-and-conditions .terms-and-conditions-text2 p {
    font-size: 20px;
    margin-bottom: 10px;
    text-align: justify;
    color: black;
}

/* terms and conditions para text3 */

.terms-and-conditions .terms-and-conditions-text3 h1 {
    font-size: 28px;
    margin-bottom: 10px;
    text-align: left;
    margin-top: 1%;
    color: black;
}

.terms-and-conditions .terms-and-conditions-text3 p {
    font-size: 20px;
    margin-bottom: 10px;
    text-align: justify;
    color: black;
}

/* terms and conditions para text4 */

.terms-and-conditions .terms-and-conditions-text4 h1 {
    font-size: 28px;
    margin-bottom: 10px;
    text-align: left;
    margin-top: 1%;
    color: black;
}

.terms-and-conditions .terms-and-conditions-text4 p {
    font-size: 20px;
    margin-bottom: 10px;
    text-align: justify;
    color: black;
}

/* terms and conditions para text5 */

.terms-and-conditions .terms-and-conditions-text5 h1 {
    font-size: 28px;
    margin-bottom: 10px;
    text-align: left;
    margin-top: 1%;
    color: black;
}

.terms-and-conditions .terms-and-conditions-text5 p {
    font-size: 20px;
    margin-bottom: 10px;
    text-align: justify;
    color: black;
}

/* terms and conditions para text6 */

.terms-and-conditions .terms-and-conditions-text6 h1 {
    font-size: 28px;
    margin-bottom: 10px;
    text-align: left;
    margin-top: 1%;
    color: black;
}

.terms-and-conditions .terms-and-conditions-text6 p {
    font-size: 20px;
    margin-bottom: 10px;
    text-align: justify;
    color: black;
}

/* Tablet view 768px */
@media (max-width: 768px) {

    /* Terms and Conditions page */
    .terms-and-conditions {
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
        margin-top: 3%;
        margin-bottom: 6%;
    }

    /* heading for all */

    .terms-and-conditions h1 {
        font-size: 24px;
        margin-bottom: 12px;
        text-align: center; /* Center-align for better readability */
        color: black;
    }

    /* terms and conditions para text for all */

    .terms-and-conditions .terms-and-conditions-text p,
    .terms-and-conditions .terms-and-conditions-text2 p,
    .terms-and-conditions .terms-and-conditions-text3 p,
    .terms-and-conditions .terms-and-conditions-text4 p,
    .terms-and-conditions .terms-and-conditions-text5 p,
    .terms-and-conditions .terms-and-conditions-text6 p {
        font-size: 18px;
        margin-bottom: 12px;
        text-align: justify;
        color: black;
    }
}


