
/* python developer page */

.python-description-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin-left: 5%;
  margin-top: 6%;
}

/* python developer description */

.python-details {
  width: 60%;
  margin-right: 40px;
  text-align: justify;
}

/* python developer description heading */

.python-details h2 {
  font-size: 30px;
  margin-bottom: 20px;
  color: #23458e;
  text-align: left;
}

/* python developer description heading responsibilities and education */

.python-details h3 {
  font-size: 24px;
  margin-bottom: 20px;
}

/* python developer description content */

.python-details .python-description p{
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 10px;
}

/* python developer description content bullet points */

.python-details .python-description p:before{
  content: '• ';
  margin-right: 15px;
  font-size: 18px;
}

/* python developer additional description */

.python-description-info p {
  display: block;
  /* padding: 10px; */
  justify-content: space-between;
  margin-top: 20px;
}

/* python developer apply form */

.python-apply-form {
  width: 35%;
  height: 45%;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-right: 5%;
}

/* python developer apply form heading */

.python-apply-form h3 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  color: #23458e;
}

/* python developer apply form box */

.python-apply-form form {
  display: flex;
  flex-direction: column;
}

/* python developer apply form label */

.python-apply-form label {
  margin-bottom: 5px;
  font-weight: bold;
}

/* python developer apply form input and textarea */

.python-apply-form input,
.python-apply-form textarea {
  margin-bottom: 15px;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

/* python developer apply form button */

.python-apply-form button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

/* python developer apply form button hover */

.python-apply-form button:hover {
  background-color: #0056b3;
}

@media (max-width: 1263px) {
  /* Python description container */
  .python-description-container {
    flex-direction: row; /* Stack the elements vertically */
    align-items: center; /* Center align items */
    margin-left: 5%; /* Remove left margin */
    margin-right: 5%;
    margin-top: -2%;
  }

  /* Python developer description */
  .python-details {
    width: 55%; /* Take full width */
    margin-right: 0; /* Remove right margin */
  }

  /* Python apply form */
  .python-apply-form {
    width: 40%; /* Adjust to 90% width for smaller screens */
    height: auto; /* Allow height to adjust automatically */
    margin-right: 0; /* Remove right margin */
    margin-top: -30%;
  }

  /* Python apply form heading */
  .python-apply-form h3 {
    font-size: 20px; /* Decrease font size for heading */
  }

  /* Python developer apply form input and textarea */
  .python-apply-form input,
  .python-apply-form textarea {
    font-size: 12px; /* Decrease font size for inputs */
  }

  /* Python developer apply form button */
  .python-apply-form button {
    font-size: 14px; /* Decrease font size for button */
  }
}


/* Responsive styles for tablet view (768px) */

@media (max-width: 768px) {
  /* General container adjustments */
  .python-description-container {
    flex-direction: column;
    padding: 10px;
    margin-top: 18%;
    margin-left: 0;
  }

  /* Adjustments to maintain layout description and apply form */

  .python-details,
  .python-apply-form {
    width: 100%;
    margin-right: 0;
  }

  /* container details */

  .python-details {
    margin-bottom: 20px;
  }

  /* container apply form */

  .python-apply-form {
    height: auto;
    border: 1px solid #ddd;
  }

  /* Font sizes and spacings for tablet view */

  /* python container heading */
  .python-details h2 {
    font-size: 26px;
  }

  /* python container heading responsibilities and education */

  .python-details h3 {
    font-size: 20px;
  }

  /* python container content */

  .python-details .python-description p {
    font-size: 14px;
  }

  /* python apply form heading */

  .python-apply-form h3 {
    font-size: 20px;
  }

  /* python apply form input and textarea */

  .python-apply-form input,
  .python-apply-form textarea {
    font-size: 16px;
  }

  /* Adjustments to maintain layout and usability */
  /* python apply form button */
  .python-apply-form button {
    font-size: 14px;
  }

}
  