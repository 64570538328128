@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@200;300;400;500;600;700&display=swap");

:root {
  --width: 220px;
  --gap: 1rem;
  --speed: 500ms;
}

/* main container for slider page */

main {
  height: 110vh;
  /* margin-top: -1%; */
  background: #202227;
  position: relative;
  /* z-index: -1;  */
}

/* slider container */

.slider {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}


/* item container */

.item {
  display: flex;
  align-items: center;
  width: var(--width);
  height: calc(var(--width) * 1.5);
  position: absolute;
  z-index: 1;
  border-radius: 0.25rem;
  box-shadow: 0 12px 24px -6px rgba(0, 0, 0, 1);
  transition: left var(--speed), width var(--speed), height var(--speed),
    border-radius var(--speed), opacity var(--speed);
  transition-timing-function: ease-out;
  transform-origin: center;
  overflow: hidden;
  will-change: transform, opacity;
}

/* item shadow after hovering */

.item::after {
  content: "";
  display: block;
  position: absolute;
  inset: 0;
  z-index: 0;
  box-shadow: 0px 0px 50px 25px rgba(0, 0, 0, 0.25) inset;
  transition: box-shadow var(--speed);
  will-change: transform, opacity;
}

/* in item container with images  */

.item img {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.item:nth-child(1),
.item:nth-child(2) {
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0;
  box-shadow: none;
  opacity: 1;
  filter: blur(0);
}

.item:nth-child(3) {
  left: 50%;
}

.item:nth-child(4) {
  left: calc(50% + var(--width) + var(--gap));
  transition-delay: calc(var(--speed) * 0.2);
}

.item:nth-child(5) {
  left: calc(50% + calc(var(--width) * 2) + calc(var(--gap) * 2));
  transition-delay: calc(var(--speed) * 0.4);
}

.item:nth-child(6) {
  left: calc(50% + calc(var(--width) * 3) + calc(var(--gap) * 3));
  opacity: 0;
  transition-delay: calc(var(--speed) * 0.6);
}

/* hover effect with content */

.content {
  width: min(30vw, 400px);
  position: absolute;
  left: 3rem;
  z-index: 1;
  transform: translateY(25%);
  color: white;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.75);
  opacity: 0;
  display: none;
  flex-direction: column;
  /* gap: 1rem; */
  margin-top: -8%;
}

/* hover effect with content heading */

.content .title {
  font-weight: 800;
  font-size: 1.2rem;
  text-transform: uppercase;
  text-align: left;
  margin-bottom: 20px;
  line-height: 1.6; /* Adjust word spacing as per the content */
}


/* hover effect with content description */

.content .description {
  line-height: 1.5;
  font-size: 1.2rem;
  font-weight: 400;
  text-align: justify;
  /* width: 60vw; */
}

.content button {
  width: fit-content;
  background-color: rgba(255, 255, 255, 0.9);
  color: rgba(0, 0, 0, 0.9);
  border: 0;
  border-radius: 0.25rem;
  padding: 0.75rem;
  cursor: pointer;
  transition: background var(--speed), color var(--speed);
}

.content button:hover {
  background-color: rgba(0, 0, 0, 0.9);
  color: rgba(255, 255, 255, 0.9);
}

.item:nth-of-type(2) .content {
  display: flex;
  animation: show var(--speed) ease-in-out calc(var(--speed) / 2) forwards;
}

@keyframes show {
  0% {
    filter: blur(10px);
  }
  100% {
    opacity: 1;
    filter: blur(0);
    transform: translateY(0);
  }
}

/* in bottom prev and next icon with buttons */

.nav {
  /* top: -20%; */
  margin-bottom: 5%;
  display: flex;
  gap: 0.5rem;
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
  user-select: none;
  border-radius: 100vw;
  background-color: aliceblue;
  padding: 0.25rem;
}

/* styling for prev button */

.nav .prev {
  /* background-color: rgba(255, 255, 255, 0.9); */
  /* color: rgba(0, 0, 0, 0.9); */
  padding: 0.75rem;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  cursor: pointer;
  transition: background var(--speed), color var(--speed);
}

/* hover effect for prev button */

.nav .prev:hover {
  background-color: rgba(0, 0, 0, 0.9);
  color: rgba(255, 255, 255, 0.9);
}

/* styling for next button */

.nav .next {
  /* background-color: rgba(255, 255, 255, 0.9); */
  /* color: rgba(0, 0, 0, 0.9); */
  padding: 0.75rem;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  cursor: pointer;
  transition: background var(--speed), color var(--speed);
}

/* hover effect for next button */

.nav .next:hover {
  background-color: rgba(0, 0, 0, 0.9);
  color: rgba(255, 255, 255, 0.9);
}

/* Media query for laptop view */
@media (max-width: 1263px) {
  :root {
    --width: 180px; /* Adjust the width of the items */
    --gap: 0.75rem; /* Adjust the gap between items */
  }

  main {
    height: 80vh; /* Adjust height as necessary */
    padding: 2rem; /* Add padding for better spacing */
  }

  .slider {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .item {
    width: var(--width);
    height: calc(var(--width) * 1.5); /* Maintain aspect ratio */
    position: relative; /* Change to relative for stacking */
    margin: 0.5rem 0; /* Space items vertically */
    box-shadow: 0 8px 16px -4px rgba(0, 0, 0, 0.5); /* Reduce shadow */
  }

  .item img {
    object-fit: cover; /* Maintain image aspect ratio */
  }

  .content {
    width: 80%; /* Allow content to take up more space */
    left: auto; /* Reset left positioning */
    transform: translateY(0); /* Adjust translation */
    margin-top: 40px; /* Remove top margin */
  }

  .content .title {
    font-size: 1rem; /* Adjust font size */
    margin-top: 40%;
  }

  .content .description {
    font-size: 1rem; /* Adjust font size */
  }

  .nav {
    bottom: 1rem; /* Adjust position */
    padding: 0.5rem; /* Add padding */
  }

  .nav .prev,
  .nav .next {
    width: 2.5rem; /* Adjust button size */
    height: 2.5rem; /* Adjust button size */
  }
}



/* Responsive CSS for tablet view (max-width: 768px) */
@media (max-width: 768px) {
  :root {
    --width: 180px; /* Adjust width for tablet view */
    --gap: 0.8rem; /* Adjust gap for tablet view */
  }

  /* main container for slider page */

  main {
    height: 120vh;
    background: #202227;
  }

  /* slider container */
  
  .slider {
    position: relative;
    width: 100%;
    height: 120%;
    /* display: flex; */
    /* align-items: center; */
    flex-direction: column;
  }

  /* item container */

  .item {
    width: var(--width);
    height: calc(var(--width) * 1.3); /* Adjust height for tablet view */
    position: relative; /* Change to relative positioning */
    margin: 0.5rem auto; /* Add margin to separate items */
    box-shadow: 0 8px 16px -4px rgba(0, 0, 0, 0.8); /* Reduce box-shadow for a softer look */
  }

  /* in item container with images */

  .content {
    width: 250px;
    left: 5%;
    transform: translate(-50%, 10%); /* Center content on smaller screens */
    text-align: justify; /* Center-align text for better readability */
  }

  /* hover effect with content  heading */

  .content .title {
    margin-top: -50px;
    margin-bottom: 10px;
    /* line-height: 0.9rem; */
    font-size: 0.9rem; /* Reduce title font size for tablet view */
    font-weight: 900;
  }

  /* hover effect with content description */

  .content .description {
    font-size: 0.9rem; /* Reduce description font size */
    line-height: 0.9rem;
  }

  /* in bottom prev and next icon with buttons */

  .nav {
    bottom: 5.5rem; /* Adjust bottom position for navigation controls */
    gap: 0.25rem; /* Reduce gap between navigation buttons */
  }

  .nav .prev,
  .nav .next {
    width: 2.5rem; /* Reduce width and height for smaller buttons */
    height: 2.5rem;
    padding: 0.5rem; /* Adjust padding for buttons */
  }
}

