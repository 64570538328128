.profile-page{
    width: 100%;
}

.profile-page .title{
    font-size: 2rem;
    padding: 5px;
    text-decoration: underline;
}

.profile-page .subtitle{
    font-size: 1.3rem;
    padding: 5px;
    line-height: 0.9rem;
    margin-left: 0%;
    margin-top: 10px;
    color: #23458e;
    margin-bottom: 10px;
}

.profile-page .logout-button{
    height: 50px;
    background: #00ad5f;
    color: #fff;
    font-size: 17px;
    font-weight: 600;
    text-transform: uppercase;
    border: 0;
    position: relative;
    /* left: calc(50% - 100px); */
    cursor: pointer;
    top: 10px;
    margin-bottom: 12%;
}

.profile-page .logout-button:hover {
    background: #007f4e;
}
  

@media screen and (max-width: 768px) {

    .profile-page{
        width: 100%;
    }
    .profile-page .title .subtitle{
        font-size: 1.5rem;
    }

    .profile-page button{
        width: 50%;
        margin-left: 90px;
    }
    
}