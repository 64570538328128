*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
 font-family: Open-sans;
}

body{
  overflow-x: hidden;
}

.container{
  width: 100vw;
  min-height: auto;
  overflow-x: hidden;
  /* background: #ced9ff; */
}
