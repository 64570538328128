
/* Complete careers page */

.careers {
  margin-top: 8%;
}

.box {
  background: #f2f2f2;
  border-radius: 4px;
  padding: 1em 1em;
  margin: 2em 0;
  text-align: left;
  width: 80%;
  margin-left: 10%;
}

/* left column box heading styles */

.box-col  h4 {
  font-weight: 500;
  color: #07355C;
  display: inline-block;
  vertical-align: middle;
  font-size: 25px;
}

/* .box ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
} */

/* box function column */

.function {
  display: block;
  font-size: 18px;
}

.box-col {
  display: inline-block;
  vertical-align: middle;
}

/* right column */

.text-right {
  text-align: right;
}

.box-80 {
  width: 79%;
}

/* right column Apply button */

.box-20 {
  width: 20%;
}
/* Apply Updated button CSS */
.box-20 .button-apply {
  display: inline-block; /* Makes the span behave like a button */
  background-color: #23458e; /* Base background color */
  color: #fff; /* Text color */
  padding: 0.5em 1em; /* Padding for button size */
  border-radius: 4px; /* Rounded corners */
  text-decoration: none; /* Remove underline */
  font-size: 16px; /* Font size */
  font-weight: bold; /* Make the text bold */
  cursor: pointer; /* Change cursor to pointer on hover */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Adds depth with a shadow */
  transition: background-color 0.3s, box-shadow 0.3s; /* Smooth transition for hover effects */
  text-align: center; /* Center the text inside the button */
  user-select: none; /* Prevents text selection */
}

/* Apply Updated button Hover and active states */
.box-20 .button-apply:hover {
  background-color: #23458e; /* Darker color on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Enlarged shadow on hover */
  text-decoration: none;
}


@media (max-width: 1263px) {
  .careers {
    margin-top: 10%; /* Adjust margin for smaller screens */
  }

  .box {
    width: 90%; /* Make boxes wider for smaller screens */
    margin-left: 5%; /* Center boxes on smaller screens */
  }

  .box-col h4 {
    font-size: 22px; /* Reduce font size */
  }

  .function {
    font-size: 16px; /* Adjust function font size */
  }

  .box-80 {
    width: 75%; /* Adjust width of left column */
  }

  .box-20 {
    width: 25%; /* Adjust width of right column */
  }

  .box-20 .button-apply {
    /* padding: 0.4em 0.8em; Smaller padding for buttons */
    font-size: 14px; /* Reduce font size for buttons */
    margin-left: 350%;
    margin-top: -1000%;
  }
}
        

/* Responsive for laptop Screen */

/* Media Query for Laptop view with max-width 1024px */
@media screen and (max-width: 1024px) {

  /* Adjust the careers section margin for laptop view */
  .careers {
    margin-top: 5%; /* Reduce top margin for laptop */
  }

  /* Reduce the box width and center it properly for smaller screens */
  .box {
    width: 90%; /* Increase the width to take more screen space */
    margin-left: 5%; /* Adjust left margin to center the box */
    padding: 1em 1em; /* Maintain padding for consistency */
  }

  /* Adjust heading font size for laptop view */
  .box-col h4 {
    font-size: 22px; /* Slightly smaller font size */
  }

  /* Adjust function column font size */
  .function {
    font-size: 16px; /* Smaller text size for laptop */
  }

  /* Adjust box column layout for laptop view */
  .box-col {
    display: block; /* Stack columns vertically */
    width: 100%; /* Full width on small screens */
    margin-bottom: 1em; /* Add margin to separate stacked columns */
  }

  /* Align the right column's text for laptop view */
  .text-right {
    text-align: left; /* Align text to the left for better readability on smaller screens */
    margin-top: 1em; /* Add some margin to create space */
  }

  /* Adjust width for right and left columns */
  .box-80 {
    width: 100%; /* Full width for laptop */
    margin-bottom: 1em; /* Space between columns */
  }

  .box-20 {
    width: 100%; /* Full width for the apply button */
    text-align: center; /* Center align the button for better visibility */
    margin-top: 1em; /* Space between stacked elements */
  }

  /* Apply button responsiveness */
  .box-20 .button-apply {
    font-size: 14px; /* Reduce button font size */
    padding: 0.5em 1.5em; /* Adjust padding for button size */
    width: 50vw; /* Make button wider on smaller screens */
    margin: 0 auto; /* Center the button */
  }

}


/* Responsive for tablet Screen */

@media (max-width: 768px) {

  /* Base Styles for .box, .box-col, .text-right, and other classes */

  .careers {
    margin-top: 28%;
    margin-right: 5%;
  }

  .box {
    width: 90%; /* Increase width to better fit smaller screens */
    margin-left: 3%; /* Adjust margin for centering */
    padding: 1em; /* Adjust padding for better spacing */
    /* margin-top: 20%; */
  }

  /* left column box heading styles */

  .box-col {
    display: block; /* Stack columns vertically */
    width: 100%; /* Full width for each column */
    margin-bottom: 1em; /* Space between columns */
  }

  .box-col h4 {
    font-size: 20px; /* Adjust font size for smaller screens */
  }

  /* left column and right column */

  .box-80, .box-20 {
    width: 50%; /* Make each box take full width */
  }

  /* Apply button */

  .box-20 .button-apply {
    width: 20vw; /* Full width for the button */
    display: block; /* Stack button block-level */
    text-align: center; /* Center text inside the button */
    margin-left: 150%;
    margin-top: -15%;
  }
}





  

  