@import url(https://fonts.googleapis.com/css?family=Raleway);
/* services card styling */
.snip1584 {
  font-family: 'Raleway', sans-serif;
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin: 10px;
  /* padding-top: 100%; */
  min-width: 230px;
  max-width: 315px;
  width: 100%;
  color: #ffffff;
  font-size: 16px;
  text-align: left;
  margin-left: 10%;
  margin-top: 2%;
}
.snip1584 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

/* services before hovering */

.snip1584:before {
  position: absolute;
  top: 10px;
  bottom: 10px;
  left: 10px;
  right: 10px;
  top: 100%;
  content: '';
  background-color: rgba(51, 51, 51, 0.9);
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s;
}

/* services images */

.snip1584 img {
  vertical-align: top;
  max-width: 100%;
  backface-visibility: hidden;
}

/* services hover effect heading */

.snip1584 figcaption {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 10%;
  right: 0;
  z-index: 1;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.snip1584 h3,
.snip1584 h5 {
  margin: 0;
  opacity: 0;
  letter-spacing: 1px;
}
.snip1584 h3 {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  text-transform: uppercase;
  font-weight: 400;
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
  margin-bottom: 5px;
}
.snip1584 h5 {
  font-weight: normal;
  background-color: #ae895d;
  padding: 3px 10px;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}
.snip1584 a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.snip1584:hover:before,
.snip1584.hover:before {
  top: 10px;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}
.snip1584:hover h3,
.snip1584.hover h3,
.snip1584:hover h5,
.snip1584.hover h5 {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}
.snip1584:hover h3,
.snip1584.hover h3 {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
.snip1584:hover h5,
.snip1584.hover h5 {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

/* services heading styling */


div h1{
  text-align: center;
  margin-top: 180px;
  color: #23458e;
  font-size: 35px;
}

div h2{
  text-align: center;
  margin-top: 30px;
  color: #23458e;
  font-size: 35px;
}

/* Responsive styles for tablet view (max-width: 768px) */
@media screen and (max-width: 768px) {
  @import url(https://fonts.googleapis.com/css?family=Raleway);
  /* services card styling */
  .snip1584 {
    font-family: 'Raleway', sans-serif;
    position: relative;
    display: inline-block;
    overflow: hidden;
    margin: 10px;
    /* padding-top: 100%; */
    min-width: 230px;
    max-width: 315px;
    width: 100%;
    color: #ffffff;
    font-size: 16px;
    text-align: left;
    margin-left: 15%;
    margin-top: 2%;
  }
  .snip1584 * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }
  
  /* services before hovering */
  
  .snip1584:before {
    position: absolute;
    top: 10px;
    bottom: 10px;
    left: 10px;
    right: 10px;
    top: 100%;
    content: '';
    background-color: rgba(51, 51, 51, 0.9);
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
    -webkit-transition-delay: 0.25s;
    transition-delay: 0.25s;
  }
  
  /* services images */
  
  .snip1584 img {
    vertical-align: top;
    max-width: 100%;
    backface-visibility: hidden;
  }
  
  /* services hover effect heading */
  
  .snip1584 figcaption {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 10%;
    right: 0;
    z-index: 1;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .snip1584 h3,
  .snip1584 h5 {
    margin: 0;
    opacity: 0;
    letter-spacing: 1px;
  }
  .snip1584 h3 {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    text-transform: uppercase;
    font-weight: 400;
    -webkit-transition-delay: 0.05s;
    transition-delay: 0.05s;
    margin-bottom: 5px;
  }
  .snip1584 h5 {
    font-weight: normal;
    background-color: #ae895d;
    padding: 3px 10px;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
  }
  .snip1584 a {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
  .snip1584:hover:before,
  .snip1584.hover:before {
    top: 10px;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
  }
  .snip1584:hover h3,
  .snip1584.hover h3,
  .snip1584:hover h5,
  .snip1584.hover h5 {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  .snip1584:hover h3,
  .snip1584.hover h3 {
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
  }
  .snip1584:hover h5,
  .snip1584.hover h5 {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
  }
  
  /* services heading styling */
  
  
  div h1{
    text-align: center;
    margin-top: 180px;
    color: #23458e;
    font-size: 35px;
  }
  
  div h2{
    text-align: center;
    margin-top: 30px;
    color: #23458e;
    font-size: 35px;
  }
}

@media screen and (max-width: 425px) and (min-width: 320px) {
  /* Adjust the services card styling for mobile */
  .snip1584 {
    margin-left: 23%; /* Reduced margin for smaller screens */
    margin-top: 5%; /* Increase top margin */
    min-width: 200px; /* Make the card width responsive */
    max-width: 230px; /* Max width to fill screen */
    font-size: 14px; /* Slightly smaller font size */
    /* margin-left: 5%; */
  }

  /* Ensure the images and figcaption content are sized properly */
  .snip1584 img {
    max-width: 100%; /* Make images fit the card */
  }

  .snip1584 figcaption {
    left: 0; /* Make the content centered in smaller screens */
  }

  /* Adjust heading font size */
  .snip1584 h3 {
    font-size: 18px; /* Smaller heading */
    margin-left: 15px;
  }

  .snip1584 h5 {
    font-size: 14px; /* Smaller subheading */
    padding: 2px 8px; /* Adjust padding */
  }

  /* Adjust overall page headings */
  div h1 {
    margin-top: 100px; /* Reduce top margin for smaller screens */
    font-size: 28px; /* Decrease font size */
  }

  div h2 {
    margin-top: 20px; /* Adjust margin for proper spacing */
    font-size: 28px; /* Decrease font size */
  }
}

