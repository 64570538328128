
/* Global Variables for styling colors */

:root {
    --card-background-color: #fff;
    --text-color: #111;
    --card-transition: 0.2s ease-in-out;
}

/* container for product cards */

.container-products {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 60px;
    padding: 30px;
    margin-top: 5%;
    margin-bottom: 5%;
    /* max-width: 1200px; */
}

/* card styling for product cards */

.card-products {
    max-width: 300px;
    height: 280px;
    background-color: var(--card-background-color);
    margin: 50px 10px 50px 10px;
    padding: 20px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
    transition: var(--card-transition);
    overflow: hidden;
}

/* hover effect for product cards */

.card-products:hover {
    height: auto;
}

/* content styling for product cards */

.card-products:hover .content-products {
    visibility: visible;
    opacity: 1;
    transition-delay: 0.1s;
}

/* image styling for product cards */

.image-wrapper {
    position: relative;
    width: 270px;
    height: 200px;
    top: 0;
    z-index: 1;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
    left: -5px;
}

/* image styling for product cards */

.image-wrapper img {
    max-width: 100%;
    height: 230px;
    border-radius: 4px;
    left: -20px;
}

/* content styling for product cards */

.content-products {
    position: relative;
    padding: 8px 8px;
    margin-top: 30px;
    text-align: justify;
    color: var(--text-color);
    visibility: hidden;
    opacity: 0;
    font-size: 16px;
    transition: var(--card-transition);
}

.content-products h2{
    font-size: 22px;
    text-align: left;
    margin-bottom: 10px;
}

/* learn more styling for product cards */

.content-products .learn-more {
    position: absolute;
    color: #111;
    font-weight: 900;
    text-decoration: underline;
}

/* Responsive styling for tablet view (max-width 768px) */
@media (max-width: 768px) {
    /* Adjust container padding and gap */
    .container-products {
        flex-direction: column;
        padding: 20px;
        gap: 30px;
        margin-top: 35%;
        margin-bottom: 10%;
    }

    /* Adjust card dimensions */
    .card-products {
        max-width: 300px;
        height: 280px;
        background-color: var(--card-background-color);
        margin: 5px 10px 50px 10px;
        padding: 20px;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
        transition: var(--card-transition);
        overflow: hidden;
    }
    
    /* hover effect for product cards */
    
    .card-products:hover {
        height: auto;
    }
    
    /* content styling for product cards */
    
    .card-products:hover .content-products {
        visibility: visible;
        opacity: 1;
        transition-delay: 0.2s;
    }

    /* Adjust image wrapper dimensions */
    .image-wrapper {
        width: 100%;
        /* height: auto; */
        box-shadow: none;
        left: 0;
    }

    /* Adjust image styling */
    .image-wrapper img {
        width: 100%;
        /* height: auto; */
        border-radius: 4px;
    }

    /* Adjust content padding and font size */
    .content-products {
        padding: 10px;
        /* margin-top: 20px; */
        font-size: 14px;
    }

    /* Adjust learn-more link font size */
    .content-products .learn-more {
        font-size: 14px;
    }
}

@media (max-width: 375px) and (min-width: 320px) {
    /* Styling for smaller screens product container */
    .container-products {
        flex-direction: column;
        align-items: center;
        gap: 20px; /* Reduce the gap between product cards */
        padding: 15px; /* Adjust padding for smaller screens */
    }

    /* Styling for smaller screens product cards */

    .card-products {
        max-width: 90%; /* Ensure cards take up most of the screen width */
        height: 190px; /* Allow cards to expand based on content */
        margin: 20px 0; /* Reduce the margin for smaller viewports */
        padding: 15px; /* Adjust padding inside the card */
        box-shadow: 0 3px 15px rgba(0, 0, 0, 0.3); /* Soften shadow for mobile */
    }

    /* Styling for smaller screens image */

    .image-wrapper {
        width: 100%; /* Image should take full card width */
        height: auto; /* Maintain aspect ratio */
        left: 0;
        box-shadow: 0 3px 15px rgba(0, 0, 0, 0.2); /* Adjust box shadow */
    }

    /* Styling for smaller screens image */

    .image-wrapper img {
        width: 100%; /* Ensure the image fits within the card */
        height: auto; /* Adjust height to maintain image ratio */
        border-radius: 4px; /* Maintain rounded corners */
    }

    /* Styling for smaller screens content */

    .content-products {
        margin-top: 20px; /* Reduce margin between image and content */
        font-size: 14px; /* Smaller font size for mobile readability */
    }

    /* Styling for smaller screens content learn more */

    .content-products .learn-more {
        font-size: 14px; /* Adjust size for the learn more link */
    }
}

