/* Industry services page content */

.industry_content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin-top: 8%;
}

/* main heading industry */

.industry_content h5{
    font-size: 3.5rem;
    text-align: center;
    align-items: center;
    padding: 10px;
    color: #2348E2;
}

/* para content */

.industry_para_content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.industry_para_content p{
    font-size: 22px;
    text-align: justify;
    align-items: center;
    padding: 10px;
    /* color: #2348E2; */
    width: 70vw;
    margin-left: 75px;
    padding: 20px;
}

/* main image content below the heading */

.industry_para_content .img1{
    width: 70vw;
    height: 85vh;
    margin-left: 65px;
}

/* benefits content */

.industry_benefits{
    position: relative;
    width: 80%;
    margin: 0 auto;
    padding-top: 10px;
    margin-top: 10px;
    margin-bottom: 40px;
}

/* benefits heading */

.industry_benefits h5{
    font-size: 35px;
    text-align: justify;
    align-items: center;
    /* padding: 10px; */
    color: #2348E2;
    width: 50vw;
    margin-left: 7%;
    /* padding: 20px; */
}

/* benefits image */

.industry_benefits .img2{
    float: right;
    width: 35vw;
    margin-top: -25%;
    /* height: 45vh; */
    margin-left: 65px;
}

.industry_benefits .benefits{
    font-size: 20px;
    /* text-align: justify; */
    align-items: center;
    /* padding: 10px; */
    /* color: #2348E2; */
    width: 30vw;
    margin-left: 105px;
    /* margin-left: 25px;
    padding: 20px; */
}

.industry_benefits .benefits p{
    position: relative; /* For positioning the pseudo-element */
    padding-left: 5px; /* Space for the bullet */
    margin: 5px 0; /* Add margin between paragraphs */
    /* color: #2348E2; */
    text-align: justify;
    width: 30vw;
    font-size: 20px;
}

.industry_benefits .benefits p::before {
    content: '•'; /* Bullet character */
    position: absolute;
    left: -20px;
    /* color: #2348E2;  */
    font-size: 24px; /* Bullet size */
    line-height: 1; /* Adjust bullet alignment */
}

/* industry applications */

.industry_applications{
    position: relative;
    width: 80%;
    padding-top: 10px;
    margin-top: 30px;
}

/* industry applications heading */

.industry_applications h5{
    font-size: 35px;
    text-align: justify;
    align-items: center;
    padding: 10px;
    color: #2348E2;
    float: right;
    margin-right: 15%;
    /* width: 50vw; */
    /* margin-left: 25px;
    padding: 20px; */
}

/* industry applications image */

.industry_applications .img3{
    float: left;
    width: 25vw;
    /* margin-left: 20px; */
    margin-bottom: 30px;
    margin-top: -25%;
    margin-left: 120px;
}

.industry_applications .applications{
    font-size: 20px;
    /* text-align: justify; */
    align-items: center;
    /* padding: 10px; */
    /* color: #2348E2; */
    width: 30vw;
    margin-top: 60px;
    margin-left: 35%;
    /* margin-left: 25px;
    padding: 20px; */
}

.industry_applications .applications p{
    position: relative; /* For positioning the pseudo-element */
    padding-left: 5px; /* Space for the bullet */
    /* color: #2348E2; */
    text-align: justify;
    font-size: 20px;
    width: 30vw;
    margin-left: 50%;
    line-height: 1;
    padding: 5px;
}

.industry_applications .applications p::before {
    content: '•'; /* Bullet character */
    position: absolute; /* For positioning the pseudo-element */
    left: -20px;
    /* color: #2348E2;  */
    font-size: 24px; /* Bullet size */
    line-height: 1; /* Adjust bullet alignment */
}

/* industry down image */

.industry_down_image .img4{
    width: 80vw;
    margin-top: 40px;
}

/* General styles for desktop view (max-width: 1920px) */
@media screen and (max-width: 1920px) {
    /* Industry services page content */

    .industry_content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
        margin-top: 8%;
    }

    /* main heading industry */

    .industry_content h5{
        font-size: 3.5rem;
        text-align: center;
        align-items: center;
        padding: 10px;
        color: #2348E2;
    }

    /* para content */

    .industry_para_content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px;
    }

    .industry_para_content p{
        font-size: 22px;
        text-align: justify;
        align-items: center;
        padding: 10px;
        /* color: #2348E2; */
        width: 70vw;
        margin-left: 75px;
        padding: 20px;
    }

    /* main image content below the heading */

    .industry_para_content .img1{
        width: 70vw;
        height: 85vh;
        margin-left: 65px;
    }

    /* benefits content */

    .industry_benefits{
        position: relative;
        width: 80%;
        margin: 0 auto;
        padding-top: 10px;
        margin-top: 10px;
        margin-bottom: 40px;
    }

    /* benefits heading */

    .industry_benefits h5{
        font-size: 35px;
        text-align: justify;
        align-items: center;
        /* padding: 10px; */
        color: #2348E2;
        width: 50vw;
        margin-left: 18%;
        /* padding: 20px; */
    }

    /* benefits image */

    .industry_benefits .img2{
        float: right;
        width: 25vw;
        margin-top: -20%;
        /* height: 45vh; */
        margin-right: 15%;
    }

    .industry_benefits .benefits{
        font-size: 20px;
        /* text-align: justify; */
        align-items: center;
        /* padding: 10px; */
        /* color: #2348E2; */
        width: 20vw;
        margin-left: 20%;
        /* margin-left: 25px;
        padding: 20px; */
    }

    .industry_benefits .benefits p{
        position: relative; /* For positioning the pseudo-element */
        padding-left: 5px; /* Space for the bullet */
        margin: 5px 0; /* Add margin between paragraphs */
        /* color: #2348E2; */
        text-align: justify;
        width: 20vw;
        font-size: 20px;
    }

    .industry_benefits .benefits p::before {
        content: '•'; /* Bullet character */
        position: absolute;
        left: -20px;
        /* color: #2348E2;  */
        font-size: 24px; /* Bullet size */
        line-height: 1; /* Adjust bullet alignment */
    }

    /* industry applications */

    .industry_applications{
        position: relative;
        width: 80%;
        padding-top: 10px;
        margin-top: 30px;
    }

    /* industry applications heading */

    .industry_applications h5{
        font-size: 35px;
        text-align: justify;
        align-items: center;
        padding: 10px;
        color: #2348E2;
        float: right;
        margin-right: 15%;
        /* width: 50vw; */
        /* margin-left: 25px;
        padding: 20px; */
    }

    /* industry applications image */

    .industry_applications .img3{
        float: left;
        width: 20vw;
        /* margin-left: 20px; */
        margin-bottom: 30px;
        margin-top: -20%;
        margin-left: 18%;
    }

    .industry_applications .applications{
        font-size: 20px;
        /* text-align: justify; */
        align-items: center;
        /* padding: 10px; */
        /* color: #2348E2; */
        width: 20vw;
        margin-top: 60px;
        margin-left: 35%;
        /* margin-left: 25px;
        padding: 20px; */
    }

    .industry_applications .applications p{
        position: relative; /* For positioning the pseudo-element */
        padding-left: 5px; /* Space for the bullet */
        /* color: #2348E2; */
        text-align: justify;
        font-size: 20px;
        width: 20vw;
        margin-left: 95%;
        line-height: 1;
        padding: 5px;
    }

    .industry_applications .applications p::before {
        content: '•'; /* Bullet character */
        position: absolute; /* For positioning the pseudo-element */
        left: -20px;
        /* color: #2348E2;  */
        font-size: 24px; /* Bullet size */
        line-height: 1; /* Adjust bullet alignment */
    }

    /* industry down image */

    .industry_down_image .img4{
        width: 80vw;
        margin-top: 40px;
    }
    
}

@media screen and (max-width: 1536px) {
    /* Industry services page content */

    .industry_content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
        margin-top: 8%;
    }

    /* main heading industry */

    .industry_content h5{
        font-size: 3.5rem;
        text-align: center;
        align-items: center;
        padding: 10px;
        color: #2348E2;
    }

    /* para content */

    .industry_para_content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px;
    }

    .industry_para_content p{
        font-size: 22px;
        text-align: justify;
        align-items: center;
        padding: 10px;
        /* color: #2348E2; */
        width: 70vw;
        margin-left: 75px;
        padding: 20px;
    }

    /* main image content below the heading */

    .industry_para_content .img1{
        width: 70vw;
        height: 85vh;
        margin-left: 65px;
    }

    /* benefits content */

    .industry_benefits{
        position: relative;
        width: 80%;
        margin: 0 auto;
        padding-top: 10px;
        margin-top: 10px;
        margin-bottom: 40px;
    }

    /* benefits heading */

    .industry_benefits h5{
        font-size: 35px;
        text-align: justify;
        align-items: center;
        /* padding: 10px; */
        color: #2348E2;
        width: 50vw;
        margin-left: 19%;
        /* padding: 20px; */
    }

    /* benefits image */

    .industry_benefits .img2{
        float: none;
        width: 25vw;
        margin-top: -40%;
        /* height: 45vh; */
        margin-left: 58%;
    }

    .industry_benefits .benefits{
        font-size: 20px;
        /* text-align: justify; */
        align-items: center;
        /* padding: 10px; */
        /* color: #2348E2; */
        width: 25vw;
        margin-left: 20%;
        /* margin-left: 25px;
        padding: 20px; */
    }

    .industry_benefits .benefits p{
        position: relative; /* For positioning the pseudo-element */
        padding-left: 5px; /* Space for the bullet */
        margin: 5px 0; /* Add margin between paragraphs */
        /* color: #2348E2; */
        text-align: justify;
        width: 25vw;
        font-size: 20px;
    }

    .industry_benefits .benefits p::before {
        content: '•'; /* Bullet character */
        position: absolute;
        left: -20px;
        /* color: #2348E2;  */
        font-size: 24px; /* Bullet size */
        line-height: 1; /* Adjust bullet alignment */
    }

    /* industry applications */

    .industry_applications{
        position: relative;
        width: 80%;
        padding-top: 10px;
        margin-top: 30px;
    }

    /* industry applications heading */

    .industry_applications h5{
        font-size: 35px;
        text-align: justify;
        align-items: center;
        padding: 10px;
        color: #2348E2;
        float: right;
        margin-right: 10%;
        /* width: 50vw; */
        /* margin-left: 25px;
        padding: 20px; */
    }

    /* industry applications image */

    .industry_applications .img3{
        float: left;
        width: 25vw;
        /* margin-left: 20px; */
        margin-bottom: 30px;
        margin-top: -30%;
        margin-left: 15%;
    }

    .industry_applications .applications{
        font-size: 20px;
        /* text-align: justify; */
        align-items: center;
        /* padding: 10px; */
        /* color: #2348E2; */
        width: 25vw;
        margin-top: 60px;
        margin-left: 40%;
        /* margin-left: 25px;
        padding: 20px; */
    }

    .industry_applications .applications p{
        position: relative; /* For positioning the pseudo-element */
        padding-left: 5px; /* Space for the bullet */
        /* color: #2348E2; */
        text-align: justify;
        font-size: 20px;
        width: 25vw;
        margin-left: 50%;
        line-height: 1;
        padding: 5px;
    }

    .industry_applications .applications p::before {
        content: '•'; /* Bullet character */
        position: absolute; /* For positioning the pseudo-element */
        left: -20px;
        /* color: #2348E2;  */
        font-size: 24px; /* Bullet size */
        line-height: 1; /* Adjust bullet alignment */
    }

    /* industry down image */

    .industry_down_image .img4{
        width: 80vw;
        margin-top: 40px;
    }
    
}

@media screen and (max-width: 1263px) {
    /* Industry services page content */

    .industry_content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
        margin-top: 8%;
    }

    /* main heading industry */

    .industry_content h5{
        font-size: 3.5rem;
        text-align: center;
        align-items: center;
        padding: 10px;
        color: #2348E2;
    }

    /* para content */

    .industry_para_content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px;
    }

    .industry_para_content p{
        font-size: 22px;
        text-align: justify;
        align-items: center;
        padding: 10px;
        /* color: #2348E2; */
        width: 70vw;
        margin-left: 75px;
        padding: 20px;
    }

    /* main image content below the heading */

    .industry_para_content .img1{
        width: 70vw;
        height: 85vh;
        margin-left: 65px;
    }

    /* benefits content */

    .industry_benefits{
        position: relative;
        width: 80%;
        margin: 0 auto;
        padding-top: 10px;
        margin-top: 10px;
        margin-bottom: 40px;
    }

    /* benefits heading */

    .industry_benefits h5{
        font-size: 35px;
        text-align: justify;
        align-items: center;
        /* padding: 10px; */
        color: #2348E2;
        width: 50vw;
        margin-left: 7%;
        /* padding: 20px; */
    }

    /* benefits image */

    .industry_benefits .img2{
        float: right;
        width: 25vw;
        margin-top: -25%;
        /* height: 45vh; */
        margin-left: 65px;
    }

    .industry_benefits .benefits{
        font-size: 20px;
        /* text-align: justify; */
        align-items: center;
        /* padding: 10px; */
        /* color: #2348E2; */
        width: 30vw;
        margin-left: 105px;
        /* margin-left: 25px;
        padding: 20px; */
    }

    .industry_benefits .benefits p{
        position: relative; /* For positioning the pseudo-element */
        padding-left: 5px; /* Space for the bullet */
        margin: 5px 0; /* Add margin between paragraphs */
        /* color: #2348E2; */
        text-align: justify;
        width: 30vw;
        font-size: 20px;
    }

    .industry_benefits .benefits p::before {
        content: '•'; /* Bullet character */
        position: absolute;
        left: -20px;
        /* color: #2348E2;  */
        font-size: 24px; /* Bullet size */
        line-height: 1; /* Adjust bullet alignment */
    }

    /* industry applications */

    .industry_applications{
        position: relative;
        width: 80%;
        padding-top: 10px;
        margin-top: 30px;
    }

    /* industry applications heading */

    .industry_applications h5{
        font-size: 35px;
        text-align: justify;
        align-items: center;
        padding: 10px;
        color: #2348E2;
        float: right;
        margin-right: 15%;
        /* width: 50vw; */
        /* margin-left: 25px;
        padding: 20px; */
    }

    /* industry applications image */

    .industry_applications .img3{
        float: left;
        width: 25vw;
        /* margin-left: 20px; */
        margin-bottom: 30px;
        margin-top: -25%;
        margin-left: 120px;
    }

    .industry_applications .applications{
        font-size: 20px;
        /* text-align: justify; */
        align-items: center;
        /* padding: 10px; */
        /* color: #2348E2; */
        width: 30vw;
        margin-top: 60px;
        margin-left: 35%;
        /* margin-left: 25px;
        padding: 20px; */
    }

    .industry_applications .applications p{
        position: relative; /* For positioning the pseudo-element */
        padding-left: 5px; /* Space for the bullet */
        /* color: #2348E2; */
        text-align: justify;
        font-size: 20px;
        width: 30vw;
        margin-left: 50%;
        line-height: 1;
        padding: 5px;
    }

    .industry_applications .applications p::before {
        content: '•'; /* Bullet character */
        position: absolute; /* For positioning the pseudo-element */
        left: -20px;
        /* color: #2348E2;  */
        font-size: 24px; /* Bullet size */
        line-height: 1; /* Adjust bullet alignment */
    }

    /* industry down image */

    .industry_down_image .img4{
        width: 80vw;
        margin-top: 40px;
    }
    
}

/* Responsive CSS for tablet view (max-width: 768px) */

@media (max-width: 768px) {

    /* Industry Content */
    .industry_content {
        padding: 10px;
        margin-top: 25%;
    }

    .industry_content h5 {
        font-size: 2.5rem;
        padding: 5px;
    }

    /* Para Content */
    .industry_para_content {
        padding: 5px;
    }

    .industry_para_content p {
        font-size: 18px;
        width: 90vw;
        margin-left: 0;
        padding: 10px;
    }

    .industry_para_content .img1 {
        width: 90vw;
        height: auto; /* Adjust height to auto for responsiveness */
        margin-left: 0;
    }

    /* Benefits Content */
    .industry_benefits {
        width: 90%;
        margin: 0 auto;
        padding-top: 5px;
        margin-top: 20px;
        margin-bottom: 30px;
    }

    .industry_benefits h5 {
        font-size: 28px;
        margin-left: 0;
    }

    .industry_benefits .img2 {
        float: none;
        width: 80vw;
        margin-top: 10px;
        margin-left: 0;
    }

    .industry_benefits .benefits {
        width: 90vw;
        margin-left: 0;
    }

    .industry_benefits .benefits p {
        font-size: 18px;
        width: 90vw;
        margin-left: 0;
    }

    /* Industry Applications */
    .industry_applications {
        width: 90%;
        padding-top: 5px;
        margin-top: 20px;
    }

    .industry_applications h5 {
        font-size: 28px;
        margin-right: 50%;
        margin-left: 0;
    }

    .industry_applications .img3 {
        float: none;
        width: 80vw;
        margin-left: 0;
        margin-top: 10px;
    }

    .industry_applications .applications {
        width: 90vw;
        margin-left: 0;
    }

    .industry_applications .applications p {
        font-size: 18px;
        width: 90vw;
        margin-left: 0;
    }

    /* Industry Down Image */
    .industry_down_image .img4 {
        width: 90vw;
        margin-left: -15px;
    }
}

/* Responsive CSS for mobile view */

@media (max-width: 429px) {
    /* Industry services page content */
    .industry_content {
        padding: 10px;
        margin-top: 20%;
    }

    /* main heading industry */
    .industry_content h5 {
        font-size: 2.5rem;
        padding: 5px;
    }

    /* para content */
    .industry_para_content {
        padding: 5px;
    }

    .industry_para_content p {
        font-size: 16px;
        width: 90vw; /* Adjusted width for mobile view */
        margin-left: 0; /* Removed margin for mobile view */
        padding: 10px;
    }

    /* main image content below the heading */
    .industry_para_content .img1 {
        width: 90vw; /* Adjusted width for mobile view */
        height: auto; /* Adjusted height to maintain aspect ratio */
        margin-left: 0; /* Removed margin for mobile view */
    }

    /* benefits content */
    .industry_benefits {
        width: 90%;
        padding-top: 5px;
    }

    /* benefits heading */
    .industry_benefits h5 {
        font-size: 25px;
        margin-left: 0; /* Removed margin for mobile view */
        width: 90vw; /* Adjusted width for mobile view */
    }

    /* benefits image */
    .industry_benefits .img2 {
        float: none; /* Removed float */
        width: 90vw; /* Adjusted width for mobile view */
        margin-top: 0; /* Removed top margin for mobile view */
        margin-left: 0; /* Removed margin for mobile view */
    }

    /* benefits text */
    .industry_benefits .benefits {
        width: 90vw; /* Adjusted width for mobile view */
        margin-left: 0; /* Removed margin for mobile view */
    }

    .industry_benefits .benefits p {
        width: 90vw; /* Adjusted width for mobile view */
        margin-left: 0; /* Removed margin for mobile view */
    }

    /* industry applications */
    .industry_applications {
        width: 90%;
        padding-top: 5px;
    }

    /* industry applications heading */
    .industry_applications h5 {
        font-size: 25px;
        margin-right: 0; /* Removed margin for mobile view */
        width: 90vw; /* Adjusted width for mobile view */
    }

    /* industry applications image */
    .industry_applications .img3 {
        float: none; /* Removed float */
        width: 90vw; /* Adjusted width for mobile view */
        margin-left: 0; /* Removed margin for mobile view */
        margin-top: 20px; /* Adjusted margin for mobile view */
    }

    /* industry applications text */
    .industry_applications .applications {
        width: 90vw; /* Adjusted width for mobile view */
        margin-left: 0; /* Removed margin for mobile view */
        margin-top: 20px; /* Adjusted margin for mobile view */
    }

    .industry_applications .applications p {
        width: 90vw; /* Adjusted width for mobile view */
        margin-left: 0; /* Removed margin for mobile view */
    }

    /* industry down image */
    .industry_down_image .img4 {
        width: 90vw; /* Adjusted width for mobile view */
        margin-top: 20px; /* Adjusted margin for mobile view */
    }

}

/* Mobile view adjustments for max-width 425px */

@media (max-width: 425px) {
    /* Industry content */
    .industry_content {
        padding: 10px;
        margin-top: 20%;
    }

    /* Main heading */
    .industry_content h5 {
        font-size: 2rem; /* Reduced font size */
        padding: 5px;
    }

    /* Paragraph content */
    .industry_para_content p {
        font-size: 16px; /* Reduced font size */
        width: 90vw; /* Adjusted width for mobile */
        margin-left: 0; /* Reset margin-left */
        padding: 10px;
    }

    /* Main image content */
    .industry_para_content .img1 {
        width: 90vw; /* Adjusted width for mobile */
        height: auto; /* Adjust height to auto */
        margin-left: 0; /* Reset margin-left */
    }

    /* Benefits content */
    .industry_benefits {
        width: 90%; /* Adjusted width for mobile */
        padding-top: 10px;
        margin: 10px auto; /* Centered margin */
    }

    /* Benefits heading */
    .industry_benefits h5 {
        font-size: 24px; /* Reduced font size */
        width: 100%; /* Full width for mobile */
        margin-left: 0; /* Reset margin-left */
    }

    /* Benefits image */
    .industry_benefits .img2 {
        float: none; /* Remove float */
        width: 90vw; /* Adjusted width for mobile */
        margin: 10px 0; /* Reset margin */
    }

    /* Benefits text */
    .industry_benefits .benefits {
        font-size: 16px; /* Reduced font size */
        width: 90vw; /* Adjusted width for mobile */
        margin-left: 0; /* Reset margin-left */
    }

    .industry_benefits .benefits p {
        font-size: 16px; /* Reduced font size */
        width: 90vw; /* Adjusted width for mobile */
        margin-left: 0; /* Reset margin-left */
        padding-left: 20px; /* Increased padding for bullet */
        text-align: justify; /* Adjusted text alignment */
    }

    /* Industry applications */
    .industry_applications {
        width: 90%; /* Adjusted width for mobile */
        padding-top: 10px;
        margin-top: 20px; /* Reduced top margin */
    }

    /* Industry applications heading */
    .industry_applications h5 {
        font-size: 24px; /* Reduced font size */
        float: none; /* Remove float */
        width: 100%; /* Full width for mobile */
        margin-right: 0; /* Reset margin-right */
    }

    /* Industry applications image */
    .industry_applications .img3 {
        float: none; /* Remove float */
        width: 80vw; /* Adjusted width for mobile */
        margin-left: 0; /* Reset margin-left */
        margin-bottom: 20px; /* Adjust bottom margin */
    }

    /* Industry applications text */
    .industry_applications .applications {
        font-size: 16px; /* Reduced font size */
        width: 90vw; /* Adjusted width for mobile */
        margin-left: 0; /* Reset margin-left */
    }

    .industry_applications .applications p {
        font-size: 16px; /* Reduced font size */
        width: 90vw; /* Adjusted width for mobile */
        margin-left: 0; /* Reset margin-left */
        padding-left: 20px; /* Increased padding for bullet */
    }

    /* Industry down image */
    .industry_down_image .img4 {
        width: 90vw; /* Adjusted width for mobile */
        margin-top: 20px; /* Reduced top margin */
    }
}


