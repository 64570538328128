
/* complete form css */

.wrapper{
    position: relative;
    width: 750px;
    height: 450px;
    background: #081b29;
    border: 2px solid #0ef;
    box-shadow: 0 0 25px #0ef;
    overflow: hidden;
    margin-left: 28%;
    margin-top: 12%;
    margin-bottom: 5%;
}

/* form css */

.wrapper .form-box{
    position: absolute;
    top:0;
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* login form box */

.wrapper .form-box.login{
    left: 0;
    padding: 0 60px 0 40px;
}

/* login form box with image */

.login img{
    width: 55px;
    margin-top: -20px;
    margin-left: -30px;
}

/* register form box with image */

.register img{
    width: 55px;
    margin-top: -40px;
    margin-left: 240px;
}

/* animation for login */

.wrapper .form-box.login .animation{
    transform: translateX(0);
    opacity: 1;
    filter: blur(0);
    transition: .7s ease;
    transition-delay: calc(.1s * var(--j));
}

/* activate animation for login */

.wrapper.active .form-box.login .animation{
    transform: translateX(-120%);
    opacity: 0;
    filter: blur(10px);
    transition-delay: calc(.1s * var(--i));
}

/* register form box */

.wrapper .form-box.register{
    right: 0;
    padding: 0 40px 0 60px;
    pointer-events: none;
}

/* activate animation for register */

.wrapper.active .form-box.register{
    pointer-events: all;
}

/* animation for register */

.wrapper .form-box.register .animation{
    transform: translateX(120%);
    opacity: 0;
    filter: blur(10px);
    transition: .7s ease;
    transition-delay: calc(.1s * var(--j));
}

/* activate animation for register */

.wrapper.active .form-box.register .animation{
    transform: translateX(0);
    opacity: 1;
    filter: blur(0);
    transition-delay: calc(.1s * var(--i));
}

/* heading for login and register */

.form-box h2{
    font-size: 32px;
    color : #fff;
    text-align: center;
}

/* input box for login and register */

.form-box .input-box{
    position: relative;
    width: 100%;
    height: 50px;
    margin: 2px 0;
}

/* input textarea for login and register */

.input-box input{
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    outline: none;
    border-bottom: 2px solid #fff;
    padding-right: 23px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    transition: .5s;
}

.input-box input:focus,
.input-box input:valid{
    top: 4px;
    border-bottom-color: #0ef;
}

/* label for login and register */

.input-box label{
    position: absolute;
    top: 40%;
    left: 0;
    transform: translateY(-50%);
    font-size: 16px;
    color: #fff;
    pointer-events: none;
    transition: .5s;
}

.input-box input:focus ~ label,
.input-box input:valid ~ label{
    top: 7px;
    color: #0ef;
}

/* icon for login and register */

.input-box .icon{
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    font-size: 18px;
    color: #fff;
    transition: .5s;
}

.input-box input:focus ~ .icon,
.input-box input:valid ~ .icon{
    color: #0ef;
}

/* success message */

.success-message {
    color: green;
    margin-top: 10px;
  }

  /* error message */

.error-message {
    color: red;
    margin-top: 10px;
  }

/* forgot link */

.remember-forgot .link{
    margin: 5px 0 0 0;
    color: #fff;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

/* button for login and register */

.button{
    position: relative;
    width: 100%;
    height: 45px;
    background: transparent;
    border: 2px solid #0ef;
    outline: none;
    border-radius: 40px;
    cursor: pointer;
    font-size: 16px;
    color: #fff;
    font-weight: 600;
    z-index: 1;
    overflow: hidden;
    margin-top: 10px;
    margin-left: -30px;
}

/* animation for button */

.button::before{
    content: '';
    position: absolute;
    top: -100%;
    left: 0;
    width: 100%;
    height: 300%;
    background: linear-gradient(#081b29, #0ef, #081b29, #0ef); 
    z-index: -1;
    transition: .5s;
}

/* hover effect  for button */

.button:hover::before{
    top: 0;
}

/* link for login and register */

.form-box .logreg-link{
    font-size: 14.5px;
    color: #fff;
    text-align: center;
    margin-left: 20px;
    margin-bottom: 10px;
    cursor: pointer;
}

/* link for login and register */

.logreg-link p span{
    color: #0ef;
    text-decoration: none;
    font-weight: 600;
}

/* link for login and register hover effect */

.logreg-link p span:hover{
    text-decoration: underline;
}

/* info text for login and register */

.wrapper .info-text{
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* info text for login */

.wrapper .info-text.login{
    right: 0;
    text-align: right;
    padding: 0 40px 60px 150px;
}

/* info text for login animation */

.wrapper .info-text.login .animation{
    transform: translateX(0);
    opacity: 1;
    filter: blur(0);
    transition: .7s ease;
    transition-delay: calc(.1s * var(--j));
}

/* info text activation for login */

.wrapper.active .info-text.login .animation{
    transform: translateX(120%);
    opacity: 0;
    filter: blur(10px);
    transition-delay: calc(.1s * var(--i));
}

/* info text for register */

.wrapper .info-text.register{
    left: 0;
    text-align: left;
    padding: 0 150px 60px 40px;
    pointer-events: none;
}

/* info text activation for register */

.wrapper.active .info-text.register{
    pointer-events: auto;
}

/* info text for register animation */

.wrapper .info-text.register .animation{
    transform: translateX(-120%);
    opacity: 0;
    filter: blur(10px);
    transition: .7s ease;
    transition-delay: calc(.1s * var(--j));
}

/* info text activation for register */

.wrapper.active .info-text.register .animation{
    transform: translateX(0);
    opacity: 1;
    filter: blur(0);
    transition-delay: calc(.1s * var(--i));
}

/* info text heading for login and register */

.info-text h2{
    color: #fff;
    font-size: 36px;
    line-height: 1.3;
    text-transform: uppercase;
}

/* info text para for login and register */

.info-text p{
    color: #fff;
    font-size: 16px;
}

/* animation for login */

.wrapper .bg-animate{
    position: absolute;
    top: -4px;
    right: 0;
    width: 850px;
    height: 600px;
    background: linear-gradient(45deg, #081b29, #0ef);
    border-bottom: 3px solid #0ef;
    transform: rotate(10deg) skewY(40deg);
    transform-origin: bottom right;
    transition: 1.5s ease;
    transition-delay: 1.6s;
}

/* activate and animation for login */

.wrapper.active .bg-animate{
    transform: rotate(0) skewY(0);
    transition-delay: .5s;
}

/* animation for register */

.wrapper .bg-animate2{
    position: absolute;
    top: 100%;
    left: 250px;
    width: 850px;
    height: 700px;
    background: #081b29;
    border-top: 3px solid #0ef;
    transform: rotate(0) skewY(0);
    transform-origin: bottom left;
    transition: 1.5s ease;
    transition-delay: .5s;
}

/* activate and animation for register */

.wrapper.active .bg-animate2{
    transform: rotate(-11deg) skewY(-41deg);
    transition-delay: 1.2s;
}

/* Forgot Password Modal */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* modal content style */
  
.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
}

/* modal content heading style */
  
.modal-content h2 {
    color: #081b29;
    font-size: 24px;
    margin-bottom: 10px;
}

/* modal content para style */
  
.modal-content p {
    color: #555;
    font-size: 16px;
    margin-bottom: 20px;
}

/* modal content input style */
  
.modal-content input[type="email"],
.modal-content input[type="text"],
.modal-content input[type="password"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    margin-bottom: 20px;
}

/* modal content button style */
  
.modal-content .act_button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-left: -30px;
}
  
.modal-content .act_button {
    background-color: #081b29 ; 
    margin-bottom: 10px;
    margin-right: 90px;
}

/* modal content button hover effect */

.modal-content .act_button:hover {
    background-color: #0dc;
}

@media screen and (max-width: 1263px) {

    /* complete form css */

    .wrapper{
        position: relative;
        width: 750px;
        height: 900px;
        background: #081b29;
        border: 2px solid #0ef;
        box-shadow: 0 0 25px #0ef;
        overflow: hidden;
        margin-left: 20%;
        margin-top: 12%;
        margin-bottom: 5%;
    }

    /* form css */

    .wrapper .form-box{
        position: absolute;
        top:0;
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    /* login form box */

    .wrapper .form-box.login{
        left: 0;
        padding: 0 60px 0 40px;
    }

    /* login form box with image */

    .login img{
        width: 55px;
        margin-top: -0px;
        margin-left: -30px;
    }

    /* register form box with image */

    .register img{
        width: 55px;
        margin-top: -40px;
        margin-left: 240px;
    }

    /* animation for login */

    .wrapper .form-box.login .animation{
        transform: translateX(0);
        opacity: 1;
        filter: blur(0);
        transition: .7s ease;
        transition-delay: calc(.1s * var(--j));
    }

    /* activate animation for login */

    .wrapper.active .form-box.login .animation{
        transform: translateX(-120%);
        opacity: 0;
        filter: blur(10px);
        transition-delay: calc(.1s * var(--i));
    }

    /* register form box */

    .wrapper .form-box.register{
        right: 0;
        padding: 0 40px 0 60px;
        pointer-events: none;
    }

    /* activate animation for register */

    .wrapper.active .form-box.register{
        pointer-events: all;
    }

    /* animation for register */

    .wrapper .form-box.register .animation{
        transform: translateX(120%);
        opacity: 0;
        filter: blur(10px);
        transition: .7s ease;
        transition-delay: calc(.1s * var(--j));
    }

    /* activate animation for register */

    .wrapper.active .form-box.register .animation{
        transform: translateX(0);
        opacity: 1;
        filter: blur(0);
        transition-delay: calc(.1s * var(--i));
    }

    /* heading for login and register */

    .form-box h2{
        font-size: 32px;
        color : #fff;
        text-align: center;
    }

    /* input box for login and register */

    .form-box .input-box{
        position: relative;
        width: 100%;
        height: 50px;
        margin: 2px 0;
    }

    /* input textarea for login and register */

    .input-box input{
        width: 100%;
        height: 100%;
        background: transparent;
        border: none;
        outline: none;
        border-bottom: 2px solid #fff;
        padding-right: 23px;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        transition: .5s;
    }

    .input-box input:focus,
    .input-box input:valid{
        top: 4px;
        border-bottom-color: #0ef;
    }

    /* label for login and register */

    .input-box label{
        position: absolute;
        top: 40%;
        left: 0;
        transform: translateY(-50%);
        font-size: 16px;
        color: #fff;
        pointer-events: none;
        transition: .5s;
    }

    .input-box input:focus ~ label,
    .input-box input:valid ~ label{
        top: 7px;
        color: #0ef;
    }

    /* icon for login and register */

    .input-box .icon{
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        font-size: 18px;
        color: #fff;
        transition: .5s;
    }

    .input-box input:focus ~ .icon,
    .input-box input:valid ~ .icon{
        color: #0ef;
    }

    /* success message */

    .success-message {
        color: green;
        margin-top: 10px;
    }

    /* error message */

    .error-message {
        color: red;
        margin-top: 10px;
    }

    /* forgot link */

    .remember-forgot .link{
        margin: 5px 0 0 0;
        color: #fff;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
    }

    /* button for login and register */

    .button{
        position: relative;
        width: 100%;
        height: 45px;
        background: transparent;
        border: 2px solid #0ef;
        outline: none;
        border-radius: 40px;
        cursor: pointer;
        font-size: 16px;
        color: #fff;
        font-weight: 600;
        z-index: 1;
        overflow: hidden;
        margin-top: 10px;
        margin-left: -30px;
    }

    /* animation for button */

    .button::before{
        content: '';
        position: absolute;
        top: -100%;
        left: 0;
        width: 100%;
        height: 300%;
        background: linear-gradient(#081b29, #0ef, #081b29, #0ef); 
        z-index: -1;
        transition: .5s;
    }

    /* hover effect  for button */

    .button:hover::before{
        top: 0;
    }

    /* link for login and register */

    .form-box .logreg-link{
        font-size: 14.5px;
        color: #fff;
        text-align: center;
        margin-left: 20px;
        margin-bottom: 10px;
        cursor: pointer;
    }

    /* link for login and register */

    .logreg-link p span{
        color: #0ef;
        text-decoration: none;
        font-weight: 600;
    }

    /* link for login and register hover effect */

    .logreg-link p span:hover{
        text-decoration: underline;
    }

    /* info text for login and register */

    .wrapper .info-text{
        position: absolute;
        top: 0;
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    /* info text for login */

    .wrapper .info-text.login{
        right: 0;
        text-align: right;
        padding: 0 40px 60px 150px;
    }

    /* info text for login animation */

    .wrapper .info-text.login .animation{
        transform: translateX(0);
        opacity: 1;
        filter: blur(0);
        transition: .7s ease;
        transition-delay: calc(.1s * var(--j));
    }

    /* info text activation for login */

    .wrapper.active .info-text.login .animation{
        transform: translateX(120%);
        opacity: 0;
        filter: blur(10px);
        transition-delay: calc(.1s * var(--i));
    }

    /* info text for register */

    .wrapper .info-text.register{
        left: 0;
        text-align: left;
        padding: 0 150px 60px 40px;
        pointer-events: none;
    }

    /* info text activation for register */

    .wrapper.active .info-text.register{
        pointer-events: auto;
    }

    /* info text for register animation */

    .wrapper .info-text.register .animation{
        transform: translateX(-120%);
        opacity: 0;
        filter: blur(10px);
        transition: .7s ease;
        transition-delay: calc(.1s * var(--j));
    }

    /* info text activation for register */

    .wrapper.active .info-text.register .animation{
        transform: translateX(0);
        opacity: 1;
        filter: blur(0);
        transition-delay: calc(.1s * var(--i));
    }

    /* info text heading for login and register */

    .info-text h2{
        color: #fff;
        font-size: 36px;
        line-height: 1.3;
        text-transform: uppercase;
    }

    /* info text para for login and register */

    .info-text p{
        color: #fff;
        font-size: 16px;
    }

    /* animation for login */

    .wrapper .bg-animate{
        position: absolute;
        top: -4px;
        right: 0;
        width: 850px;
        height: 600px;
        background: linear-gradient(45deg, #081b29, #0ef);
        border-bottom: 3px solid #0ef;
        transform: rotate(10deg) skewY(40deg);
        transform-origin: bottom right;
        transition: 1.5s ease;
        transition-delay: 1.6s;
    }

    /* activate and animation for login */

    .wrapper.active .bg-animate{
        transform: rotate(0) skewY(0);
        transition-delay: .5s;
    }

    /* animation for register */

    .wrapper .bg-animate2{
        position: absolute;
        top: 100%;
        left: 250px;
        width: 850px;
        height: 700px;
        background: #081b29;
        border-top: 3px solid #0ef;
        transform: rotate(0) skewY(0);
        transform-origin: bottom left;
        transition: 1.5s ease;
        transition-delay: .5s;
    }

    /* activate and animation for register */

    .wrapper.active .bg-animate2{
        transform: rotate(-11deg) skewY(-41deg);
        transition-delay: 1.2s;
    }

    /* Forgot Password Modal */
    .modal {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }

    /* modal content style */
    
    .modal-content {
        background-color: #fff;
        padding: 20px;
        border-radius: 8px;
        width: 300px;
        text-align: center;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        position: relative;
    }

    /* modal content heading style */
    
    .modal-content h2 {
        color: #081b29;
        font-size: 24px;
        margin-bottom: 10px;
    }

    /* modal content para style */
    
    .modal-content p {
        color: #555;
        font-size: 16px;
        margin-bottom: 20px;
    }

    /* modal content input style */
    
    .modal-content input[type="email"],
    .modal-content input[type="text"],
    .modal-content input[type="password"] {
        width: 100%;
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 5px;
        font-size: 16px;
        margin-bottom: 20px;
    }

    /* modal content button style */
    
    .modal-content .act_button {
        width: 100%;
        padding: 10px;
        border: none;
        border-radius: 5px;
        color: #fff;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s;
        margin-left: -30px;
    }
    
    .modal-content .act_button {
        background-color: #081b29 ; 
        margin-bottom: 10px;
        margin-right: 90px;
    }

    /* modal content button hover effect */

    .modal-content .act_button:hover {
        background-color: #0dc;
    }
}


@media screen and (max-width: 768px)  {

    /* complete form css */

    .wrapper{
        position: relative;
        width: 400px;
        height: 450px;
        background: #081b29;
        border: 2px solid #0ef;
        box-shadow: 0 0 25px #0ef;
        overflow: hidden;
        margin-left: 4%;
        margin-top: 28%;
        margin-bottom: 5%;
    }

    /* form css */

    .wrapper .form-box{
        position: absolute;
        top:0;
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    /* login form box */

    .wrapper .form-box.login{
        left: 0;
        padding: 0 60px 0 40px;
    }

    /* login form box with image */

    .login img{
        width: 25px;
        margin-top: 20px;
        margin-left: -30px;
    }

    /* register form box with image */

    .register img{
        width: 25px;
        margin-top: -40px;
        margin-left: 90px;
    }

    /* animation for login */

    .wrapper .form-box.login .animation{
        transform: translateX(0);
        opacity: 1;
        filter: blur(0);
        transition: .7s ease;
        transition-delay: calc(.1s * var(--j));
    }

    /* activate animation for login */

    .wrapper.active .form-box.login .animation{
        transform: translateX(-120%);
        opacity: 0;
        filter: blur(10px);
        transition-delay: calc(.1s * var(--i));
    }

    /* register form box */

    .wrapper .form-box.register{
        right: 0;
        padding: 0 40px 0 60px;
        pointer-events: none;
    }

    /* activate animation for register */

    .wrapper.active .form-box.register{
        pointer-events: all;
    }

    /* animation for register */

    .wrapper .form-box.register .animation{
        transform: translateX(120%);
        opacity: 0;
        filter: blur(10px);
        transition: .7s ease;
        transition-delay: calc(.1s * var(--j));
    }

    /* activate animation for register */

    .wrapper.active .form-box.register .animation{
        transform: translateX(0);
        opacity: 1;
        filter: blur(0);
        transition-delay: calc(.1s * var(--i));
    }

    /* heading for login and register */

    .form-box h2{
        font-size: 32px;
        color : #fff;
        text-align: center;
    }

    /* input box for login and register */

    .form-box .input-box{
        position: relative;
        width: 100%;
        height: 50px;
        margin: 2px 0;
    }

    /* input textarea for login and register */

    .input-box input{
        width: 100%;
        height: 100%;
        background: transparent;
        border: none;
        outline: none;
        border-bottom: 2px solid #fff;
        padding-right: 23px;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        transition: .5s;
    }

    .input-box input:focus,
    .input-box input:valid{
        top: 4px;
        border-bottom-color: #0ef;
    }

    /* label for login and register */

    .input-box label{
        position: absolute;
        top: 40%;
        left: 0;
        transform: translateY(-50%);
        font-size: 16px;
        color: #fff;
        pointer-events: none;
        transition: .5s;
    }

    .input-box input:focus ~ label,
    .input-box input:valid ~ label{
        top: 7px;
        color: #0ef;
    }

    /* icon for login and register */

    .input-box .icon{
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        font-size: 18px;
        color: #fff;
        transition: .5s;
    }

    .input-box input:focus ~ .icon,
    .input-box input:valid ~ .icon{
        color: #0ef;
    }

    /* success message */

    .success-message {
        color: green;
        margin-top: 10px;
    }

    /* error message */

    .error-message {
        color: red;
        margin-top: 10px;
    }

    /* forgot link */

    .remember-forgot .link{
        margin: 5px 0 0 0;
        color: #fff;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
    }

    /* button for login and register */

    .button{
        position: relative;
        width: 100%;
        height: 45px;
        background: transparent;
        border: 2px solid #0ef;
        outline: none;
        border-radius: 40px;
        cursor: pointer;
        font-size: 16px;
        color: #fff;
        font-weight: 600;
        z-index: 1;
        overflow: hidden;
        margin-top: 10px;
        margin-left: 0px;
    }

    /* animation for button */

    .button::before{
        content: '';
        position: absolute;
        top: -100%;
        left: 0;
        width: 100%;
        height: 300%;
        background: linear-gradient(#081b29, #0ef, #081b29, #0ef); 
        z-index: -1;
        transition: .5s;
    }

    /* hover effect  for button */

    .button:hover::before{
        top: 0;
    }

    /* link for login and register */

    .form-box .logreg-link{
        font-size: 14.5px;
        color: #fff;
        text-align: center;
        margin-left: 0;
        margin-bottom: 10px;
        cursor: pointer;
    }

    /* link for login and register */

    .logreg-link p span{
        color: #0ef;
        text-decoration: none;
        font-weight: 600;
    }

    /* link for login and register hover effect */

    .logreg-link p span:hover{
        text-decoration: underline;
    }

    /* info text for login and register */

    .wrapper .info-text{
        position: absolute;
        top: 0;
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    /* info text for login */

    .wrapper .info-text.login{
        right: 0;
        text-align: center;
        padding: 0 20px 60px 100px;
    }

    /* info text for login animation */

    .wrapper .info-text.login .animation{
        transform: translateX(0);
        opacity: 1;
        filter: blur(0);
        transition: .7s ease;
        transition-delay: calc(.1s * var(--j));
    }

    /* info text activation for login */

    .wrapper.active .info-text.login .animation{
        transform: translateX(120%);
        opacity: 0;
        filter: blur(10px);
        transition-delay: calc(.1s * var(--i));
    }

    /* info text for register */

    .wrapper .info-text.register{
        left: 0;
        text-align: left;
        padding: 0 100px 90px 20px;
        /* padding-top: -10px; */
        pointer-events: none;
    }

    /* info text activation for register */

    .wrapper.active .info-text.register{
        pointer-events: auto;
    }

    /* info text for register animation */

    .wrapper .info-text.register .animation{
        transform: translateX(-120%);
        opacity: 0;
        filter: blur(10px);
        transition: .7s ease;
        transition-delay: calc(.1s * var(--j));
    }

    /* info text activation for register */

    .wrapper.active .info-text.register .animation{
        transform: translateX(0);
        opacity: 1;
        filter: blur(0);
        transition-delay: calc(.1s * var(--i));
    }

    /* info text heading for login and register */

    .info-text h2{
        color: #fff;
        font-size: 16px;
        /* line-height: 1.3; */
        text-transform: uppercase;
    }

    /* info text para for login and register */

    .info-text p{
        color: #fff;
        font-size: 16px;
    }

    /* animation for login */

    .wrapper .bg-animate{
        position: absolute;
        top: -4px;
        right: 0;
        width: 850px;
        height: 600px;
        background: linear-gradient(45deg, #081b29, #0ef);
        border-bottom: 3px solid #0ef;
        transform: rotate(20deg) skewY(50deg);
        transform-origin: bottom right;
        transition: 1.5s ease;
        transition-delay: 1.6s;
    }

    /* activate and animation for login */

    .wrapper.active .bg-animate{
        transform: rotate(0) skewY(0);
        transition-delay: .5s;
    }

    /* animation for register */

    .wrapper .bg-animate2{
        position: absolute;
        top: 100%;
        left: 250px;
        width: 850px;
        height: 700px;
        background: #081b29;
        border-top: 3px solid #0ef;
        transform: rotate(0) skewY(0);
        transform-origin: bottom left;
        transition: 1.5s ease;
        transition-delay: .5s;
    }

    /* activate and animation for register */

    .wrapper.active .bg-animate2{
        transform: rotate(-25deg) skewY(-29deg);
        transition-delay: 1.2s;
    }

    /* Forgot Password Modal */
    .modal {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }

    /* modal content style */
    
    .modal-content {
        background-color: #fff;
        padding: 20px;
        border-radius: 8px;
        width: 300px;
        text-align: center;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        position: relative;
    }

    /* modal content heading style */
    
    .modal-content h2 {
        color: #081b29;
        font-size: 24px;
        margin-bottom: 10px;
    }

    /* modal content para style */
    
    .modal-content p {
        color: #555;
        font-size: 16px;
        margin-bottom: 20px;
    }

    /* modal content input style */
    
    .modal-content input[type="email"],
    .modal-content input[type="text"],
    .modal-content input[type="password"] {
        width: 100%;
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 5px;
        font-size: 16px;
        margin-bottom: 20px;
    }

    /* modal content button style */
    
    .modal-content .act_button {
        width: 100%;
        padding: 10px;
        border: none;
        border-radius: 5px;
        color: #fff;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s;
        margin-left: 0px;
    }
    
    .modal-content .act_button {
        background-color: #081b29 ; 
        margin-bottom: 10px;
        margin-right: 90px;
    }

    /* modal content button hover effect */

    .modal-content .act_button:hover {
        background-color: #0dc;
    }
}



@media screen and (max-width: 425px) {
    /* Complete form css */
    .wrapper {
        position: relative;
        width: 400px;
        height: 450px;
        overflow: hidden;
        margin-left: 0;
        /* margin-right: -5%; */
        margin-top: 25%;
        margin-bottom: 5%;

    }

    /* form box for login and register */

    .wrapper .form-box {
        position: absolute;
        top: 0;
        width: 50%;
        height: 80%;
        left: -10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* padding: 0 20px; */
    }

    /* form box for login */

    .wrapper .form-box.login{
        left: 0;
        padding: 0 60px 0 40px;
    }

    /* form box for login with image */
    
    .wrapper .form-box.login img{
        width: 35px;
        margin-top: 80px;
    }

    /* form box for register with image */

    .wrapper .form-box.register img{
        width: 35px;
        margin-top: 30px;
        margin-left: 60px;
    }

    /* animation for login */
    
    .wrapper .form-box.login .animation{
        transform: translateX(0);
        opacity: 1;
        filter: blur(0);
        transition: .7s ease;
        transition-delay: calc(.1s * var(--j));
    }

    /* activate login form for animation */
    
    .wrapper.active .form-box.login .animation{
        transform: translateX(-120%);
        opacity: 0;
        filter: blur(10px);
        transition-delay: calc(.1s * var(--i));
    }

    /* form box register */
    
    .wrapper .form-box.register{
        right: 0;
        padding: 0 40px 0 60px;
        pointer-events: none;
    }

    /* activate register form */
    
    .wrapper.active .form-box.register{
        pointer-events: all;
        margin-left: 200px;

    }

    /* animation for register */
    
    .wrapper .form-box.register .animation{
        transform: translateX(120%);
        opacity: 0;
        filter: blur(10px);
        transition: .7s ease;
        transition-delay: calc(.1s * var(--j));
    }

    /* activate register form for animation */
    
    .wrapper.active .form-box.register .animation{
        transform: translateX(0);
        opacity: 1;
        filter: blur(0);
        transition-delay: calc(.1s * var(--i));
    }

    /* heading for login and register */

    .form-box h2{
        font-size: 22px;
        color : #fff;
        text-align: center;
    }

    /* input box for login and register */
    
    .form-box .input-box{
        position: relative;
        width: 100%;
        height: 50px;
        margin: 2px 0;
    }

    /* input box for login and register */
    
    .input-box input{
        width: 100%;
        height: 100%;
        background: transparent;
        border: none;
        outline: none;
        border-bottom: 2px solid #fff;
        padding-right: 23px;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        transition: .5s;
    }

    .input-box input:focus,
    .input-box input:valid{
        border-bottom-color: #0ef;
    }

    /* label for login and register */

    .input-box label{
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        font-size: 16px;
        color: #fff;
        pointer-events: none;
        transition: .5s;
    }

    .input-box input:focus ~ label,
    .input-box input:valid ~ label{
        top: -5px;
        color: #0ef;
    }

    /* icon for login and register */

    .input-box .icon{
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        font-size: 18px;
        color: #fff;
        transition: .5s;
    }

    .input-box input:focus ~ .icon,
    .input-box input:valid ~ .icon{
        color: #0ef;
    }

    /* forgot link */

    .remember-forgot .link{
        margin: 5px 0 0 0;
        color: #fff;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
    }

    /* button for login and register */
    
    .button{
        position: relative;
        width: 100%;
        height: 30px;
        background: transparent;
        border: 2px solid #0ef;
        outline: none;
        border-radius: 40px;
        cursor: pointer;
        font-size: 16px;
        color: #fff;
        font-weight: 600;
        z-index: 1;
        overflow: hidden;
        margin-top: 10px;
        margin-left: 5px;
    }

    /* button for login and register before animation */
    
    .button::before{
        content: '';
        position: absolute;
        top: -100%;
        left: 0;
        width: 100%;
        height: 300%;
        background: linear-gradient(#081b29, #0ef, #081b29, #0ef); 
        z-index: -1;
        transition: .5s;
    }
    
    .button:hover::before{
        top: 0;
    }

    /* link for login and register */

    .form-box .logreg-link{
        font-size: 14.5px;
        color: #fff;
        text-align: center;
        margin-left: 5px;
        margin-bottom: 10px;
    }

    /* link for login and register */
    
    .logreg-link p span{
        color: #0ef;
        text-decoration: none;
        font-weight: 600;
    }

    /* link for login and register hover */
    
    .logreg-link p span:hover{
        text-decoration: underline;
    }

    /* info-text for login and register */
    
    .wrapper .info-text{
        position: absolute;
        top: 0;
        width: 60%;
        height: 100%;
        margin-right: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    /* info-text for login */
    
    .wrapper .info-text.login{
        right: 0;
        text-align: right;
        padding: 0 40px 60px 150px;
    }

    /* info-text for login animation */
    
    .wrapper .info-text.login .animation{
        transform: translateX(0);
        opacity: 1;
        filter: blur(0);
        transition: .7s ease;
        transition-delay: calc(.1s * var(--j));
    }

    /* activate the info-text for login animation */
    
    .wrapper.active .info-text.login .animation{
        transform: translateX(120%);
        opacity: 0;
        filter: blur(10px);
        transition-delay: calc(.1s * var(--i));
    }

    /* info-text for register */
    
    .wrapper .info-text.register{
        left: 0;
        text-align: left;
        padding: 0 150px 60px 40px;
        pointer-events: none;
    }

    /* active info-text for register  */
    
    .wrapper.active .info-text.register{
        pointer-events: auto;
    }

    /* info-text for register animation */
    
    .wrapper .info-text.register .animation{
        transform: translateX(-120%);
        opacity: 0;
        filter: blur(10px);
        transition: .7s ease;
        transition-delay: calc(.1s * var(--j));
    }

    /* activate the info-text for register animation */
    
    .wrapper.active .info-text.register .animation{
        transform: translateX(0);
        opacity: 1;
        filter: blur(0);
        transition-delay: calc(.1s * var(--i));
    }

    /* info-text for login and register heading */
    
    .info-text h2{
        color: #fff;
        font-size: 14px;
        font-weight: 900;
        line-height: 1.3;
        text-transform: uppercase;
    }

    /* info-text for login and register para */
    
    .info-text p{
        color: #fff;
        font-size: 16px;
        text-align: justify;
    }

    /* animation for login */
    
    .wrapper .bg-animate{
        position: absolute;
        top: -4px;
        right: 0;
        width: 850px;
        height: 600px;
        background: linear-gradient(45deg, #081b29, #0ef);
        border-bottom: 3px solid #0ef;
        transform: rotate(10deg) skewY(55deg);
        transform-origin: bottom right;
        transition: 1.5s ease;
        transition-delay: 1.6s;
    }

    /* activate the animation for login */
    
    .wrapper.active .bg-animate{
        transform: rotate(0) skewY(0);
        transition-delay: .5s;
    }

    /* animation for register */
    
    .wrapper .bg-animate2{
        position: absolute;
        top: 100%;
        left: 250px;
        width: 850px;
        height: 700px;
        background: #081b29;
        border-top: 3px solid #0ef;
        transform: rotate(0) skewY(0);
        transform-origin: bottom left;
        transition: 1.5s ease;
        transition-delay: .5s;
    }

    /* activate the animation for register */
    
    .wrapper.active .bg-animate2{
        transform: rotate(-11deg) skewY(-65deg);
        transition-delay: 1.2s;
    }

    /* modal  content  */
    
    .modal {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }

    /* modal content  */
      
    .modal-content {
        background-color: #fff;
        padding: 20px;
        border-radius: 8px;
        width: 300px;
        text-align: center;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        position: relative;
    }

    /* modal content heading */
      
    .modal-content h2 {
        color: #081b29;
        font-size: 24px;
        margin-bottom: 10px;
    }

    /* modal content para */
      
    .modal-content p {
        color: #555;
        font-size: 16px;
        margin-bottom: 20px;
    }

    /* modal content input */
      
    .modal-content input[type="email"],
    .modal-content input[type="text"],
    .modal-content input[type="password"] {
        width: 100%;
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 5px;
        font-size: 16px;
        margin-bottom: 20px;
    }

    /* modal content button */
      
    .modal-content .act_button {
        width: 100%;
        padding: 10px;
        border: none;
        border-radius: 5px;
        color: #fff;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s;
        margin-left: 5px;
    }
      
    .modal-content .act_button {
        background-color: #081b29 ; 
        margin-bottom: 10px;
        margin-right: 90px;
    }

    /* activate the button when hover */
    
    .modal-content .act_button:hover {
        background-color: #0dc;
    }
}
@media screen and (max-width: 320px) {
    /* container for login and register */
    .wrapper {
        position: relative;
        width: 350px;
        height: 450px;
        overflow: hidden;
        margin-left: 3%;
        margin-top: 15%;
        margin-bottom: 5%;

    }

    /* form box for login and register */

    .wrapper .form-box {
        position: absolute;
        top: 0;
        width: 50%;
        height: 80%;
        left: -10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* padding: 0 20px; */
    }

    /* form box for login */

    .wrapper .form-box.login{
        left: 0;
        padding: 0 60px 0 40px;
    }

    /* form box for login with image */
    
    .wrapper .form-box.login img{
        width: 35px;
        margin-top: 80px;
    }

    /* form box for register with image */

    .wrapper .form-box.register img{
        width: 25px;
        margin-top: 30px;
        margin-left: 60px;
    }

    /* animation for login */
    
    .wrapper .form-box.login .animation{
        transform: translateX(0);
        opacity: 1;
        filter: blur(0);
        transition: .7s ease;
        transition-delay: calc(.1s * var(--j));
    }

    /* activate the form box for login animation */
    
    .wrapper.active .form-box.login .animation{
        transform: translateX(-120%);
        opacity: 0;
        filter: blur(10px);
        transition-delay: calc(.1s * var(--i));
    }

    /* form box for register */
    
    .wrapper .form-box.register{
        right: 0;
        padding: 0 40px 0 60px;
        pointer-events: none;
    }

    /* activate register form box */
    
    .wrapper.active .form-box.register{
        pointer-events: all;
        margin-left: 180px;

    }

    /* animation for register form box */
    
    .wrapper .form-box.register .animation{
        transform: translateX(120%);
        opacity: 0;
        filter: blur(10px);
        transition: .7s ease;
        transition-delay: calc(.1s * var(--j));
    }

    /* activate register form box for animation */
    
    .wrapper.active .form-box.register .animation{
        transform: translateX(0);
        opacity: 1;
        filter: blur(0);
        transition-delay: calc(.1s * var(--i));
    }

    /* heading for login and register */

    .form-box h2{
        font-size: 22px;
        color : #fff;
        text-align: center;
    }

    /* input box for login and register */
    
    .form-box .input-box{
        position: relative;
        width: 100%;
        height: 50px;
        margin: 2px 0;
    }

    /* input box for login and register */
    
    .input-box input{
        width: 100%;
        height: 100%;
        background: transparent;
        border: none;
        outline: none;
        border-bottom: 2px solid #fff;
        padding-right: 23px;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        transition: .5s;
    }

    .input-box input:focus,
    .input-box input:valid{
        border-bottom-color: #0ef;
    }

    /* label for login and register */

    .input-box label{
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        font-size: 16px;
        color: #fff;
        pointer-events: none;
        transition: .5s;
    }

    .input-box input:focus ~ label,
    .input-box input:valid ~ label{
        top: -5px;
        color: #0ef;
    }

    /* icon for login and register */

    .input-box .icon{
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        font-size: 18px;
        color: #fff;
        transition: .5s;
    }

    .input-box input:focus ~ .icon,
    .input-box input:valid ~ .icon{
        color: #0ef;
    }

    /* forgot link */

    .remember-forgot .link{
        margin: 5px 0 0 0;
        color: #fff;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
    }

    /* button for login and register */
    
    .button{
        position: relative;
        width: 90px;
        height: 20px;
        background: transparent;
        border: 2px solid #0ef;
        outline: none;
        border-radius: 40px;
        cursor: pointer;
        font-size: 16px;
        color: #fff;
        font-weight: 600;
        z-index: 1;
        overflow: hidden;
        margin-top: 10px;
        margin-left: -6px;
    }

    /* button for login and register before animation */
    
    .button::before{
        content: '';
        position: absolute;
        top: -100%;
        left: 0;
        width: 100%;
        height: 300%;
        background: linear-gradient(#081b29, #0ef, #081b29, #0ef); 
        z-index: -1;
        transition: .5s;
    }

    /* button for login and register after animation */
    
    .button:hover::before{
        top: 0;
    }

    /* link for login and register */

    .form-box .logreg-link{
        font-size: 14.5px;
        color: #fff;
        text-align: center;
        margin-left: 5px;
        margin-bottom: 10px;
    }

    /* link for login and register */
    
    .logreg-link p span{
        color: #0ef;
        text-decoration: none;
        font-weight: 600;
    }

    /* link for login and register after hover */
    
    .logreg-link p span:hover{
        text-decoration: underline;
    }

    /* info text for login and register */
    
    .wrapper .info-text{
        position: absolute;
        top: 0;
        width: 60%;
        height: 100%;
        margin-right: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    /* info text for login */
    
    .wrapper .info-text.login{
        right: 10px;
        text-align: right;
        padding: 0 40px 60px 150px;
    }

    /* info text for login animation */
    
    .wrapper .info-text.login .animation{
        transform: translateX(0);
        opacity: 1;
        filter: blur(0);
        transition: .7s ease;
        transition-delay: calc(.1s * var(--j));
    }

    /* activate the info text for login animation */
    
    .wrapper.active .info-text.login .animation{
        transform: translateX(120%);
        opacity: 0;
        filter: blur(10px);
        transition-delay: calc(.1s * var(--i));
    }

    /* info text for register */
    
    .wrapper .info-text.register{
        left: 0;
        text-align: left;
        padding: 0 150px 60px 40px;
        pointer-events: none;
    }

    /* activate the info text for register */
    
    .wrapper.active .info-text.register{
        pointer-events: auto;
        margin-left: -20px;
    }

    /* info text for register animation */
    
    .wrapper .info-text.register .animation{
        transform: translateX(-120%);
        opacity: 0;
        filter: blur(10px);
        transition: .7s ease;
        transition-delay: calc(.1s * var(--j));
    }

    /* activate the info text for register animation */
    
    .wrapper.active .info-text.register .animation{
        transform: translateX(0);
        opacity: 1;
        filter: blur(0);
        transition-delay: calc(.1s * var(--i));
    }

    /* info-text for login and register heading */
    
    .info-text h2{
        color: #fff;
        font-size: 12px;
        line-height: 0.8;
        text-transform: uppercase;
    }

    /* info-text for login and register para */
    
    .info-text p{
        color: #fff;
        font-size: 12px;
        text-align: justify;
    }

    /* animation for login */
    
    .wrapper .bg-animate{
        position: absolute;
        top: -4px;
        right: 0;
        width: 850px;
        height: 600px;
        background: linear-gradient(45deg, #081b29, #0ef);
        border-bottom: 3px solid #0ef;
        transform: rotate(10deg) skewY(60deg);
        transform-origin: bottom right;
        transition: 1.5s ease;
        transition-delay: 1.6s;
    }

    /* activate the animation */
    
    .wrapper.active .bg-animate{
        transform: rotate(0) skewY(0);
        transition-delay: .5s;
    }

    /* animation for register */
    
    .wrapper .bg-animate2{
        position: absolute;
        top: 100%;
        left: 250px;
        width: 850px;
        height: 700px;
        background: #081b29;
        border-top: 3px solid #0ef;
        transform: rotate(0) skewY(0);
        transform-origin: bottom left;
        transition: 1.5s ease;
        transition-delay: .5s;
    }

    /* activate the animation */
    
    .wrapper.active .bg-animate2{
        transform: rotate(-11deg) skewY(-65deg);
        transition-delay: 1.2s;
    }

    /* modal content */
    
    .modal {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }

    /* modal content */
      
    .modal-content {
        background-color: #fff;
        padding: 20px;
        border-radius: 8px;
        width: 300px;
        text-align: center;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        position: relative;
    }

    /* modal content heading */
      
    .modal-content h2 {
        color: #081b29;
        font-size: 24px;
        margin-bottom: 10px;
    }

    /* modal content para */
      
    .modal-content p {
        color: #555;
        font-size: 16px;
        margin-bottom: 20px;
    }

    /* modal content input */
      
    .modal-content input[type="email"],
    .modal-content input[type="text"],
    .modal-content input[type="password"] {
        width: 100%;
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 5px;
        font-size: 16px;
        margin-bottom: 20px;
    }

    /* modal content button */
      
    .modal-content .act_button {
        width: 100%;
        padding: 10px;
        border: none;
        border-radius: 5px;
        color: #fff;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s;
        margin-left: 5px;
    }
      
    .modal-content .act_button {
        background-color: #081b29 ; 
        margin-bottom: 10px;
        margin-right: 90px;
    }

    /* modal content button hover */
    
    .modal-content .act_button:hover {
        background-color: #0dc;
    }
}






  
  