
/* footer links section */

.section__padding{
    padding: 4rem 4rem;
}

/* footer */

.footer{
    background-color: #292930;
    /* height: 35vh; */
}

/* .sb__container{
    display: flex;
    flex-direction: column;
} */

/* footer links container */

.sb__footer-links{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    text-align: left;
    margin-bottom: 5px;
}

/* footer links div */

.sb__footer-links_div{
    width: 150px;
    margin: 1.09rem 6.09rem 1.09rem 6.09rem;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    color: white;
} 

/* footer btn div */

a{
    color: rgb(175, 175, 179);
    text-decoration: none;
}

/* footer btn social media */

.socialmedia{
    display: flex;
    flex-direction: row;
}   

/* social media icons */

.socialmedia .icon{
    width: 80%;
    font-size: 20px;
}

/* footer links h4 */

.sb__footer-links_div h4{
    font-size: 22px;
    line-height: 17px;
    margin-bottom: 0.9rem;
}

/* footer links p */

.sb__footer-links_div p{
    font-size: 16px;
    line-height: 15px;
    margin: 0.5rem 0;
    cursor: pointer;
}

/* footer links p hover  */

.sb__footer-links_div a:hover{
    color:#fff ;
}

/* footer below */

.sb__footer-below{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.2rem;
}

/* footer below links */

.sb__footer-below-links{
    display: flex;
    flex-direction: row;
}

/* footer below para  */

.sb__footer-below-links p{
    font-size: 15px;
    line-height: 15px;
    margin-left: 2rem;
    color: rgb(175, 175, 179);
    font-weight: 600;
}

/*  footer below para hover */

.sb__footer-below-links p:hover{
    color: #fff;
}

/* horizontal line */

hr{
    color: white;
    width: 100%;
}

/* footer copyright */

.sb__footer-copyright p{
    font-size: 15px;
    line-height: 15px;
    color: rgb(255, 255, 255);
    font-weight: 600;
}

@media  screen and (max-width: 850px) {
    .sb__footer-heading h1{
        font-size: 44px;
        line-height: 50px;
    }
}

@media  screen and (max-width: 550px) {
    .sb__footer-heading h1{
        font-size: 34px;
        line-height: 42px;
    }

    /* footer links */

    .sb__footer-links_div{
        margin: 1rem 0;
    }

    /* footer btn para */

    .sb__footer-btn p{
        font-size: 14px;
        line-height: 50px;
    }

    /* footer below */

    .sb__footer-below{
        flex-direction: column;
        justify-content: left;
    }

    /* footer below links */

    .sb__footer-below-links{
        flex-direction: column;
    }

    /*  footer below para */

    .sb__footer-below-links p{
        margin-left: 0rem;
        margin-top: 1rem;
    }
}    

@media  screen and (max-width: 400px) {
    .sb__footer-heading h1{
        font-size: 27px;
        line-height: 38px;
    }
}
